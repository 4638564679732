import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import _ from "lodash";
// import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import classnames from "classnames";
import SideMenuGroup from "./SideMenuGroup";
import {
  ChevronRight,
  Truck,
  Target,
  Monitor,
  ShoppingCart,
  Home,
  Server,
  Grid,
  Coffee,
  Users,
  Anchor,
  Wind,
  Feather,
} from "react-feather";
import * as Icon from "react-feather";
import {
  getCategoryAttributes,
  resetCategoryAttributes,
} from "../../../../../redux/actions/data/index";
import {
  setCurrentCategory,
  setBreadcrumbs,
  setBreadcrumbsClick,
} from "../../../../../redux/actions/selectedData/index";

import "./SideMenu.scss";
import { getCategoryMeasurementUnitOptions } from "../../../../../redux/actions/commonFilters/index";
import {
  resetActiveSidebarItem,
  setActiveSidebarItem,
} from "../../../../../redux/actions/filter";
class SideMenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    selectedCategoryId: null,
    areAllCategoriesHidden: false,
  };

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      );
    }
    if (
      prevProps.activeBreadcrumbs !== this.props.activeBreadcrumbs &&
      _.isEmpty(this.props.activeBreadcrumbs)
    ) {
      this.setState({
        ...this.state,
        areAllCategoriesHidden: false,
      });
    }
    if (prevProps.sidebarState !== this.props.sidebarState) {
      // console.log("NOVI SIDEBARSTATEEEEEEEEEEEE", this.props.sidebarState);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { setBreadcrumbsClick, isBreadcrumbsClicked } = this.props;
    if (
      !_.isEqual(nextProps.isBreadcrumbsClicked, isBreadcrumbsClicked) &&
      nextProps.isBreadcrumbsClicked
    ) {
      this.handleGroupClick(null, null);
      setBreadcrumbsClick(false);
    }
    if (
      !!nextProps.activeSidebarItem &&
      !nextProps.shouldResetSidebarItem &&
      this.props.activeSidebarItem !== nextProps.activeSidebarItem
    ) {
      const items = [...nextProps.activeBreadcrumbs];
      if (nextProps.activeSidebarItem.is_last) {
        items.pop();
      }
      this.setState(
        {
          activeGroups: items,
          tempArr: items,
          currentActiveGroup: nextProps.activeSidebarItem.is_last ? items : [],
          areAllCategoriesHidden: nextProps.activeSidebarItem.is_last,
        },
        () => {
          nextProps.setActiveItem(
            nextProps.activeSidebarItem.is_last
              ? nextProps.activeSidebarItem.id
              : "/"
          );
        }
      );
    } else if (nextProps.shouldResetSidebarItem) {
      this.setState(
        {
          activeGroups: [],
          tempArr: [],
          currentActiveGroup: [],
          areAllCategoriesHidden: false,
        },
        () => {
          nextProps.resetActiveItem(false);
        }
      );
    }
  }

  handleGroupClick = (item, parent = null) => {
    const {
      resetCategoryAttributes,
      setCurrentCategory,
      getCategoryAttributes,
      getCategoryMeasurementUnitOptions,
      breadcrumbs,
      currentCategory,
    } = this.props;
    if (item === null && parent === null) {
      // click on breadcrumbs group
      const categoryId = currentCategory.id;
      if (breadcrumbs.includes(categoryId)) {
        breadcrumbs.splice(breadcrumbs.indexOf(categoryId) + 1);
      }
      // reset old attributes
      let activeItems = null;
      breadcrumbs.forEach((item) => {
        // eslint-disable-line array-callback-return
        activeItems = null;
        // activeItems = {id: category.id, title: category.name};
      });
      this.setState(
        {
          activeGroups: breadcrumbs,
          tempArr: breadcrumbs,
          currentActiveGroup: breadcrumbs,
        },
        () => this.showHideCategoriesButton(false)
      );
      this.props.handleActiveItem(activeItems);
    } else {
      const parent1 = parent === null ? item.parentCategory : parent;
      let open_group = this.state.activeGroups;
      let active_group = this.state.currentActiveGroup;
      let temp_arr = this.state.tempArr;
      // Active Group to apply sidebar-group-active class
      if (item.type === "item" && parent1 === null) {
        active_group = [];
        temp_arr = [];

        // dodano
        active_group.push(item.id);
        temp_arr.push(item.id);
      } else if (item.type === "item" && parent1 !== null) {
        active_group = [];
        if (temp_arr.includes(parent1)) {
          temp_arr.splice(temp_arr.indexOf(parent1) + 1, temp_arr.length);
        } else {
          temp_arr = [];
          temp_arr.push(parent1);
        }
        active_group = temp_arr.slice(0);
        // active_group.push(item.id)
      } else if (item.type === "collapse" && parent1 === null) {
        this.props.setActiveItem(null);
        this.props.setActiveItem2(null);
        if (_.isEmpty(temp_arr)) {
          temp_arr.push(item.id);
        } else {
          temp_arr = [];
          temp_arr.push(item.id);
        }
      } else if (item.type === "collapse" && parent1 !== null) {
        if (active_group.includes(parent1)) {
          // temp_arr = active_group;
          temp_arr = active_group.slice(0);
        }
        if (temp_arr.includes(item.id)) {
          // temp_arr.splice(temp_arr.indexOf(id), 1)
          temp_arr.splice(temp_arr.indexOf(item.id) + 1, temp_arr.length);
        } else {
          if (temp_arr.includes(parent1)) {
            temp_arr.splice(temp_arr.indexOf(parent1) + 1);
            temp_arr.push(item.id);
          } else {
            temp_arr.push(item.id);
          }
        }
        // console.log("KRAJ temp_arr", temp_arr);
      } else {
        temp_arr = [];
      }
      if (item.type === "collapse") {
        // set current category
        resetCategoryAttributes();
        setCurrentCategory({ id: item.id, name: item.title });
        getCategoryAttributes(item.id);
        getCategoryMeasurementUnitOptions(item.id);
        if (!_.isEmpty(active_group) && parent1 === null) {
          active_group = [];
          open_group = [];
          if (parent1 === null) {
            open_group.push(item.id);
          }
          // open_group.push(item.id);
          // open_group.push(item.id)
          this.showHideCategoriesButton(false);
        } else {
          // If open group does not include clicked group item
          if (!open_group.includes(item.id)) {
            // Get unmatched items that are not in the active group
            let temp = open_group.filter(function (obj) {
              return active_group.indexOf(obj) === -1;
            });
            // Remove those unmatched items from open group
            if (temp.length > 0 && !open_group.includes(parent1)) {
              open_group = open_group.filter(function (obj) {
                return !temp.includes(obj);
              });
            }
            if (
              open_group.includes(parent1) &&
              active_group.includes(parent1)
            ) {
              open_group = active_group.slice(0);
            }
            // Add group item clicked in open group
            if (!open_group.includes(item.id)) {
              if (open_group.includes(parent1)) {
                open_group.splice(open_group.indexOf(parent1));
                open_group.push(parent1);
                open_group.push(item.id);
              } else {
                open_group.push(item.id);
              }
            }
          } else {;
            // If open group includes click group item, remove it from open group
            open_group = [...open_group].filter(id => item.id !== id);
            active_group = [...active_group].filter(id => item.id !== id);
          }
        }
      }
      if (item.type === "item") {
        open_group = active_group.slice(0);
        this.props.setBreadcrumbs(open_group);
        this.showHideCategoriesButton(true);
      } else {
        if (parent1 === null) {
          this.props.setBreadcrumbs(temp_arr);
        } else {
          this.props.setBreadcrumbs(temp_arr);
        }
      }
      if (item.isLast === false) {
        this.props.setActiveItem("/");
      }
      this.setState({
        activeGroups: open_group,
        tempArr: temp_arr,
        currentActiveGroup: active_group,
      });
    }
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
  };

  createSidebarConfig = (categories, lvl) => {
    const sidebarConfig = [];
    sidebarConfig.push(
      categories.map((item) => {
        const configItem = {};
        configItem.id = item.id;
        configItem.title = item.name;
        configItem.type = !item.is_last ? "collapse" : "item";
        configItem.isLast = item.is_last;
        configItem.icon =
          item.parent_category !== null ? <Icon.Circle size={10} /> : item.icon;
        configItem.parentCategory = item.parent_category;
        configItem.level = lvl;
        if (!item.is_last) {
          configItem.children = this.createSidebarConfig(
            item.children,
            lvl + 1
          );
        }
        return configItem;
      })
    );
    return sidebarConfig[0];
  };

  showHideCategoriesButton = (value) => {
    this.setState({
      ...this.state,
      areAllCategoriesHidden: value,
    });
  };

  renderCategoryIcon = (itemIcon) => {
    // Truck, Target, Monitor, ShoppingCart, Home, Server, Grid, Coffee, Users, Anchor, Wind, Feather
    const iconSize = 14;
    switch (itemIcon) {
      case "Truck":
        return <Truck size={iconSize}></Truck>;
      case "Target":
        return <Target size={iconSize}></Target>;
      case "Monitor":
        return <Monitor size={iconSize}></Monitor>;
      case "ShoppingCart":
        return <ShoppingCart size={iconSize}></ShoppingCart>;
      case "Home":
        return <Home size={iconSize}></Home>;
      case "Server":
        return <Server size={iconSize}></Server>;
      case "Grid":
        return <Grid size={iconSize}></Grid>;
      case "Coffee":
        return <Coffee size={iconSize}></Coffee>;
      case "Users":
        return <Users size={iconSize}></Users>;
      case "Anchor":
        return <Anchor size={iconSize}></Anchor>;
      case "Wind":
        return <Wind size={iconSize}></Wind>;
      case "Feather":
        return <Feather size={iconSize}></Feather>;
      default:
        break;
    }
  };

  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    const { categories, activeItemState } = this.props;
    const shouldHideAllCategories = this.state.areAllCategoriesHidden;
    let visibleCategories = !shouldHideAllCategories
      ? categories
      : this.props.breadcrumbs !== null
      ? categories.filter((item) => item.id === this.props.breadcrumbs[0])
      : categories;

    const menuItems = this.createSidebarConfig(visibleCategories, 1).map(
      (item) => {
        // eslint-disable-line array-callback-return
        // const CustomAnchorTag = Link;
        let renderItem = (
          <li
            className={classnames("nav-item", {
              "has-sub": item.type === "collapse",
              open: this.state.activeGroups.includes(item.id),
              "sidebar-group-active": this.state.currentActiveGroup.includes(
                item.id
              ),
              hover: this.props.hoverIndex === item.id,
              active:
                this.props.activeItemState === item.id && item.type === "item",
              disabled: item.disabled,
            })}
            key={item.id}
            onClick={(e) => {
              e.stopPropagation();
              if (item.type === "item") {
                this.props.handleActiveItem(item);
                this.handleGroupClick(item, null);
                if (this.props.deviceWidth <= 1200 && item.type === "item") {
                  this.props.toggleMenu();
                }
              } else {
                this.handleGroupClick(item, null);
              }
            }}
          >
            <a
              href={window.location.href}
              className={`d-flex ${
                item.badgeText
                  ? "justify-content-between"
                  : "justify-content-start"
              }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id);
              }}
              key={item.id}
              onClick={(e) => {
                return item.type === "collapse"
                  ? e.preventDefault()
                  : console.log("onClickkk");
              }}
              target={item.newTab ? "_blank" : undefined}
              rel="noreferrer"
            >
              <div className="menu-text">
                {this.renderCategoryIcon(item.icon)}
                {(!this.props.collapsedContent ||
                  (this.props.collapsedContent &&
                    !this.props.sidebarState)) && (
                  <span className="menu-item menu-title">{item.title}</span>
                )}
              </div>

              {item.type === "collapse" ? (
                <ChevronRight className="menu-toggle-icon" size={13} />
              ) : (
                ""
              )}
            </a>
            {item.type === "collapse" ? (
              <SideMenuGroup
                group={item}
                handleGroupClick={this.handleGroupClick}
                activeGroup={this.state.activeGroups}
                handleActiveItem={this.props.handleActiveItem}
                activeItemState={this.props.activeItemState}
                handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
                activePath={this.props.activePath}
                hoverIndex={this.props.hoverIndex}
                initRender={this.initRender}
                parentArr={this.parentArr}
                triggerActive={undefined}
                currentActiveGroup={this.state.currentActiveGroup}
                permission={this.props.permission}
                currentUser={this.props.currentUser}
                redirectUnauthorized={this.redirectUnauthorized}
                collapsedMenuPaths={this.props.collapsedMenuPaths}
                toggleMenu={this.props.toggleMenu}
                deviceWidth={this.props.deviceWidth}
                sidebarState={this.props.sidebarState}
                setActiveItem={this.props.setActiveItem}
                sidebarHidden={this.props.sidebarHidden}
              />
            ) : (
              ""
            )}
          </li>
        );

        if (
          item.navLink &&
          item.collapsed !== undefined &&
          item.collapsed === true
        ) {
          this.collapsedPath = item.navLink;
          this.props.collapsedMenuPaths(item.navLink);
        }

        if (item.type === "collapse" || item.type === "item") {
          return renderItem;
        } else {
          return null;
        }
      }
    );
    return (
      <>
        {menuItems}
        {this.state.areAllCategoriesHidden &&
          (!this.props.collapsedContent ||
            (this.props.collapsedContent && !this.props.sidebarState)) && (
            <li className="vvg-show-all-categories-container">
              <Button
                className={"vvg-show-all-categories-button btn"}
                // color="deoco"
                onClick={() => this.showHideCategoriesButton(false)}
              >
                {I18n.t("lblShowAllCategories")}
              </Button>
            </li>
          )}
        <hr style={{ margin: "10px" }} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.data.categories,
    breadcrumbs: state.selectedData.breadcrumbs,
    activeBreadcrumbs: state.selectedData.activeBreadcrumbs,
    currentCategory: state.selectedData.currentCategory,
    isBreadcrumbsClicked: state.selectedData.isBreadcrumbsClicked,
    categoryNames: state.commonFilters.categoryNameOptions,
    activeSidebarItem: state.filter.activeSidebarItem,
    shouldResetSidebarItem: state.filter.resetActiveSidebarItem,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryAttributes: (id) => dispatch(getCategoryAttributes(id)),
    setCurrentCategory: (category) => dispatch(setCurrentCategory(category)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    resetCategoryAttributes: () => dispatch(resetCategoryAttributes()),
    setBreadcrumbsClick: (value) => dispatch(setBreadcrumbsClick(value)),
    getCategoryMeasurementUnitOptions: (categoryId) =>
      dispatch(getCategoryMeasurementUnitOptions(categoryId)),
    resetActiveItem: (flag) => dispatch(resetActiveSidebarItem(flag)),
    setActiveItem2: (id) => dispatch(setActiveSidebarItem(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuContent);
