import {
    DEFAULT_MULTISEARCH_CLASS_NAME,
    OPTIONS_TO_BOTTOM_CLASS_NAME,
    OPTIONS_TO_TOP_CLASS_NAME_34,
    OPTIONS_TO_TOP_CLASS_NAME_56,
    SELECT_34_CLASS_NAME,
    SELECT_56_CLASS_NAME,
} from './constants';


export const idsToNamesString = (selectedIds, options) =>
    selectedIds
        .map(id => options.find(option => option.id.toString() === id.toString()).name || null)
        .filter(name => name !== null)
        .join(', ');

export const idToNameString = (selectedItem = '', options) => {
    return options.find(option => selectedItem.toString() === option.id.toString())?.name || null;
}

export const sortObjectsById = (a, b) => {
    if (a.id.length < b.id.length) {
        return 1;
    }
    if (a.id.length > b.id.length) {
        return -1;
    }
    if (a.id > b.id) {
        return 1;
    }
    if (a.id < b.id) {
        return -1;
    }
    return 0;
};

export const handleOnChange = (selectedIds, id) => {
    const selectedIdsSet = new Set(selectedIds);
    if (selectedIdsSet.has(id)) {
        selectedIdsSet.delete(id);
    } else {
        selectedIdsSet.add(id);
    }
    return Array.from(selectedIdsSet);
};

export const resolveSelectClassName = (isSelectOpen, selectedIds, isSearch = true) => {
    if (!isSelectOpen) {
        return SELECT_34_CLASS_NAME;
    }
    if (!isSearch) {
        return SELECT_34_CLASS_NAME;
    }
    if (selectedIds.length > 0) {
        return SELECT_56_CLASS_NAME;
    }
    return SELECT_34_CLASS_NAME;
};

export const resolveOptionsClassName = (offsetBottom, selectClassName, isSearch = true) => {
    if (offsetBottom > 135) {
        return OPTIONS_TO_BOTTOM_CLASS_NAME;
    }
    if (selectClassName === SELECT_34_CLASS_NAME) {
        return OPTIONS_TO_TOP_CLASS_NAME_34;
    }
    if (!isSearch) {
        return OPTIONS_TO_TOP_CLASS_NAME_34;
    }
    return OPTIONS_TO_TOP_CLASS_NAME_56;
};

export const resolveWrapperClassName = (className) => {
    return [DEFAULT_MULTISEARCH_CLASS_NAME, className].join(' ');
}
