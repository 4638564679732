import React, { useRef } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import '../VvgTopbar.css';
import { useOutsideClickListener } from '../../../../utility/Hooks';

const VvgCustomDropdownSelector = (props) => {
    const { isMobile, close, items, selectedId, isOpen, handleClick, handleItemClick, wrapperSize, menuItemSize = 10, placeholder } = props;
    const style = {
        width: `${menuItemSize}%`,
    };
    if (isMobile) {
        style['height'] = '40px';
    }
    const ref = useRef(null);
    useOutsideClickListener(ref, close, 'custom');
    return (
        <div
            ref={ref}
            style={style}
            className="menu-item space-center"
        >
            <Button
                isOpen={isOpen}
                selectedId={selectedId}
                items={items}
                placeholder={placeholder}
                handleClick={handleClick}
                isMobile={isMobile}
            />
            {isOpen &&
                <Wrapper
                    items={items}
                    handleItemClick={handleItemClick}
                    wrapperSize={wrapperSize}
                    selectedId={selectedId}
                    close={close}
                    isMobile={isMobile}
                />
            }
        </div>
    );
};

export default VvgCustomDropdownSelector;

const Button = (props) => {
    const { isOpen, items, selectedId, placeholder, handleClick, isMobile } = props;
    
    const style = {
        // color: '#2c2c2c'
        color: '#626262'
    };

    const ArrowIcon = !isOpen
        ? <ChevronDown className="button-arrow" style={style} />
        : <ChevronUp className="button-arrow" style={style} />;
    
    const title = items.find(item => item.id === selectedId)?.name || placeholder;
    return (
            <div
                className={`menu-item size-100 clickable-item space-center`}
                onClick={() => handleClick()}
            >
                <span
                    className="button-text"
                    style={{
                        fontSize: '14px',
                        fontWeight: isMobile ? 'regular' : 'bold',
                        color: '#626262',
                    }}
                >
                    {title}
                </span>
                {ArrowIcon}
            </div>
    );
};

const Wrapper = (props) => {
    const { isMobile, selectedId, items, close, handleItemClick, wrapperSize } = props;
    const style = {
        width: isMobile ? '100%' : `calc(${wrapperSize}% - 20px)`,
    };
    if (isMobile) {
        style['top'] = '40px';
        style['borderRadius'] = '5px';
        style['border'] = '1px solid rgba(34, 41, 47, 0.15)';
    }
    return (
        <div
            className="wrapper-container"
            style={style}
        >
            {items.map((c, index) => {
                const { name, id } = c;
                return <Item
                    selectedId={selectedId}
                    handleItemClick={handleItemClick}
                    name={name}
                    id={id}
                    key={index}
                    close={close}
                />;
            })}
        </div>
    );
};

const Item = (props) => {
    const { id, name, handleItemClick, close, selectedId } = props;
    return (
        <div className="sub-menu-item size-25">
            <span
                onClick={(e) => {
                    e.preventDefault();
                    handleItemClick(id);
                    close();
                }}
                className={`sub-menu-item-link ${selectedId === id ? 'sub-menu-item-link-active' : ''}`}
            >{name}</span>
        </div>
    );
}