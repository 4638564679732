import React from 'react';

import '../VvgTopbar.css';
import { DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';

const VvgMenuDropdownSelector = (props) => {
    const { isMobile, items = [], isOpen = false, handleClick, handleItemClick, selectedId, itemSize = 10 } = props;
    const title = items.find(item => item.id === selectedId)?.name || '';
    const iconStyle = {
        // color: '#2c2c2c',
        color: '#626262',
        fontWeight: isMobile ? 'regular' : 'bold',
    };
    const ArrowIcon = !isOpen
        ? <ChevronDown className="button-arrow" style={iconStyle} />
        : <ChevronUp className="button-arrow" style={iconStyle} />;
    const style = {
        width: `${itemSize}%`,
    };
    if (isMobile) {
        style['height'] = '40px';
    }
    return (
        <div style={style} className="menu-item clickable-item  space-center">
                <ButtonDropdown
                    isOpen={isOpen}
                    toggle={handleClick}
                >
                    <DropdownToggle
                        color={'#2c2c2c'}
                        className={`button-text ${isMobile ? 'button-text-mobile-hover' : 'button-text-hover'} menu-item-center vvg-topbar-item`}
                        style={iconStyle}
                        >
                        {title}
                        <span onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClick();
                        }}>{ArrowIcon}</span>
                        
                    </DropdownToggle>
                    <DropdownMenu
                        className="menu-dropdown vvg-dropdown-menu"
                    >
                        {items.map(item => {
                            const { id, name } = item;
                            return (
                                <DropdownItem
                                    key={id}
                                    tag="a"
                                    onClick={() => handleItemClick(id)}
                                    className={selectedId === id ? "menu-dropdown-item selected-menu-item" : "menu-dropdown-item"}
                                >
                                    {name}
                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                </ButtonDropdown>             
        </div>
    );
}

export default VvgMenuDropdownSelector;