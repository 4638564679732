import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import "../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";

// format: yyyy-MM-ddThh:mm:ss
class Timepicker extends React.Component {
  state = {
    dateTimePicker: new Date(),
  };

  render() {
    let { dateTimePicker } = this.state;

    return (
      <Flatpickr
        className="form-control"
        data-enable-time
        value={dateTimePicker}
        onChange={(date) => {
          this.setState({ dateTimePicker: date });
        }}
      />
    );
  }
}

export default Timepicker;
