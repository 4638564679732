import React, { useState, useEffect, useRef } from 'react';
import fuzzysort from 'fuzzysort';
import { X } from 'react-feather';
import { I18n } from 'react-redux-i18n';
import { useOutsideClickListener } from '../../../utility/Hooks';
import { FUZZY_SEARCH_OPTIONS } from '../common/utils/constants';
import {
    idToNameString,
    resolveOptionsClassName,
    resolveSelectClassName,
    resolveWrapperClassName,
    sortObjectsById,
} from '../common/utils/helpers';
import Measure from 'react-measure';

const SingleSearchDropdown = (props) => {
    const {
        className,
        style,
        selectedItem,
        options = [],
        onChange,
        hasClearButton = true,
        shouldRecalcOffset = true,
        bottomToTop = false,
        sendFlagOnInputFocusOrBlur = () => {},
    } = props;

    const [wrapperClassName, setWrapperClassName] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isSelectOpen, setSelectOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectClassName, setSelectClassName] = useState('');
    const [optionsClassName, setOptionsClassName] = useState('');
    const [offsetBottom, setOffsetBottom] = useState(shouldRecalcOffset || bottomToTop ? 0 : 200);
    const [isReadOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setWrapperClassName(resolveWrapperClassName(className));
    }, [className]);

    useEffect(() => {
        if (options.length === 0) {
            return;
        }
        if (inputValue.length === 0) {
            setFilteredOptions(options);
        } else {
            setFilteredOptions(
                fuzzysort.go(inputValue, options, FUZZY_SEARCH_OPTIONS)
                    .map(fs => fs.obj)
                    .sort(sortObjectsById)
            );
        }
    }, [options, inputValue]);

    useEffect(() => {
        const selectedIds = selectedItem !== null && selectedItem !== undefined ? [selectedItem] : [];
        setSelectClassName(resolveSelectClassName(isSelectOpen, selectedIds));
    }, [selectedItem, isSelectOpen]);

    useEffect(() => {
        setOptionsClassName(resolveOptionsClassName(offsetBottom, selectClassName));
    }, [offsetBottom, selectClassName]);

    useEffect(() => {
        if (selectedItem !== null && selectedItem !== undefined && options.length === 1) {
            setReadOnly(true);
        } 
    }, [selectedItem, options]);

    const onSelectClick = (e) => {
        e.stopPropagation();
        if (isSelectOpen) {
            setInputValue('');
        }
        setSelectOpen(!isSelectOpen);
    };

    const closeSelect = () => {
        if (isSelectOpen) {
            setInputValue('');
            setSelectOpen(false);
        }
    };

    const onInputChange = (e) => {
        e.persist();
        setInputValue(e.target.value);
    };

    const onInputClick = (e) => {
        e.stopPropagation();
        if (!isSelectOpen) {
            setSelectOpen(!isSelectOpen);
        }
    };

    const onClearButtonClick = (e) => {
        e.stopPropagation();
        setInputValue('');
        onChange(undefined);
    };

    const onOptionClick = (id) => {
        onChange(id);
        closeSelect();
    };
    const selectedOption = idToNameString(selectedItem, options);
    const shouldShowSelectedOption = selectedItem !== null && selectedItem !== undefined;
    const shouldShowInput = !(selectedItem !== null && selectedItem !== undefined) || isSelectOpen;
    const shouldShowClearButton = hasClearButton && (
        (selectedItem !== null && selectedItem !== undefined && selectedItem.length !== 0) || inputValue.length > 0
    );

    const wrapperRef = useRef(null);
    useOutsideClickListener(wrapperRef, closeSelect);
    const inputPlaceholder = isSelectOpen
        ? I18n.t('lblSearch')
        : I18n.t('lblSelect');
    return (
        <div
            ref={wrapperRef}
            className={wrapperClassName}
            style={style}
        >
            <Measure
                bounds
                onResize={(contentRect) => {
                    const ob = window.innerHeight - contentRect.bounds?.top - contentRect.bounds?.height;
                    shouldRecalcOffset && setOffsetBottom(ob);
                }}
            >
                {({ measureRef }) => (
                    <div
                        ref={measureRef}
                        className={`select ${!isReadOnly ? selectClassName : 'read-only'}`}
                        onClick={onSelectClick}
                    >
                        <div
                            className={`select-content`}
                        >
                            {shouldShowSelectedOption &&
                                <div
                                    className="selected-options"
                                >
                                    {selectedOption}
                                </div>
                            }
                            {shouldShowInput && !isReadOnly &&
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={onInputChange}
                                    onClick={onInputClick}
                                    placeholder={inputPlaceholder}
                                    autoFocus={false}
                                    disabled={!isSelectOpen}
                                    onFocus={(e) => sendFlagOnInputFocusOrBlur(true)}
                                    onBlur={(e) => sendFlagOnInputFocusOrBlur(false)}
                                />

                            }
                        </div>
                        {shouldShowClearButton && !isReadOnly &&
                            <div className="select-clear-button">
                                <X
                                    className="clear-button"
                                    onClick={onClearButtonClick}
                                />
                            </div>
                        }
                    </div>
                )}
            </Measure>
            {isSelectOpen && !isReadOnly &&
                <Options
                    optionsClassName={optionsClassName}
                    filteredOptions={filteredOptions}
                    onOptionClick={onOptionClick}
                    selectedItem={selectedItem}
                />
            }
        </div>
    );
};

const Options = (props) => {
    const {
        optionsClassName,
        filteredOptions,
        onOptionClick,
        selectedItem
    } = props;

    return (
        <div
            className={`options ${optionsClassName}`}
        >
            {filteredOptions.length === 0
                ? <div
                    className="no-option"
                >
                    {I18n.t('lblNoOptions')}
                </div>
                : filteredOptions.map(({ id, name }, idx) => (
                    <div
                        key={idx}
                        className={`option ${selectedItem === id ? 'selected' : ''}`}
                        onClick={() => onOptionClick(id)}
                    >
                        {name}
                    </div>
                ))
            }

        </div>
    );
};

export default SingleSearchDropdown;