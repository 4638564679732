import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import classnames from "classnames";
import {
  setSidebarCommonFilter,
  setSidebarAdditionalFilter,
  setMinMaxError,
} from "../../redux/actions/filter/index";
import { Field, getFormSyncErrors, getFormValues, change } from "redux-form";
import { ReduxFormSelect } from "../ReduxFormComponents/ReduxSelect";
import "./VvgNumericInput.scss";
import { FILTER_TYPES, filtersForm } from "../../utility/Constants";
import * as Icon from "react-feather";
import { getActiveLanguage, setThousandSeparator } from '../../utility/Helpers';
import { loggedInUserThousandSeparatorSelector } from "../../redux/selectors/userSelectors";

class VvgNumericInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromValue: null,
      toValue: null,
      isUpdated: false,
      isMeasurementShown: false,
      isInitialValueSet: false,
      showFromError: false,
      showToError: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== undefined && !this.state.isInitialValueSet) {
      if (
        this.props.formValues !== undefined &&
        this.props.formValues[nextProps.data.name + "_measurement"]
      ) {
        // leave values from form
      } else {
        this.props.changeFilter(
          filtersForm,
          nextProps.data.name + "_measurement",
          this.props.userProfileData !== null &&
            !this.props.userProfileData.metric_system
            ? nextProps.data.measurement_units[1]
            : nextProps.data.measurement_units[0]
        );
      }

      this.setState({
        ...this.state,
        isInitialValueSet: true,
      });
    }
    // change measurementUnit if language changes
    if (
      nextProps.categoryMeasurementUnits !== this.props.categoryMeasurementUnits
    ) {
      if (this.props.formValues !== undefined) {
        const oldValue = this.props.formValues[this.props.measurementUnitsName];
        if (oldValue !== undefined) {
          let newValue;
          if (!_.isObject(oldValue)) {
            newValue = nextProps.categoryMeasurementUnits.find(
              (item) => item.id === Number(oldValue)
            );
          } else {
            newValue = {
              id: oldValue.id,
              metric_system: oldValue.metric_system,
              name: this.getMeasurementName(
                oldValue.id,
                nextProps.categoryMeasurementUnits
              ),
              sort_order: oldValue.sort_order,
            };
          }

          this.props.changeFilter(
            filtersForm,
            this.props.measurementUnitsName,
            newValue
          );
        }
      }
    }
  }

  getValue = (id, filterType) => {
    const filterValues = this.props.additionalFilters.filter(
      (item) => item.id === id
    );
    let fromValue = "";
    let toValue = "";
    if (
      filterValues !== undefined &&
      !_.isEmpty(filterValues) &&
      filterValues[0].value !== null
    ) {
      const splitValue = filterValues[0].value.split("~");
      if (filterType === "from") {
        fromValue = this.state.fromValue;
        toValue = splitValue[1];
      } else {
        fromValue = splitValue[0];
        toValue = this.state.toValue;
      }
    } else {
      if (filterType === "from") {
        fromValue = this.state.fromValue;
        toValue = "";
      } else {
        fromValue = "";
        toValue = this.state.toValue;
      }
    }
    if (fromValue && toValue) {
      return fromValue + "~" + toValue;
    } else if ((fromValue === null || fromValue === "") && toValue) {
      this.setState({
        ...this.state,
        fromValue: "",
      });
      return "~" + toValue;
    } else if (fromValue && (toValue === null || toValue === "")) {
      this.setState({
        ...this.state,
        toValue: "",
      });
      return fromValue + "~";
    } else {
      return null;
    }
  };

  setCommonFilter = (filters, id, value, name) => {
    const { setSidebarCommonFilter, filterName } = this.props;
    if (value !== "") {
      filters.push({
        id: id,
        value: value,
        filterName: filterName,
        name: name,
        type: FILTER_TYPES.NUMERIC,
      });
    } else {
      filters = filters.filter((item) => item.id !== id);
    }
    setSidebarCommonFilter(filters);
  };

  setFilters = (filters, filterType, value) => {
    const {
      data,
      setSidebarAdditionalFilter,
      setSidebarCommonFilter,
      isCommon,
      formValues,
    } = this.props;

    let measurementValues = null;

    if (
      !_.isEmpty(data.measurement_units) &&
      formValues[data.name + "_measurement"] !== undefined
    ) {
      const measurementId = formValues[data.name + "_measurement"].id;
      const measurementName = data.measurement_units.filter(
        (item) => item.id === measurementId
      )[0].name;

      measurementValues = {
        id: measurementId,
        name: measurementName,
      };
    }

    const computedValue = this.getValue(data.id, filterType);

    if (computedValue !== null) {
      filters.push({
        id: data.id,
        value: computedValue,
        filterName: data.name,
        name: computedValue,
        type: FILTER_TYPES.NUMERIC,
        measurement: measurementValues,
        filterOptions: {
          minimum: Number(data.minimum),
          maximum: Number(data.maximum),
        },
      });
    }

    isCommon
      ? setSidebarCommonFilter(filters)
      : setSidebarAdditionalFilter(filters);
  };

  handleChange(selectedItem, type) {
    const value =
      selectedItem !== null
        ? (selectedItem.id === 0 ? "0" : selectedItem.id) ||
          selectedItem.target.value
        : null;
    const {
      data,
      isCommon,
      commonFilters,
      additionalFilters,
      idFrom,
      idTo,
      formValues,
      measurementUnitsName,
    } = this.props;
    let filters = Object.assign(
      [],
      isCommon ? commonFilters : additionalFilters
    );
    const filterId = isCommon ? (type === "from" ? idFrom : idTo) : data.id;
    // reset measurement if from&to values are null
    if (_.isEmpty(value)) {
      if (type === "from" && formValues[idTo] === undefined) {
        filters = filters.filter((item) => item.id !== measurementUnitsName);
        this.props.changeFilter(filtersForm, measurementUnitsName);
      }
      if (type === "to" && formValues[idFrom] === undefined) {
        filters = filters.filter((item) => item.id !== measurementUnitsName);
        this.props.changeFilter(filtersForm, measurementUnitsName);
      }
    }
    filters = filters.filter((item) => item.id !== filterId);
    const id = type !== null ? (type === "from" ? idFrom : idTo) : null;
    if (isCommon) {
      this.setCommonFilter(filters, id, value, value);
    } else {
      if (type === "from") {
        this.setState(
          {
            fromValue: value,
            showFromError: this.checkMinMaxValues(value, type),
          },
          () => this.setFilters(filters, type, value)
        );
      } else {
        this.setState(
          {
            toValue: value,
            showToError: this.checkMinMaxValues(value, type),
          },
          () => this.setFilters(filters, type, value)
        );
      }
    }
  }

  checkMinMaxValues = (value, type) => {
    const { data, setMinMaxError, minMaxErrors } = this.props;

    const dataMaximum = Number(data.maximum);
    const dataMinimum = Number(data.minimum);

    let errors = Object.assign(minMaxErrors, []);
    const filterName = data.name.split(" ").join("") + "_" + type;
    errors = errors.filter((item) => item.id !== filterName);
    // check if value is between minimum and maximum filter value
    if (value !== null) {
      if (
        (!isNaN(dataMaximum) && data.maximum !== null && value > dataMaximum) ||
        (!isNaN(dataMinimum) && data.minimum !== null && value < dataMinimum)
      ) {
        errors.push({ id: filterName });
        setMinMaxError(errors);
        return true;
      }
    }

    setMinMaxError(errors);
    return false;
  };

  getMeasurementName = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i].name;
      }
    }
  };

  getFilterValue = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i].value;
      }
    }
  };

  handleChangeMeasurement = (value) => {
    const {
      data,
      isCommon,
      commonFilters,
      additionalFilters,
      setSidebarCommonFilter,
      setSidebarAdditionalFilter,
      measurementUnitsName,
    } = this.props;

    let filters = Object.assign(
      [],
      isCommon ? commonFilters : additionalFilters
    );
    let filterValue;
    if (data !== null && data !== undefined) {
      filterValue = this.getFilterValue(data.id, filters);
      filters = filters.filter((item) => item.id !== data.id);
    }
    if (isCommon) {
      if (measurementUnitsName !== null && measurementUnitsName !== undefined) {
        filters = filters.filter((item) => item.id !== measurementUnitsName);
      }
      if (value !== null) {
        const measurementValues = {
          id: value.id,
          name: value.name,
        };

        filters.push({
          id: measurementUnitsName, // "measurement_unit"
          value: String(value.id),
          filterName: value.name,
          name: value.name,
          type: FILTER_TYPES.MEASUREMENT_UNIT,
          measurement: measurementValues,
        });
      }
    } else {
      if (value !== null) {
        const measurementValues = {
          id: value.id,
          name: value.name,
        };
        filters.push({
          id: data.id,
          value: filterValue,
          filterName: data.name,
          name: data.name,
          type: FILTER_TYPES.NUMERIC,
          measurement: measurementValues,
          filterOptions: {
            minimum: Number(data.minimum),
            maximum: Number(data.maximum),
          },
        });
      }
    }

    isCommon
      ? setSidebarCommonFilter(filters)
      : setSidebarAdditionalFilter(filters);
    // }
  };

  getOptions = (data, inputType) => {
    const { activeLanguage, thousandSeparator } = this.props;
    const { filter_minimum, filter_maximum, filter_step } = data;
    const options = [];
    const min = Number(filter_minimum);
    const max = Number(filter_maximum);
    const step = Number(filter_step);
    if (this.props.formValues !== undefined) {
      if (inputType === "from") {
        const toValue = this.props.formValues[
          data.name.split(" ").join("") + "_to"
        ];
        if (toValue !== undefined && toValue !== null) {
          for (let i = min; i <= max; i += step) {
            if (i < Number(toValue.id)) {
              options.push({
                id: i,
                name:
                  data.skip_formatting
                    ? i
                    : thousandSeparator !== null && thousandSeparator !== undefined
                      ? setThousandSeparator(i, thousandSeparator)
                      : i.toLocaleString(activeLanguage),
              });
            }
          }
        } else {
          for (let i = min; i <= max; i += step) {
            options.push({
              id: i,
              name:
                data.skip_formatting
                  ? i
                  : thousandSeparator !== null && thousandSeparator !== undefined
                    ? setThousandSeparator(i, thousandSeparator)
                    : i.toLocaleString(activeLanguage),
            });
          }
        }
      } else {
        const fromValue = this.props.formValues[
          data.name.split(" ").join("") + "_from"
        ];
        if (fromValue !== undefined && fromValue !== null) {
          for (let i = min; i <= max; i += step) {
            if (i > Number(fromValue.id)) {
              options.push({
                id: i,
                name:
                  data.skip_formatting
                    ? i
                    : thousandSeparator !== null && thousandSeparator !== undefined
                      ? setThousandSeparator(i, thousandSeparator)
                      : i.toLocaleString(activeLanguage),
              });
            }
          }
        } else {
          for (let i = min; i <= max; i += step) {
            options.push({
              id: i,
              name:
                data.skip_formatting
                  ? i
                  : thousandSeparator !== null && thousandSeparator !== undefined
                    ? setThousandSeparator(i, thousandSeparator)
                    : i.toLocaleString(activeLanguage),
            });
          }
        }
      }
    }

    return options;
  };

  toggleMeasurement = () => {
    this.setState({
      ...this.state,
      isMeasurementShown: !this.state.isMeasurementShown,
    });
  };

  isFieldInMinMaxErrors = (fieldName) => {
    const { minMaxErrors } = this.props;
    let isFieldInErrors = false;
    minMaxErrors.forEach((item) => {
      // eslint-disable-line array-callback-return
      if (item.id === fieldName) {
        isFieldInErrors = true;
      }
    });
    return isFieldInErrors;
  };

  areFieldsEmpty = (data) => {
    const { formValues, idFrom, idTo } = this.props;
    const fromName =
      idFrom !== undefined
        ? idFrom
        : data !== undefined
        ? data.name.split(" ").join("") + "_from"
        : null;
    const toName =
      idTo !== undefined
        ? idTo
        : data !== undefined
        ? data.name.split(" ").join("") + "_to"
        : null;

    const fromValue =
      formValues !== undefined ? formValues[fromName] : undefined;
    const toValue = formValues !== undefined ? formValues[toName] : undefined;
    // const x = fromValue !== undefined && null) || (toValue !== undefined && null);
    return fromValue !== undefined || toValue !== undefined;
  };

  showData = (data) => {
    const {
      isCommon,
      idFrom,
      idTo,
      formErrors,
      categoryMeasurementUnits,
      measurementUnitsName,
    } = this.props;
    const hasCategoryMeasurementUnits = !_.isEmpty(categoryMeasurementUnits);
    const measurementUnits = hasCategoryMeasurementUnits
      ? categoryMeasurementUnits
      : data !== undefined
      ? data.measurement_units
      : null;

    if (data !== undefined && data.filter_minimum && data.filter_maximum) {
      return (
        <div>
          <div className="numeric-input-container">
            <Field
              name={
                data.name !== undefined
                  ? data.name.split(" ").join("") + "_from"
                  : "selectField"
              }
              component={ReduxFormSelect}
              options={this.getOptions(data, "from")}
              className={classnames(
                "react-numeric-input numeric-padding vvg-no-border",
                {
                  vvgerror2: this.isFieldInMinMaxErrors(
                    data.name !== undefined
                      ? data.name.split(" ").join("") + "_from"
                      : "selectField"
                  ),
                }
              )}
              onChange={(value) => this.handleChange(value, "from")}
              isNumeric
              isClearable={true}
              formErrors={formErrors}
            />
            <div className="separator-min-max"></div>
            <Field
              name={
                data.name !== undefined
                  ? data.name.split(" ").join("") + "_to"
                  : "selectField"
              }
              component={ReduxFormSelect}
              options={this.getOptions(data, "to")}
              className={classnames(
                "react-numeric-input numeric-padding vvg-no-border",
                {
                  vvgerror2: this.isFieldInMinMaxErrors(
                    data.name !== undefined
                      ? data.name.split(" ").join("") + "_to"
                      : "selectField"
                  ),
                }
              )}
              onChange={(value) => this.handleChange(value, "to")}
              isNumeric
              isClearable={true}
              formErrors={formErrors}
            />
          </div>
          {this.state.isMeasurementShown && (
            <Field
              name={
                hasCategoryMeasurementUnits
                  ? measurementUnitsName
                  : data.name + "_measurement"
              }
              component={ReduxFormSelect}
              options={measurementUnits}
              className="React react-numeric-input numeric-padding vvg-measurement-size vvg-no-border zindex-six"
              onChange={(value) => this.handleChangeMeasurement(value)}
              isNumeric
              isClearable={isCommon}
              isDisabled={
                (measurementUnits && measurementUnits.length === 1) ||
                !this.areFieldsEmpty(data)
              }
              formErrors={formErrors}
            />
          )}
        </div>
      );
    }
    return (
      <div>
        <div className="numeric-input-container">
          <Field
            name={
              isCommon
                ? idFrom
                : data.name !== undefined
                ? data.name.split(" ").join("") + "_from"
                : "selectField"
            }
            component="input"
            type="number"
            placeholder={I18n.t("lblFrom")}
            className={classnames("react-numeric-input", {
              vvgerror: this.state.showFromError,
            })}
            onChange={(value) => this.handleChange(value, "from")}
          />
          <div className="separator-min-max"></div>
          <Field
            name={
              isCommon !== undefined
                ? idTo
                : data.name !== undefined
                ? data.name.split(" ").join("") + "_to"
                : "selectField"
            }
            component="input"
            type="number"
            placeholder={I18n.t("lblTo")}
            className={classnames("react-numeric-input", {
              vvgerror: this.state.showToError,
            })}
            onChange={(value) => this.handleChange(value, "to")}
          />
        </div>
        {this.state.isMeasurementShown && (
          <Field
            name={
              hasCategoryMeasurementUnits
                ? measurementUnitsName
                : data !== undefined
                ? data.name + "_measurement"
                : "undefined"
            }
            component={ReduxFormSelect}
            options={measurementUnits}
            className="basic-multi-select react-numeric-input numeric-padding vvg-measurement-size vvg-no-border zindex-six"
            onChange={(value) => this.handleChangeMeasurement(value)}
            isNumeric
            isClearable={isCommon}
            isDisabled={
              (measurementUnits && measurementUnits.length === 1) ||
              !this.areFieldsEmpty(data)
            }
            formErrors={formErrors}
          />
        )}
      </div>
    );
  };
  render() {
    const { data, isCommon, filterName, categoryMeasurementUnits } = this.props;
    const showMeasurement =
      (data !== undefined && !_.isEmpty(data.measurement_units)) ||
      !_.isEmpty(categoryMeasurementUnits);
    const measurementIcon = this.state.isMeasurementShown ? (
      <Icon.Minus color="#b8c2cc" size={16} />
    ) : (
      <Icon.Plus color="#b8c2cc" size={16} />
    );
    return (
      <form>
        <div className="vvg-sidebar-filter-type-item-title">
          <div style={{ display: "flex" }}>
            {isCommon ? filterName : data.name}
            {showMeasurement && (
              <div
                style={{
                  marginTop: "-3px",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => this.toggleMeasurement()}
              >
                {measurementIcon}
              </div>
            )}
          </div>
        </div>
        {this.showData(data)}
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const activeLanguage = getActiveLanguage();
  const thousandSeparator = loggedInUserThousandSeparatorSelector(state);
  return {
    commonFilters: state.filter.sidebarFilter.commonFilters,
    additionalFilters: state.filter.sidebarFilter.additionalFilters,
    formErrors: getFormSyncErrors(filtersForm)(state),
    formValues: getFormValues(filtersForm)(state),
    initialValues: {},
    minMaxErrors: state.filter.minMaxErrors,
    i18n: state.i18n,
    userProfileData: state.user.userProfileData,
    activeLanguage,
    thousandSeparator,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
    changeFilter: (formName, fieldName, value) =>
      dispatch(change(formName, fieldName, value)),
    setMinMaxError: (filterData) => dispatch(setMinMaxError(filterData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgNumericInput);
