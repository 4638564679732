import { createSelector } from "reselect";
import {
  getCurrencyName,
  getTempCurrencyFromLocalStorage,
} from "../../utility/Helpers";

const userSelector = (state) => ({ ...state.user });
const organizationSelector = (state) => ({ ...state.organization });
const currenciesSelector = (state) => [...state.commonFilters.currencyOptions];
const userTypesSelector = (state) => [...state.commonFilters.userTypes];
const organizationTypesSelector = (state) => [
  ...state.commonFilters.organizationTypes,
];
const selectedDataSelector = (state) => ({ ...state.selectedData });

export const userLoggedInSelector = createSelector(
  userSelector,
  organizationSelector,
  (user, org) => {
    const { isUserLoggedIn, userProfileData = {} } = user;
    const { organizationProfileData = {} } = org;
    const hasUserProfileData = Object.keys(userProfileData === null ? {} : userProfileData).length !== 0;
    const hasOrganizationProfileData = Object.keys(organizationProfileData === null ? {} : organizationProfileData).length !== 0;
    const userToken = localStorage.getItem('vvg-user');
    const hasUserToken = userToken !== null && userToken !== null;
    return hasUserToken || hasOrganizationProfileData || hasUserProfileData || isUserLoggedIn;
  }
);

export const loggedInUserIdSelector = createSelector(
  userSelector,
  organizationSelector,
  (user, org) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return null;
    }
    const { organizationProfileData } = org;
    if (organizationProfileData !== null) {
      const { id } = organizationProfileData;
      if (id !== null) {
        return id;
      }
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { id } = userProfileData;
      if (id !== null) {
        return id;
      }
    }
    return null;
  }
);

export const loggedInCurrencySelector = createSelector(
  userSelector,
  selectedDataSelector,
  (user, selectedData) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      const { activeCurrency } = selectedData;
      if (activeCurrency !== null) {
        return activeCurrency;
      }
      return getTempCurrencyFromLocalStorage();
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { currency } = userProfileData;
      if (currency !== null) {
        return currency;
      }
    }
    return getTempCurrencyFromLocalStorage();
  }
);

export const loggedInCurrencyNameSelector = createSelector(
  loggedInCurrencySelector,
  currenciesSelector,
  (currency, currencies) => {
    if (currency !== null) {
      return getCurrencyName(currency, currencies);
    }
    return null;
  }
);

export const loggedInMetricSystemSelector = createSelector(
  userSelector,
  (user) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return true;
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { metric_system } = userProfileData;
      if (metric_system !== null) {
        return metric_system;
      }
    }
    return true;
  }
);

export const loggedInCountrySelector = createSelector(
  userSelector,
  organizationSelector,
  (user, org) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return null;
    }
    const { organizationProfileData } = org;
    if (organizationProfileData !== null) {
      const { country } = organizationProfileData;
      if (country !== null) {
        return country;
      }
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { country } = userProfileData;
      if (country !== null) {
        return country;
      }
    }
    return null;
  }
);

export const loggedInCitySelector = createSelector(
  userSelector,
  organizationSelector,
  (user, org) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return null;
    }
    const { organizationProfileData } = org;
    if (organizationProfileData !== null) {
      const { city } = organizationProfileData;
      if (city !== null) {
        return city;
      }
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { city } = userProfileData;
      if (city !== null) {
        return city;
      }
    }
    return null;
  }
);

export const loggedInListingDurationDaysSelector = createSelector(
  userSelector,
  organizationSelector,
  userTypesSelector,
  organizationTypesSelector,
  (user, org, userTypes, orgTypes) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return null;
    }
    const { organizationProfileData } = org;
    if (organizationProfileData !== null) {
      const { organization_type } = organizationProfileData;
      const { listing_duration_days = null } =
        orgTypes.find(
          ({ id }) => id.toString() === organization_type.toString()
        ) || {};
      if (listing_duration_days !== null) {
        return listing_duration_days;
      }
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { user_type } = userProfileData;
      if (user_type !== null) {
        const { listing_duration_days = null } =
          userTypes.find(({ id }) => id.toString() === user_type.toString()) ||
          {};
        if (listing_duration_days !== null) {
          return listing_duration_days;
        }
      }
    }
    return null;
  }
);

export const checkIsUserLoggedInUser = createSelector(
  loggedInUserIdSelector,
  (loggedInUserId) =>
    (userId) => {
      return userId === loggedInUserId;
    }
);

export const loggedInProfileImageSelector = createSelector(
  userSelector,
  organizationSelector,
  (user, org) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return null;
    }
    const { organizationProfileData } = org;
    if (organizationProfileData !== null) {
      const { profile_image } = organizationProfileData;
      if (profile_image !== null) {
        return profile_image;
      }
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { profile_image } = userProfileData;
      if (profile_image !== null) {
        return profile_image;
      }
    }
    return null;
  }
);

export const loggedInEmailSelector = createSelector(
  userSelector,
  organizationSelector,
  (user, org) => {
    const { isUserLoggedIn } = user;
    if (!isUserLoggedIn) {
      return null;
    }
    const { organizationProfileData } = org;
    if (organizationProfileData !== null) {
      const { email } = organizationProfileData;
      if (email !== null) {
        return email;
      }
    }
    const { userProfileData } = user;
    if (userProfileData !== null) {
      const { email } = userProfileData;
      if (email !== null) {
        return email;
      }
    }
    return null;
  }
);

export const loggedInUserThousandSeparatorSelector = createSelector(
  userSelector,
  (user) => {
    const { userCultureData } = user;
    if (userCultureData === null) {
      return null;
    }
    const { digit_grouping_symbol } = userCultureData;
    return digit_grouping_symbol;
  }
);

export const loggedInUserDecimalSeparatorSelector = createSelector(
  userSelector,
  (user) => {
    const { userCultureData } = user;
    if (userCultureData === null) {
      return null;
    }
    const { decimal_symbol } = userCultureData;
    return decimal_symbol;
  }
);
