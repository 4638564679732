/* eslint-disable no-prototype-builtins */
import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { routeTranslations, apiKey } from "../../utility/RouteRegistry";
import axios from "axios";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";
import {
  getTokenFromLocalStorage,
  getActiveLanguage,
  getTranslationsLastModifiedValue,
  getTranslationsFromLocalStorage,
} from "../../utility/Helpers";
import {
  localStorageTranslations,
  localStorageTempLanguage,
  localStorageTranslationsLastModified,
} from "../../utility/Constants";
import { getCategories, setIsLoading } from "../../redux/actions/data/index";
import { getItemTypeOptions } from "../../redux/actions/commonFilters/index";
import { setTranslationsLoadCompleted, setTranslationsLoadStarted } from '../actions/translationsLoader';

const middlewares = [thunk, createDebounce()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

const translations = localStorage.getItem(localStorageTranslations);

export const setTranslations = async (lang = null) => {
  await asyncSetTranslations(lang);
};

const asyncSetTranslations = async (lang = null) => {
  const language = lang !== null ? lang : getActiveLanguage();
  if (translations === null || translations === undefined || lang !== null) {
    await getTranslations(language);
  } else {
    syncTranslationWithStore(store);
    // I18n.setHandleMissingTranslation(handleMissingTranslation);
    store.dispatch(loadTranslations(JSON.parse(translations)));
    store.dispatch(setLocale(language));
  }
};

const getTranslations = async (lang = null) => {
  store.dispatch(setIsLoading(true));
  const language = lang !== null ? lang : getActiveLanguage();
  const lastModified = getTranslationsLastModifiedValue();
  const queryParams =
    lastModified !== false
      ? {
          language: language,
          api_key: apiKey,
          last_modified: lastModified,
        }
      : {
          language: language,
          api_key: apiKey,
        };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  try {
    store.dispatch(setTranslationsLoadStarted());
    const response = await axios.get(routeTranslations, { params: queryParams, headers: headers });
    const responseData = response.data;
      if (responseData) {
        const translationsObject = responseData.items[0].data;
        const maxTimestamp = responseData.items[0].last_modified;

        syncTranslationWithStore(store);
        // I18n.setHandleMissingTranslation(handleMissingTranslation);
        store.dispatch(loadTranslations(translationsObject));
        store.dispatch(setLocale(language));

        localStorage.setItem(
          localStorageTranslations,
          JSON.stringify(translationsObject)
        );
        localStorage.setItem(localStorageTempLanguage, language);
        localStorage.setItem(
          localStorageTranslationsLastModified,
          maxTimestamp
        );
        // update text used for filters
        if (lang !== null) {
          // !== null if called from VvgTopbar
          store.dispatch(getCategories());
          store.dispatch(getItemTypeOptions());
        }
        store.dispatch(setTranslationsLoadCompleted());
      }
  } catch (err) {
    const translationsFromLocalStorage = getTranslationsFromLocalStorage();
    if (
      translationsFromLocalStorage !== null &&
      translationsFromLocalStorage !== undefined
    ) {
      syncTranslationWithStore(store);
      // I18n.setHandleMissingTranslation(handleMissingTranslation);
      store.dispatch(loadTranslations(JSON.parse(translationsFromLocalStorage)));
      store.dispatch(setLocale(language));
    }
    store.dispatch(setTranslationsLoadCompleted());
    console.error(err);
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

const initialGetTranslations = async () => {
  await getTranslations();
};

initialGetTranslations();

export { store };
