export const apiKey = "03b7f69e-5cd2-4cd2-94b8-e59f95dc7b13";
export const baseUrl = "https://api.vevergo.com:4443";
// export const baseUrl = "https://148.251.11.213:4443";
export const authenticationTokenTest =
  "pom64brzn459zdt1mtmcybixxan92u64vwytpreelywqvfvkeh";
export const imageBaseUrl = `${baseUrl}/item_adjusted_images/`;
export const profileImageBaseUrl = `${baseUrl}/profile_adjusted_images/`;
export const confirmationMailUrl = `${window.location.origin}/confirmation-email`;
export const passwordResetUrl = `${window.location.origin}/password-reset`;
export const changeEmailUrl = `${window.location.origin}/change-email`;

/////// Routes
//////////////
export const routeCountries = `${baseUrl}/countries`;
export const routeTimezones = `${baseUrl}/timezones`;
export const routeDataTypes = `${baseUrl}/data_types`;
export const routeUserTypes = `${baseUrl}/user_types`;
export const routeOrganizationTypes = `${baseUrl}/organization_types`;
// category
export const routeCategoryTree = `${baseUrl}/category_tree`;
export function routeCategoryAttributes(id) {
  return `${baseUrl}/category/${id}/attributes`;
}
//favorites
export const routeFavoritesList = `${baseUrl}/item_listing_favorites`;
export const routeAddFavorite = `${baseUrl}/item_listing_favorite`;
// view
export const routeSearchItemListings = `${baseUrl}/view/search_item_listings`;
export const routeGetRecentItemListings = `${baseUrl}/view/recent_item_listings`
export const routeAutocomplete = `${baseUrl}/view/autocomplete`;
export function routeUserData(id) {
  return `${baseUrl}/view/user/${id}`;
}
export const routeUserImageById = (id) => `${baseUrl}/view/user/${id}/image`;
export function routeOrganizationData(id) {
  return `${baseUrl}/view/organization/${id}`;
}
export function routeTakeOverOrganizationProfile(id) {
  return `${baseUrl}/view/organization/${id}`;
}
export const routeSingleItemListing = `${baseUrl}/view/item_listing/`;
export const routeGetSingleItemListing = (itemId) => `${baseUrl}/item_listing/${itemId}`;
export const routePostSingleItemListing = `${baseUrl}/item_listing`;
// user
export const routeLogin = `${baseUrl}/user/login`;
export const routeRegistration = `${baseUrl}/user/native_registration`;
export const routeOrganizationsList = `${baseUrl}/user/organizations`;
export const routeOrganization = `${baseUrl}/user/organization`;
export function routeDeleteOrganization(id) {
  return `${baseUrl}/user/organization/${id}`;
}
export const routeUserPrivacy = `${baseUrl}/user/privacy`;
export const routeUser = `${baseUrl}/user`;
export const routeUserImage = `${baseUrl}/user/image`;
export const routeRegistrationConfirmation = `${baseUrl}/user/registration_confirmation`;
export const routeOrganizationUsers = `${baseUrl}/user/organization/users`;
export const routeSearchUsers = `${baseUrl}/user/users`;
export const routeOrganizationMemberships = `${baseUrl}/user/organization_memberships`;
export function routeDeleteOrganizationUser(id) {
  return `${baseUrl}/user/organization/users/${id}`;
}
export const routeReactivateAccount = `${baseUrl}/user/reactivate`;
export const routeResetPassword = `${baseUrl}/user/password_reset`;
export const routeResetPasswordConfirmation = `${baseUrl}/user/password_reset_confirmation`;
export const routeChangeEmail = `${baseUrl}/user/email`;
export const routeChangeEmailConfirmation = `${baseUrl}/user/email_confirmation`;
export const routeChangePassword = `${baseUrl}/user/password`;
export const routeUserCultureData = `${baseUrl}/user/culture_data`;

// other
export const routeTranslations = `${baseUrl}/ui_language_data`;

// user items
export const routeUserItemListings = `${baseUrl}/item_listings`;
export const routeUserItemListing = `${baseUrl}/item_listing`;
export const routeUserItemListingsById = (userId) => `${baseUrl}/view/user/${userId}/item_listings`;
export const routeOrganizationItemListingsById = (userId) => `${baseUrl}/view/organization/${userId}/item_listings`;

// images
export const routePostAdImage = (itemId) => `${baseUrl}/item_listing/${itemId}/image`;
export const routeDeleteAdImage = (imageId) => `${baseUrl}/item_listing_image/${imageId}`;
export const routeDeleteAdVideo = (videoId) => `${baseUrl}/item_listing_video/${videoId}`;
export const routeSetAdImageAsDefault = `${baseUrl}/default_item_image`;
export const routeGetItemMedia = (itemId) => `${baseUrl}/item_listing/${itemId}/media`;
export const routeViewItemMedia = (itemId) => `${baseUrl}/view/item_listing/${itemId}/media`;
export const routeSetImageComment = (imageId) => `${baseUrl}/item_listing_image/${imageId}/comment`;
export const routeSetVideoComment = (videoId) => `${baseUrl}/item_listing_video/${videoId}/comment`;
export const routePostVideo = (itemId) => `${baseUrl}/item_listing/${itemId}/video`;

// chat
export const routeGetAllContactThreads = `${baseUrl}/item_listing_contacts`;
export const routeGetSingleContactThread = (threadId) => `${baseUrl}/item_listing_contact/${threadId}`;
export const routeMarkMessageAsOpened = `${baseUrl}/item_listing_message_status`;
export const routeReplayToMessage = `${baseUrl}/item_listing_message`;
export const routeGetUnreadMessages = `${baseUrl}/item_listing_unread_messages`;
export const routeContactListingOwner = `${baseUrl}/item_listing_contact`;

export const routeReportItem = (itemId) => `${baseUrl}/item_listing_report/${itemId}`;
