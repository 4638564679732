import {
  SET_CURRENT_CATEGORY,
  SET_BREADCRUMBS,
  SET_SORT_BY,
  BREADCRUMBS_CLICKED,
  RESET_SELECTED_DATA,
  SET_ACTIVE_LANGUAGE,
  SET_ACTIVE_CURRENCY,
  SET_ACTIVE_BREADCRUMBS,
  SET_AUTOSUGGEST_INPUT,
} from "../../actions/selectedData";

const initialState = {
  currentCategory: null,
  breadcrumbs: null,
  sortBy: null,
  isBreadcrumbsClicked: false,
  activeLanguage: null,
  activeCurrency: null,
  activeBreadcrumbs: null,
  autosuggestInput: null,
};

const selectedDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.data,
      };
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.data,
      };
    case SET_ACTIVE_BREADCRUMBS:
      return {
        ...state,
        activeBreadcrumbs: action.data,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.data,
      };
    case BREADCRUMBS_CLICKED:
      return {
        ...state,
        isBreadcrumbsClicked: action.data,
      };
    case RESET_SELECTED_DATA:
      return {
        ...state,
        currentCategory: null,
        breadcrumbs: null,
        activeBreadcrumbs: null,
        sortBy: null,
        isBreadcrumbsClicked: false,
      };
    case SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: action.data,
      };
    case SET_ACTIVE_CURRENCY:
      return {
        ...state,
        activeCurrency: action.data,
      };
    case SET_AUTOSUGGEST_INPUT:
      return {
        ...state,
        autosuggestInput: action.data,
      };
    default:
      return state;
  }
};

export default selectedDataReducer;
