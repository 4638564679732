import React from 'react';
import { Mail } from 'react-feather';

export const VvgChatPreview = (props) => {
    const { isMobile, itemSize = 5, isHiddenInMobile, isHiddenInFull, numberOfUnreadMessages, isUserLoggedIn } = props;
    if (!isUserLoggedIn) {
        return null;
    }
    if (!isMobile && isHiddenInFull) {
        return null;
    }
    if (isMobile && isHiddenInMobile) {
        return null;
    }
    const style = {
        width: `${itemSize}%`,
    };

    const iconStyle = {
        // color: '#2c2c2c',
        color: '#626262',
        position: 'relative',
        height: '22px',
        width: '22px',
    };

    return (
        <a href="/communications" style={style} className="menu-item space-center">
            <div className={`menu-item ${isMobile ? 'size-100' : 'size-80'} clickable-item space-center`}>
                <div style={{position: 'relative'}}>
                <Mail className="button-arrow button-add-ad" style={iconStyle} />
                {numberOfUnreadMessages !== 0 &&
                    <div className="chat-preview-unread-messages"/>
                }
                </div>
            </div>
        </a>
    );
}
