import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import {
    getTokenFromLocalStorage,
    getAcceptLanguageHeader,
    getOrganizationFromLocalStorage,
} from "../../../utility/Helpers";

import {
    apiKey,
    profileImageBaseUrl,
    routeContactListingOwner,
    routeGetAllContactThreads,
    routeGetSingleContactThread,
    routeGetUnreadMessages,
    routeMarkMessageAsOpened,
    routeOrganizationData,
    routeReplayToMessage,
    routeSingleItemListing,
    routeUserData,
} from "../../../utility/RouteRegistry";
import { showWarning } from '../../../utility/Toast';
import { setIsLoading } from '../data';

export const GET_ALL_CONTACT_THREADS = 'GET_ALL_CONTACT_THREADS';
export const GET_SINGLE_CONTACT_THREAD = 'GET_SINGLE_CONTACT_THREAD';
export const GET_UNREAD_MESSAGES = 'GET_UNREAD_MESSAGES';
export const GET_ALL_CONTACT_IMAGES = 'GET_ALL_CONTACT_IMAGES';
export const CLEAR_CHAT_STATE = 'CLEAR_CHAT_STATE';

export const getAllContactThreads = (userId) => {
    const headers =
        getOrganizationFromLocalStorage() !== null
            ? {
                iss_authentication_token: getTokenFromLocalStorage(),
                iss_organization: getOrganizationFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            }
            : {
                iss_authentication_token: getTokenFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            };
    const params = {
        api_key: apiKey,
        size: 1000,
    };

    return dispatch => {
        dispatch(setIsLoading(true));
        axios.get(routeGetAllContactThreads, { headers, params }).then( async response => {
            if (response.data.error_id === 0) {
                const contactEntities = Array.from(
                    new Set(
                        response.data.items.map(({ contact_entity }) => contact_entity)
                    )
                );
                (userId !== null && userId !== undefined && userId !== '') && contactEntities.push(userId);
                const userImagesMap = {};
                for (let id of contactEntities) {
                    
                    const response = await axios.get(routeUserData(id), { headers, params });
                    if (response.data.error_id === 0) {
                        const imageId = response.data.items[0].profile_image;
                        const imageSrc = (imageId !== undefined && imageId !== null) ? `${profileImageBaseUrl}${imageId}.jpeg` : null;
                        userImagesMap[id] = imageSrc;
                    } else {
                        const orgResponse = await axios.get(routeOrganizationData(id), { headers, params });
                        if (orgResponse.data.error_id === 0) {
                            const imageId = orgResponse.data.items[0].profile_image;
                            const imageSrc = (imageId !== undefined && imageId !== null) ? `${profileImageBaseUrl}${imageId}.jpeg` : null;
                            userImagesMap[id] = imageSrc;
                        } else {
                            userImagesMap[id] = null;
                        }
                    }
                }
                dispatch({
                    type: GET_ALL_CONTACT_THREADS,
                    data: response.data.items,
                });
                dispatch({
                    type: GET_ALL_CONTACT_IMAGES,
                    data: userImagesMap,
                });
            } else {
                dispatch({
                    type: GET_ALL_CONTACT_THREADS,
                    data: [],
                });
                dispatch({
                    type: GET_ALL_CONTACT_IMAGES,
                    data: {},
                });
            }
            dispatch(setIsLoading(false));
        });
    };
};

export const resetSingleContactThread = () => {
    return dispatch => {
        dispatch({
            type: GET_SINGLE_CONTACT_THREAD,
            data: {},
        });
    }
};

export const getSingleContactThread = (threadId) => {
    const headers =
        getOrganizationFromLocalStorage() !== null
            ? {
                iss_authentication_token: getTokenFromLocalStorage(),
                iss_organization: getOrganizationFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            }
            : {
                iss_authentication_token: getTokenFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            };
    const params = {
        api_key: apiKey,
        size: 1000,
    };

    return dispatch => {
        dispatch(setIsLoading(true));
        axios.get(routeGetSingleContactThread(threadId), { headers, params }).then(async response => {
            if (response.data.error_id === 0) {
                const itemId = response.data.items[0].item_listing;
                const itemResponse = await axios.get(routeSingleItemListing + itemId, { headers, params });
                if (itemResponse.data.error_id === 0) {
                    const category = itemResponse.data.items[0].category;
                    dispatch({
                        type: GET_SINGLE_CONTACT_THREAD,
                        data: {
                            ...response.data.items[0],
                            category,
                            itemExists: true,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_SINGLE_CONTACT_THREAD,
                        data: {
                            ...response.data.items[0],
                            itemExists: false,
                        },
                    });
                }
                
            } else {
                dispatch({
                    type: GET_SINGLE_CONTACT_THREAD,
                    data: {},
                });
            }
            dispatch(setIsLoading(false));
        });
    };
};

export const markMessageAsOpen = (messageId) => {
    const headers =
        getOrganizationFromLocalStorage() !== null
            ? {
                iss_authentication_token: getTokenFromLocalStorage(),
                iss_organization: getOrganizationFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            }
            : {
                iss_authentication_token: getTokenFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            };
    const params = {
        api_key: apiKey,
    };
    return dispatch => {
        axios.put(routeMarkMessageAsOpened, { message_id: messageId }, { headers, params }).then(response => {
            if (response.data.error_id === 0) {
                axios.get(routeGetUnreadMessages, { headers, params: { ...params, size: 1000 } }).then(response => {
                    if (response.data.error_id === 0) {
                        dispatch({
                            type: GET_UNREAD_MESSAGES,
                            data: response.data.items,
                        });
                    } else {
                        dispatch({
                            type: GET_UNREAD_MESSAGES,
                            data: [],
                        });
                    }
                });
            }
        });
    };
}

export const replyToMessage = (threadId, message) => {
    const headers =
        getOrganizationFromLocalStorage() !== null
            ? {
                iss_authentication_token: getTokenFromLocalStorage(),
                iss_organization: getOrganizationFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            }
            : {
                iss_authentication_token: getTokenFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            };
    const params = {
        api_key: apiKey,
    };
    const data = {
        contact_id: threadId,
        message_data: message,
    };
    return dispatch => {
        axios.post(routeReplayToMessage, { ...data }, { headers, params }).then(response => {
            if (response.data.error_id === 0) {
                axios.get(routeGetSingleContactThread(threadId), { headers, params: { ...params, size: 1000 } }).then(response => {
                    if (response.data.error_id === 0) {
                        dispatch({
                            type: GET_SINGLE_CONTACT_THREAD,
                            data: response.data.items[0],
                        });
                    } else {
                        dispatch({
                            type: GET_SINGLE_CONTACT_THREAD,
                            data: {},
                        });
                    }
                });
            }
        });
    };
}

export const getUnreadMessages = () => {
    const headers =
        getOrganizationFromLocalStorage() !== null
            ? {
                iss_authentication_token: getTokenFromLocalStorage(),
                iss_organization: getOrganizationFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            }
            : {
                iss_authentication_token: getTokenFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            };
    const params = {
        api_key: apiKey,
        size: 1000,
    };
    return dispatch => {
        axios.get(routeGetUnreadMessages, { headers, params }).then(response => {
            if (response.data.error_id === 0) {
                dispatch({
                    type: GET_UNREAD_MESSAGES,
                    data: response.data.items,
                });
            } else {
                dispatch({
                    type: GET_UNREAD_MESSAGES,
                    data: [],
                });
            }
        });
    };
}

export const contactListingOwner = (id, message) => {
    const headers =
        getOrganizationFromLocalStorage() !== null
            ? {
                iss_authentication_token: getTokenFromLocalStorage(),
                iss_organization: getOrganizationFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            }
            : {
                iss_authentication_token: getTokenFromLocalStorage(),
                acceptLanguage: getAcceptLanguageHeader(),
            };
    const params = {
        api_key: apiKey,
    };
    return dispatch => {
        axios.put(routeContactListingOwner, { item_id: id, message_data: message }, { headers, params }).then(response => {
            if (response.error_id !== 0) {
                showWarning(I18n.t('lblMessageSuccess'));
            }
        });
    };
}

export const clearChatState = () => {
    return dispatch => dispatch({ type: CLEAR_CHAT_STATE });
};
