import React from 'react';
import '../VvgTopbar.css';
import { PlusSquare } from 'react-feather';

const VvgAddPost = (props) => {
    const { isMobile, itemSize = 5 , isUserLoggedIn } = props;
    if (!isUserLoggedIn) {
        return null;
    }
    const style = {
        width: `${itemSize}%`
    };
    const iconStyle = {
        color: '#626262',
        // color: isMobile ? '#626262' : '#2c2c2c',
        height: isMobile ? '26px' : '22px',
        width: isMobile ? '26px' : '22px',
    };

    return (
        <a href="/ads/add" style={style} className="menu-item space-center">
            <div className={`menu-item ${isMobile ? 'size-100' : 'size-80'} clickable-item space-center`}>
                <PlusSquare className="button-arrow button-add-ad" style={iconStyle} />
            </div>
        </a>
    );
};

export default VvgAddPost;