import {
  SET_SIDEBAR_COMMON_FILTER,
  SET_SIDEBAR_ADDITIONAL_FILTER,
  SET_APPLIED_COMMON_FILTER,
  SET_APPLIED_ADDITIONAL_FILTER,
  SET_INITIAL_FILTER,
  SET_MIN_MAX_ERROR,
  SET_ACTIVE_SIDEBAR_ITEM,
  RESET_ACTIVE_SIDEBAR_ITEM,
  RESET_ACTIVE_FILTERS,
} from "../../actions/filter/index";

const initialState = {
  sidebarFilter: {
    commonFilters: [],
    additionalFilters: [],
  },
  appliedFilter: {
    commonFilters: [],
    additionalFilters: [],
  },
  initialFilter: [],
  minMaxErrors: [],
  activeSidebarItem: null,
  resetActiveSidebarItem: false,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_COMMON_FILTER:
      return {
        ...state,
        sidebarFilter: {
          commonFilters: action.data,
          additionalFilters: state.sidebarFilter.additionalFilters,
        },
      };
    case SET_SIDEBAR_ADDITIONAL_FILTER:
      return {
        ...state,
        sidebarFilter: {
          commonFilters: state.sidebarFilter.commonFilters,
          additionalFilters: action.data,
        },
      };
    case SET_APPLIED_COMMON_FILTER:
      return {
        ...state,
        appliedFilter: {
          commonFilters: action.data,
          additionalFilters: state.appliedFilter.additionalFilters,
        },
      };
    case SET_APPLIED_ADDITIONAL_FILTER:
      return {
        ...state,
        appliedFilter: {
          commonFilters: state.appliedFilter.commonFilters,
          additionalFilters: action.data,
        },
      };
    case SET_INITIAL_FILTER:
      return {
        ...state,
        initialFilter: action.data,
      };
    case SET_MIN_MAX_ERROR:
      return {
        ...state,
        minMaxErrors: action.data,
      };
    case SET_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeSidebarItem: action.data,
      };
    case RESET_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        resetActiveSidebarItem: action.data,
        activeSidebarItem: null,
      };
    case RESET_ACTIVE_FILTERS:
      return {
        ...state,
        sidebarFilter: {
          commonFilters: [],
          additionalFilters: [],
        },
        appliedFilter: {
          commonFilters: [],
          additionalFilters: [],
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
