/* eslint-disable no-prototype-builtins */
import React from "react";
import _ from "lodash";
import {
  localStorageTempLanguage,
  localStorageToken,
  localStorageUser,
  localStorageTempCurrency,
  localStorageTranslationsLastModified,
  defaultLanguage,
  defaultLanguageId,
  localStorageOrganization,
  localStorageTranslations,
} from "./Constants";

export function getQueryParams(params, searchText) {
  const queryParams = {};
  params.forEach((item) => {
    // eslint-disable-line array-callback-return
    queryParams[item.id] = _.isObject(item.value) ? item.value[0] : item.value;
  });
  if (searchText) {
    queryParams['search_text'] = searchText;
  }
  return queryParams;
}

export function getPayloadParams(params) {
  const attributes = [];
  params.forEach((item) => {
    // eslint-disable-line array-callback-return
    if (!_.isEmpty(item.measurement)) {
      attributes.push({
        id: item.id,
        value: item.value,
        measurement_unit: item.measurement.id,
      });
    } else {
      attributes.push({
        id: item.id,
        value: item.value,
      });
    }
  });
  return attributes;
}

export const transformSelectValue = (item, options) => {
  const ids = item.value.split("|");
  const filters = ids.map((id) => {
    const option = options.find((optionItem) => optionItem.id === Number(id));
    return {
      id: option.id,
      name: option.name,
    };
  });
  return filters;
};

export function filterByKeys(obj, keys = {}) {
  const filtered = {};
  Object.keys(keys).forEach((item) => {
    if (obj.hasOwnProperty(item)) {
      filtered[item] = obj[item];
    }
  });
  return filtered;
}

// Local storage
///////////////////
export function getTokenFromLocalStorage() {
  return localStorage.getItem(localStorageToken);
}

export function getUserDataFromLocalStorage() {
  return localStorage.getItem(localStorageUser);
}

export function getOrganizationFromLocalStorage() {
  return localStorage.getItem(localStorageOrganization);
}

export function getTempCurrencyFromLocalStorage() {
  return localStorage.getItem(localStorageTempCurrency);
}

export function getTempLanguageFromLocalStorage() {
  return localStorage.getItem(localStorageTempLanguage);
}

export function getTranslationsLastModifiedFromLocalStorage() {
  return localStorage.getItem(localStorageTranslationsLastModified);
}

export function getTranslationsFromLocalStorage() {
  return localStorage.getItem(localStorageTranslations);
}

export function getBrowserLanguage() {
  return (
    String(navigator.language).substring(0, 2) ||
    String(navigator.userLanguage).substring(0, 2)
  );
}

export function getTranslationsLastModifiedValue() {
  const lastModified = getTranslationsLastModifiedFromLocalStorage();
  if (lastModified !== null && lastModified !== undefined) {
    return lastModified;
  }
  return false;
}

export function getActiveLanguage() {
  const language = getTempLanguageFromLocalStorage();
  if (language !== null && language !== undefined) {
    return language;
  } else if (
    getBrowserLanguage() !== null &&
    getBrowserLanguage() !== undefined
  ) {
    return getBrowserLanguage();
  } else {
    return defaultLanguage;
  }
}

///////////////////

export function findSelectedBreadcrumbs(data, target) {
  const memo = [];
  const recr = (item, target) => {
    const { id, children } = item;
    if (id === target) {
      memo.push(id);
      return true;
    }
    let flag = false;
    children.forEach((c) => {
      if (recr(c, target)) {
        flag = true;
      }
    });
    if (flag) {
      memo.push(id);
      return true;
    }
    return false;
  };
  data.forEach((item) => {
    recr(item, target);
  });
  return memo.reverse();
}

export function findCategoryNamesTree(data, target) {
  const memo = [];
  const recr = (item, target) => {
    const { id, name, children } = item;
    if (id === target) {
      memo.push(name);
      return true;
    }
    let flag = false;
    children.forEach((c) => {
      if (recr(c, target)) {
        flag = true;
      }
    });
    if (flag) {
      memo.push(name);
      return true;
    }
    return false;
  };
  data.forEach((item) => {
    recr(item, target);
  });
  return memo.reverse();
}

export function getCategoryById(data, target) {
  let memo = null;
  const recr = (item, target) => {
    const { id, children } = item;
    if (id === target) {
      memo = item;
      return true;
    }
    let flag = false;
    children.forEach((c) => {
      if (recr(c, target)) {
        flag = true;
      }
    });
    return flag;
  };
  data.forEach((item) => {
    recr(item, target);
  });
  return memo;
}

export function getCategoriesNames(data) {
  const memo = [];
  const recr = (item) => {
    const { id, name, children } = item;
    memo.push({ id, name });
    children.forEach((c) => {
      recr(c);
    });
  };
  data.forEach((item) => {
    recr(item);
  });
  return memo;
}

export function findUrlCategories(data, target) {
  const memo = [];
  const recr = (item, target) => {
    const { id, name, children } = item;
    if (id === target) {
      memo.push(name);
      return true;
    }
    let flag = false;
    children.forEach((c) => {
      if (recr(c, target)) {
        flag = true;
      }
    });
    if (flag) {
      memo.push(name);
      return true;
    }
    return false;
  };
  data.forEach((item) => {
    recr(item, target);
  });
  return memo.reverse();
}

export function prepareCategoriesForUrl(categories) {
  return categories
    .map((category) => category.toLowerCase().trim().split(" ").join("-"))
    .join("/");
}

export function nullToEmptyString(input) {
  return input !== null ? input : "";
}

export function nullAndUndefinedToEmptyString(input) {
  return input !== null && input !== undefined ? input : '';
}

export function handleMissingTranslation(key, replacements) {
  return <span style={{ color: "red" }}>{key}</span>;
}

export function getLanguageId(language, values) {
  let languageID = defaultLanguageId;
  values.forEach((item) => {
    if (item.code === language) {
      languageID = item.id;
    }
  });
  return languageID;
}

export function getLanguageName(language, values) {
  let languageName = defaultLanguage;
  values.forEach((item) => {
    if (item.id === language) {
      languageName = item.code;
    }
  });
  return languageName;
}

export function getCurrencyName(currencyId, values) {
  if (currencyId === null || currencyId === undefined || values === null || values === undefined || values.length === 0) {
    return 'EUR';
  } 
  const currency = values.find(({ id }) => id.toString() === currencyId.toString());
  if (currency === undefined) {
    return 'EUR';
  }
  return currency.name;
}

export function capitalize(src) {
  return src.charAt(0).toUpperCase() + src.slice(1);
}

export function getAcceptLanguageHeader() {
  return {
    "Accept-Language": getActiveLanguage(),
  };
}

export function resolveSearchText(text) {
  const textArr = !text ? [] : text.toString().trim().split(" ");
  return textArr.length < 4 ? textArr.join(" ") : `"${textArr.join(" ")}"`;
}

export const setThousandSeparator = (num, separator) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const createSingleAdUrl = (categories, category, id, title) => {
  const urlCategories = findUrlCategories(categories, category);
  const preparedCategories = prepareCategoriesForUrl(urlCategories);
  const encodedTitle = encodeURI(title.replaceAll(' ', '-'));
  return `/ad/${id}/${preparedCategories}/${encodedTitle}`;
}
