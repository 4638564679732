import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { ChevronRight } from "react-feather";
import {
  getCategoryAttributes,
  resetCategoryAttributes,
} from "../../../../../redux/actions/data/index";
import {
  setCurrentCategory,
} from "../../../../../redux/actions/selectedData/index";
import { getCategoryMeasurementUnitOptions } from "../../../../../redux/actions/commonFilters/index";
class SideMenuGroup extends React.Component {
  constructor(props) {
    super(props);
    this.flag = true;
    this.parentArray = [];
    this.childObj = {};
  }
  state = {
    isOpen: false,
    activeItem: this.props.activePath,
  };

  handleActiveItem = (item) => {
    const {
      resetCategoryAttributes,
      setCurrentCategory,
      getCategoryMeasurementUnitOptions,
    } = this.props;
    resetCategoryAttributes();
    setCurrentCategory({ id: item.id, name: item.title });
    getCategoryAttributes(item.id);
    getCategoryMeasurementUnitOptions(item.id);
    this.setState({
      activeItem: item.id,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.childObj.navLink && this.childObj.collapsed) {
        this.props.collapsedMenuPaths(this.childObj.navLink);
      }
      if (
        this.props.activePath === this.childObj.navLink &&
        !this.props.parentArr.includes(this.parentArray[0])
      ) {
        this.props.parentArr.splice(0, this.props.parentArr.length);
        this.props.parentArr.push(this.parentArray);
      } else if (this.props.parentArr.includes(this.parentArray)) {
        this.props.parentArr.splice(0, this.props.parentArr.length);
      }
    }
  }

  renderChild(item, activeGroup, handleGroupClick, handleActiveItem, parent) {
    return (
      <ul className="menu-content">
        {item.children
          ? item.children.map((child) => {
              // const CustomAnchorTag = Link;
              if (!this.parentArray.includes(item.id) && this.flag) {
                this.parentArray.push(item.id);
              }

              if (child.navlink && child.collapsed) {
                this.props.collapsedMenuPaths(child.navLink);
              }

              if (this.props.activeItemState === child.navLink) {
                this.childObj = child;
                this.props.parentArr.push(this.parentArray);
                this.flag = false;
              }

              return (
                <li
                  key={child.id}
                  className={classnames({
                    hover: this.props.hoverIndex === child.id,
                    "has-sub": child.type === "collapse",
                    open:
                      child.type === "collapse" &&
                      activeGroup.includes(child.id),
                    "sidebar-group-active": this.props.currentActiveGroup.includes(
                      child.id
                    ),
                    active: this.props.activeItemState === child.id,
                    disabled: child.disabled,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGroupClick(child, item.id);
                    this.props.setActiveItem(null);
                    if (child.isLast) {
                      handleActiveItem(child);
                    }
                    if (
                      this.props.deviceWidth <= 1200 &&
                      child.type === "item"
                    ) {
                      // this.props.toggleMenu();
                    }
                  }}
                  style={{ marginLeft: item.level * 7, fontSize: "13px" }}
                >
                  <div
                    className={classnames({
                      "d-flex justify-content-between":
                        child.type === "collapse",
                    })}
                    to={
                      child.navLink && child.type === "item"
                        ? child.navLink
                        : ""
                    }
                    // href={child.type === "external-link" ? child.navLink : ""}
                    onMouseEnter={() => {
                      this.props.handleSidebarMouseEnter(child.id);
                    }}
                    onMouseLeave={() => {
                      this.props.handleSidebarMouseEnter(child.id);
                    }}
                    key={child.id}
                    onClick={(e) => {
                      return child.type === "collapse"
                        ? e.preventDefault()
                        : "";
                    }}
                    target={child.newTab ? "_blank" : undefined}
                  >
                    <div className="menu-text">
                      {child.icon}
                      <span className="menu-item menu-title">
                        {child.title}
                      </span>
                    </div>
                    {child.type === "collapse" ? (
                      <ChevronRight className="menu-toggle-icon" size={13} />
                    ) : (
                      ""
                    )}
                  </div>

                  {child.children
                    ? this.renderChild(
                        child,
                        activeGroup,
                        handleGroupClick,
                        handleActiveItem,
                        item.id
                      )
                    : ""}
                </li>
              );
            })
          : null}
      </ul>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderChild(
          this.props.group,
          this.props.activeGroup,
          this.props.handleGroupClick,
          this.props.handleActiveItem,
          null
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.data.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCategory: (category) => dispatch(setCurrentCategory(category)),
    resetCategoryAttributes: () => dispatch(resetCategoryAttributes()),
    getCategoryAttributes: (id) => dispatch(getCategoryAttributes(id)),
    getCategoryMeasurementUnitOptions: (categoryId) =>
      dispatch(getCategoryMeasurementUnitOptions(categoryId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuGroup);
