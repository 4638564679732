export const TRANSLATIONS_LOAD_COMPLETED = 'TRANSLATIONS_LOAD_COMPLETED';
export const TRANSLATIONS_LOAD_STARTED = 'TRANSLATIONS_LOAD_STARTED';

export const setTranslationsLoadCompleted = () => dispatch => {
    dispatch({
        type: TRANSLATIONS_LOAD_COMPLETED,
    });
};

export const setTranslationsLoadStarted = () => dispatch => {
    dispatch({
        type: TRANSLATIONS_LOAD_STARTED,
    })
};
