export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const SET_SORT_BY = "SET_SORT_BY";
export const BREADCRUMBS_CLICKED = "BREADCRUMBS_CLICKED";
export const RESET_SELECTED_DATA = "RESET_SELECTED_DATA";
export const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE";
export const SET_ACTIVE_CURRENCY = "SET_ACTIVE_CURRENCY";
export const SET_ACTIVE_BREADCRUMBS = "SET_ACTIVE_BREADCRUMBS";
export const SET_AUTOSUGGEST_INPUT = "SET_AUTOSUGGEST_INPUT";

export const setAutosuggestInput = (text) => (dispatch) => dispatch({
  type: SET_AUTOSUGGEST_INPUT,
  data: text,
});

export const setCurrentCategory = (category) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CATEGORY,
      data: category,
    });
  };
};

export const setBreadcrumbs = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BREADCRUMBS,
      data: data,
    });
  };
};

export const setActiveBreadcrumbs = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_BREADCRUMBS,
      data: data,
    });
  };
};

export const setSortBy = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SORT_BY,
      data: data,
    });
  };
};

export const setBreadcrumbsClick = (value) => {
  return (dispatch) => {
    dispatch({
      type: BREADCRUMBS_CLICKED,
      data: value,
    });
  };
};

export const resetSelectedData = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SELECTED_DATA,
    })
  }
}

export const setActiveLanguage = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_LANGUAGE,
      data: id,
    })
  }
}

export const setActiveCurrency = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_CURRENCY,
      data: id,
    })
  }
}
