export const baseUrl = "https://api.vevergo.com:4443";
export const apiKey = "03b7f69e-5cd2-4cd2-94b8-e59f95dc7b13";
export const defaultLanguageId = 1; // English
export const defaultLanguage = "en"; // English
export const defaultCurrencyId = 44; // EUR

export const localStorageToken = "vvg-token";
export const localStorageUser = "vvg-user";
export const localStorageTempCurrency = "vvg-temp-currency";
export const localStorageTempLanguage = "vvg-temp-language";
export const localStorageTranslations = "vvg-translations";
export const localStorageTranslationsLastModified =
  "vvg-translations-last-modified";
export const localStorageOrganization = "vvg-organization";

export const filtersForm = "filtersForm";

export const imageBaseUrl =
  "https://api.vevergo.com:4443/item_adjusted_images/";

export const galleryImageBaseUrl = "https://api.vevergo.com:4443/item_images/";

export const dataTypes = [
  {
    id: 1,
    name: "Character",
  },
  {
    id: 2,
    name: "Integer",
  },
  {
    id: 3,
    name: "Decimal",
  },
  {
    id: 4,
    name: "Date",
  },
  {
    id: 5,
    name: "Date and time",
  },
  {
    id: 6,
    name: "Boolean",
  },
  {
    id: 7,
    name: "List - single value allowed",
  },
  {
    id: 8,
    name: "List - multiple values allowed",
  },
];

export const primaryColor = "#ff9f43";

export const filterMenuColorPrimary = "#FBFBFB";
export const filterMenuColorSecondary = "#E4E5E7 ";

export const orderOptions = [
  { value: 1, label: "lblDateModifiedNewer", orderType: 0 },
  { value: 0, label: "lblDateModifiedOlder", orderType: 1 },
  { value: 3, label: "lblPriceLower", orderType: 0, orderItem: 1 },
  { value: 2, label: "lblPriceHighest", orderType: 1, orderItem: 1 },
  { value: 5, label: "lblRelevanceLeast", orderType: 0, orderItem: 2 },
  { value: 4, label: "lblRelevanceMost", orderType: 1, orderItem: 2 },
  { value: 7, label: "lblTitleAsc", orderType: 0, orderItem: 3 },
  { value: 6, label: "lblTitleDesc", orderType: 1, orderItem: 3 },
  { value: 9, label: "lblViewsLowest", orderType: 0, orderItem: 4 },
  {
    value: 8,
    label: "lblViewsHighest",
    orderType: 1,
    orderItem: 4,
  },
];

export const COMMON_FILTERS = {
  LISTING_TYPE: "listing_type",
  INCLUDE_ITEMS_WITHOUT_PRICE: "include_items_without_price",
  PRICE_FROM: "price_from",
  PRICE_TO: "price_to",
  CURRENCY: "currency",
  COUNTRY: "country",
  PLACE: "place",
  OWNER_TYPE: "owner_type",
  INCLUDE_ITEMS_WITH_MEDIA: "include_items_with_media",
  MEASUREMENT_UNIT: "measurement_unit",
};

export const FILTER_TYPES = {
  NUMERIC: "Numeric",
  SELECT: "Select",
  COMBINED_SELECT: "Combined_select",
  TOGGLE: "Toggle",
  TEXT: "Text",
  DATE: "Date",
  MEASUREMENT_UNIT: "Measurement_unit",
};

export const FORM_TYPES = {
  ADD: "Add",
  EDIT: "Edit",
};

export const GENDER_OPTIONS = {
  NO_GENDER: "lblNoGender",
  MALE: "lblMale",
  FEMALE: "lblFemale",
};

export const ERROR_CODES = {
  1001: "lblUserAlreadyRegistered",
  1002: "lblValidationFailed",
  1003: "lblUserDoesntExist",
  1004: "lblUserExistsInvalidStatus",
  1005: "lblUserNotVerified",
  1006: "lblConfirmationUrlError",
  1007: "lblUserAlreadyExists",
  1008: "lblConfirmationIdInvalid",
  1009: "lblSocialAccountAlreadyRegistered",
  1010: "lblItemDoesntExist",
  1011: "lblLastSocialAccount",
  1012: "lblEmailAlreadyUsed",
  1013: "lblNewPasswordsDontMatch",
  1014: "lblOldPasswordInvalid",
  1015: "lblEmailDoesntExist",
  1016: "lblMaximumNumberOfItemsError",
  1017: "lblEmptyRequestBody",
  1018: "lblInvalidContentType",
  1019: "lblFileTypeNotSupported",
  1020: "lblFileSizeTooLarge",
  1021: "lblImageSizeError",
  1022: "lblInvalidSpecifiedParameters",
  1023: "lblCategoryNotAcceptingItems",
  1024: "lblMessageSendingError",
  1025: "lblExpiryDateTooHigh",
};
