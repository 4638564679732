import React, { Component } from "react";
import classnames from "classnames";
import _ from "lodash";
import { ContextLayout } from "../../../../utility/context/Layout";
import { connect } from "react-redux";
import { reset } from "redux-form";
import SidebarHeader from "./SidebarHeader";
import Hammer from "react-hammerjs";
import SideMenuContent from "./sidemenu/SideMenuContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import VvgFilterContainer from "../../../VvgFilterContainer";
import VvgFilterButton from "../../../../components/filter/filterButton/VvgFilterButton";
import { getCategories } from "../../../../redux/actions/data/index";
import { setMinMaxError } from "../../../../redux/actions/filter/index";
import {
  getCategoryAttributes,
  resetCategoryAttributes,
} from "../../../../redux/actions/data/index";
import {
  setCurrentCategory,
  setBreadcrumbs,
} from "../../../../redux/actions/selectedData/index";
import {
  setCategoryNameOptions,
  getCategoryMeasurementUnitOptions,
} from "../../../../redux/actions/commonFilters/index";
import { filtersForm } from "../../../../utility/Constants";

let categoryNames = [];

class Sidebar extends Component {
  /* static getDerivedStateFromProps(props, state) {
    if (props.activePath !== state.activeItem) {
      return {
        activeItem: props.activePath,
      };
    }
    // Return null if the state hasn't changed
    return null;
  } */
  state = {
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: this.props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar,
  };

  mounted = false;

  updateWidth = () => {
    if (this.mounted) {
      this.setState((prevState) => ({
        width: window.innerWidth,
      }));
      this.checkDevice();
    }
  };

  checkQueryParamsFromUrl = () => {};

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (window !== "undefined") {
        window.addEventListener("resize", this.updateWidth, false);
      }
      this.checkDevice();
    }
    this.props.getCategories();
  }

  componentDidUpdate(prevProps) {
    // console.log("UPDATE sidebar", this.props)
    if (prevProps.categories !== this.props.categories) {
      categoryNames = [];
      categoryNames = this.getCategoryNames(this.props.categories);
      this.props.setCategoryNameOptions(categoryNames);
      if (this.state.activeItem !== "/") {
        this.props.getCategoryMeasurementUnitOptions(this.state.activeItem);
      }
    }
    if (
      prevProps.currentCategory !== this.props.currentCategory &&
      this.props.currentCategory === null
    ) {
      this.setState({
        activeItem: "/",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCategoryNames = (categories) => {
    categories.forEach((item) => {
      // eslint-disable-line array-callback-return
      categoryNames.push({ id: item.id, name: item.name });
      if (!_.isEmpty(item.children)) {
        this.getCategoryNames(item.children);
      }
    });
    return categoryNames;
  };

  checkDevice = () => {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    var mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if ("ontouchstart" in window || window.DocumentTouch) {
      this.setState({
        ScrollbarTag: "div",
      });
    } else {
      this.setState({
        ScrollbarTag: PerfectScrollbar,
      });
    }
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(
      ""
    );
    return mq(query);
  };

  changeActiveIndex = (id) => {
    if (id !== this.state.activeIndex) {
      this.setState({
        activeIndex: id,
      });
    } else {
      this.setState({
        activeIndex: null,
      });
    }
  };

  handleSidebarMouseEnter = (id) => {
    if (id !== this.state.hoveredMenuItem) {
      this.setState({
        hoveredMenuItem: id,
      });
    } else {
      this.setState({
        hoveredMenuItem: null,
      });
    }
  };

  setActiveItem = (item) => {
    this.setState({
      activeItem: item,
    });
  };

  handleActiveItem = (item) => {
    const {
      resetCategoryAttributes,
      setCurrentCategory,
      getCategoryAttributes,
      getCategoryMeasurementUnitOptions,
      resetFilterForm,
      setMinMaxError,
      setBreadcrumbs,
      breadcrumbs,
    } = this.props;
    if (item === null) {
      this.setState({
        activeItem: item,
      });
    } else {
      resetFilterForm();
      setMinMaxError([]);
      resetCategoryAttributes();
      const { level } = item;
      const newBreadcrumbs = this.replaceLastBreadcrumb(breadcrumbs, item.id, level - 1);
      setCurrentCategory({ id: item.id, name: item.title });
      setBreadcrumbs(newBreadcrumbs);
      getCategoryAttributes(item.id);
      getCategoryMeasurementUnitOptions(item.id);
      this.setState({
        activeItem: item.id,
      });
    }
  };

  replaceLastBreadcrumb = (source, newItem, targetLevel) => {
    const target = [...source];
    target.length = targetLevel;
    target.push(newItem);
    return target;
  };

  render() {
    let {
      visibilityState,
      toggleSidebarMenu,
      sidebarHover,
      toggle,
      color,
      sidebarVisibility,
      activeTheme,
      collapsed,
      activePath,
      sidebarState,
      currentLang,
      permission,
      currentUser,
      collapsedMenuPaths,
      sidebarHidden,
      collapsedContent,
    } = this.props;

    let { menuShadow, activeIndex, hoveredMenuItem, activeItem } = this.state;
    // let scrollShadow = (container, dir) => {
    //   if (container && dir === "up" && container.scrollTop >= 100) {
    //     this.setState({ menuShadow: true });
    //   } else if (container && dir === "down" && container.scrollTop < 100) {
    //     this.setState({ menuShadow: false });
    //   } else {
    //     return;
    //   }
    // };
    return (
      <ContextLayout.Consumer>
        {(context) => {
          let dir = context.state.direction;
          return (
            <React.Fragment>
              <Hammer
                onSwipe={(e) => {
                  sidebarVisibility();
                }}
                direction={dir === "rtl" ? "DIRECTION_LEFT" : "DIRECTION_RIGHT"}
              >
                <div className="menu-swipe-area d-xl-none d-block vh-100"></div>
              </Hammer>

              <div
                id="vvg-mobile-sidebar"
                className={classnames(
                  `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                  {
                    collapsed: sidebarState === true,
                    "hide-sidebar":
                      this.state.width < 1200 && visibilityState === false,
                    "move-sidebar": this.state.width > 1200,
                  }
                )}
                onMouseEnter={() => sidebarHover(false)}
                onMouseLeave={() => sidebarHover(true)}
              >
                <SidebarHeader
                  toggleSidebarMenu={toggleSidebarMenu}
                  toggle={toggle}
                  sidebarBgColor={color}
                  sidebarVisibility={sidebarVisibility}
                  activeTheme={activeTheme}
                  collapsed={collapsed}
                  menuShadow={menuShadow}
                  activePath={activePath}
                  sidebarState={sidebarState}
                />
                <div
                  // className={classnames("main-menu-content", {
                  //   "overflow-hidden": ScrollbarTag !== "div",
                  //   "overflow-scroll": ScrollbarTag === "div",
                  // })}
                  className="main-menu-content"
                  // {...(ScrollbarTag !== "div" && {
                  //   options: { wheelPropagation: false },
                  //   onScrollDown: (container) =>
                  //     scrollShadow(container, "down"),
                  //   onScrollUp: (container) => scrollShadow(container, "up"),
                  //   onYReachStart: () =>
                  //     menuShadow === true &&
                  //     this.setState({ menuShadow: false }),
                  // })}
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Hammer
                    onSwipe={() => {
                      sidebarVisibility();
                    }}
                    direction={
                      dir === "rtl" ? "DIRECTION_RIGHT" : "DIRECTION_LEFT"
                    }
                  >
                    <ul className="navigation navigation-main">
                      <SideMenuContent
                        setActiveIndex={this.changeActiveIndex}
                        activeIndex={activeIndex}
                        hoverIndex={hoveredMenuItem}
                        handleSidebarMouseEnter={this.handleSidebarMouseEnter}
                        activeItemState={activeItem}
                        handleActiveItem={this.handleActiveItem}
                        activePath={activePath}
                        lang={currentLang}
                        permission={permission}
                        currentUser={currentUser}
                        collapsedMenuPaths={collapsedMenuPaths}
                        toggleMenu={sidebarVisibility}
                        deviceWidth={this.props.deviceWidth}
                        sidebarState={sidebarState}
                        sidebarHidden={sidebarHidden}
                        setActiveItem={this.setActiveItem}
                        collapsedContent={collapsedContent}
                      />
                      {!collapsed && <VvgFilterContainer />}
                    </ul>
                  </Hammer>
                </div>
                <div
                  style={{
                    paddingTop: "5px",
                    height: "50px",
                    position: "relative",
                  }}
                  onClick={() => {
                    sidebarHidden && sidebarVisibility();
                  }}
                >
                  <VvgFilterButton
                    collapsedContent={collapsedContent}
                    sidebarState={sidebarState}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </ContextLayout.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.login.userRole,
    categories: state.data.categories,
    currentCategory: state.selectedData.currentCategory,
    breadcrumbs: state.selectedData.breadcrumbs,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    getCategoryAttributes: (id) => dispatch(getCategoryAttributes(id)),
    setCurrentCategory: (category) => dispatch(setCurrentCategory(category)),
    resetCategoryAttributes: () => dispatch(resetCategoryAttributes()),
    setCategoryNameOptions: (categoryNames) =>
      dispatch(setCategoryNameOptions(categoryNames)),
    getCategoryMeasurementUnitOptions: (categoryId) =>
      dispatch(getCategoryMeasurementUnitOptions(categoryId)),
    resetFilterForm: () => dispatch(reset(filtersForm)),
    setMinMaxError: (errors) => dispatch(setMinMaxError(errors)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
