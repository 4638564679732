import axios from 'axios';

/* Create Axios instance */
const client = axios.create({});
const VEVERGO_HEADER = 'Vevergo_header';

/* Default headers */
function getDefaultHeader() {
    return {
        [VEVERGO_HEADER]: '',
    };
}

/* Get options for API requests */
function getApiOptions(options) {
    return {
        headers: options.headers !== null || getDefaultHeader(),
        params: options.params !== null || axios.defaults.params,
        data: options.data !== null || axios.defaults.data,
        timeout: Number(options.timeout) || axios.defaults.timeout,
        transformResponse: options.transformResponse !== null ? options.transformResponse : axios.defaults.transformResponse,
        ...options,
    };
}

/* Create API request */
const request = function (customerUid, options) {
    const onSuccess = function (response) {
        return response.items;
    };

    const onError = function (error) {
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);

        } else {
            // Something else happened while setting up the request triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return client(getApiOptions(customerUid, options))
        .then(onSuccess)
        .catch(onError);
};

function get(url, options = null) {
    return request(
        {
            url: url,
            method: "GET",
            ...options
        }
    );
}

function post(url, data = null, options = null) {
    return request(
        {
            url: url,
            method: "POST",
            data: data,
            ...options
        }
    );
}

function deleteItem(url, data = null, options = null) {
    return request(
        {
            url: url,
            method: "DELETE",
            data: data,
            ...options
        }
    );
}

const ApiManager = {
    get, post, deleteItem,
};

export default ApiManager;
