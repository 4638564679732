/* eslint-disable no-prototype-builtins */
import axios from "axios";
import { I18n } from "react-redux-i18n";
import { showWarning } from "../../../utility/Toast";
import {
  routeOrganizationsList,
  routeDeleteOrganization,
  routeOrganization,
  routeTakeOverOrganizationProfile,
  routeOrganizationUsers,
  routeOrganizationMemberships,
  routeDeleteOrganizationUser,
  apiKey,
} from "../../../utility/RouteRegistry";
import {
  ERROR_CODES,
  localStorageOrganization,
} from "../../../utility/Constants";
import { getTokenFromLocalStorage } from "../../../utility/Helpers";

export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_ORGANIZATION_PROFILE = "SET_ORGANIZATION_PROFILE";
export const SET_ORGANIZATION_USERS = "SET_ORGANIZATION_USERS";
export const SET_ORGANIZATION_MEMBERSHIPS = "SET_ORGANIZATION_MEMBERSHIPS";
export const RESET_ORGANIZATION_PROFILE = "RESET_ORGANIZATION_PROFILE";

export const getOrganizationsList = () => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    axios
      .get(routeOrganizationsList, { params: queryParams, headers: headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          } else {
            dispatch({
              type: SET_ORGANIZATIONS_LIST,
              data: response.data.items,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const addOrganization = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeOrganization, params, {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const deleteOrganization = (id) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(routeDeleteOrganization(id), {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const setOrganizationData = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeOrganization, params, {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              showWarning(I18n.t("lblSuccessProfileUpdate"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const getOrganizationProfile = (id) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(routeTakeOverOrganizationProfile(id), {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              dispatch({
                type: SET_ORGANIZATION_PROFILE,
                data: responseData.items[0],
              });
              localStorage.setItem(
                localStorageOrganization,
                responseData.items[0].id
              );
              // save org data to redux and token?
              // add isOrganizationImpersonated flag?
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const getOrganizationUsers = (orgId) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
    iss_organization: orgId,
  };
  return (dispatch) => {
    axios
      .get(routeOrganizationUsers, { params: queryParams, headers: headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          } else {
            dispatch({
              type: SET_ORGANIZATION_USERS,
              data: response.data.items,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const addOrganizationUser = (orgId, params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
    iss_organization: orgId,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(routeOrganizationUsers, params, {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const deleteOrganizationUser = (orgId, id) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
    iss_organization: orgId,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(routeDeleteOrganizationUser(id), {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const setResetOrganizationUser = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ORGANIZATION_PROFILE,
    });
  };
};

export const getOrganizationMemberships = () => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    axios
      .get(routeOrganizationMemberships, {
        params: queryParams,
        headers: headers,
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          } else {
            dispatch({
              type: SET_ORGANIZATION_MEMBERSHIPS,
              data: response.data.items,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };
};
