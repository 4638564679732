// Filter
export const lblItemType = "Item Type";
export const lblPriceCurrencyType = "Price / Type";
export const lblCurrency = "Currency";
export const lblIncludeItemsWithoutPrice = "Include items without price";
export const lblItemCountry = "Item Country";
export const lblItemCity = "Item City";
export const lblPublishedBy = "Published by";
export const lblIncludeItemsWithoutMedia = "Include items without media";
export const lblMeasurementUnit = "Measurement unit";
export const lblIndividual = "Individual";
export const lblOrganization = "Organization";
export const lblYes = "Yes";
export const lblNo = "No";
export const lblFrom = "from";
export const lblTo = "to";
export const lblAdditionalFilters = "Additional Filters";
export const lblAddFilter = "Add filter";
export const lblSelect = "Select";
export const lblFilter = "Filter";
export const lblDateModifiedOlder = "Date modified: older first";
export const lblDateModifiedNewer = "Date modified: newer first";
export const lblPriceHighest = "Price: highest first";
export const lblPriceLower = "Price: lowest first";
export const lblRelevanceMost = "Relevance: most relevant";
export const lblRelevanceLeast = "Relevance: least relevant";
export const lblTitleDesc = "Title: descending";
export const lblTitleAsc = "Title: ascending";
export const lblViewsHighest = "Views: highest number first";
export const lblViewsLowest = "Views: lowest number first";

// Login
export const lblEmail = "Email";
export const lblPassword = "Password";
export const lblOldPassword = "Old Password";
export const lblConfirmPassword = "Confirm Password";
export const lblForgotPassword = "Forgot password";
export const lblResetPassword = "Reset password";
export const lblRegister = "Register";
export const lblLogin = "Login";
export const lblLoginDescription = "Welcome back, please login to your account.";
export const lblReactivateAccount = "Reactivate account";
export const lblReactivateQuestion = "Reactivate account?";
export const lblCreateAccount = "Create account";
export const lblCreateAccountDescription = "Fill the below form to create a new account.";
export const lblUsername = "Username";
export const lblFirstName = "First name";
export const lblLastName = "Last name";
export const lblSend = "Send";
export const lblAccountReactivated = "Account reactivated successfully.";
export const lblPasswordResetMessage = "Your password has been successfully reset. Please check your mail.";
export const lblPasswordResetConfirmationMessage = "Your password has been successfully reset.";
export const lblNewPassword = "New password";
export const lblConfirmNewPassword = "Confirm new password";
export const lblAccountDeactivated = "Account deactivated successfully.";

// Profile
export const lblMemberSince = "Member since";
export const lblMyPublishedItems = "My published items";
export const lblOrganizations = "Organizations";
export const lblAddOrganization = "Add organization";
export const lblTakeOverProfile = "Take over the organization profile";
export const lblDelete = "Delete";

export const lblUserProfile = "User profile";
export const lblAccountData = "Account data";
export const lblPersonalData = "Personal data";
export const lblSecurityPrivacy = "Security & Privacy";
export const lblUserId = "User ID";
export const lblMiddleName = "Middle name";
export const lblCountry = "Country";
export const lblCountryAdditionalInfo = "Determines also the number, date and time format";
export const lblTimeZone = "Time zone";
export const lblLanguage = "Language";
export const lblAddress = "Address";
export const lblPostcode = "Postcode";
export const lblCity = "City";
export const lblMobileTelephoneNumber = "Mobile telephone number";
export const lblLandlineTelephoneNumber = "Landline telephone number";
export const lblFaxNumber = "Fax number";
export const lblWebsite = "Website";
export const lblGender = "Gender";
export const lblUnitSystem = "Unit system";
export const lblMetricSystem = "Metric system";
export const lblImperialSystem = "Imperial system";
export const lblShowAddressOrProfile = "Show the user's address on the public profile of the user.";
export const lblMobilePhoneVisible = "Mobile / fixed phone number is visible.";
export const lblEmailVisible = "Email is visible.";
export const lblWebsiteVisible = "Website is visible.";
export const lblNotificationsExpiredItems = "Allow notifications about expired items on my email.";
export const lblNotificationsNewMessages = "Allow notifications about new messages on my email.";
export const lblChangeEmail = "Change email";
export const lblChangePassword = "Change password";
export const lblDeactivateAccount = "Deactivate account";
export const lblSave = "Save";
export const lblMale = "Male";
export const lblFemale = "Female";
export const lblNoGender = "No gender";
export const lblLinkedAccounts = "Linked accounts";
export const lblName = "Name";
export const lblOwner = "Owner";
export const lblOptions = "Options";
export const lblUserType = "User type";
export const lblOrganizationType = "Organization type";
export const lblUserStatus = "User status";
export const lblShowNamePublic = "Allow my first and last name to be visible.";

export const lblOrganizationCreated = "Organization created";
export const lblSignOffFromProfile = "Sign off the organization profile";
export const lblOrganizationProfile = "Organization profile";
export const lblCompanyData = "Company data";
export const lblCompanyName = "Company name";
export const lblLongCompanyName = "Long company name";
export const lblVatPayer = "VAT payer";
export const lblVatId = "VAT ID";
export const lblMember = "Member";
export const lblMembers = "Members";
export const lblFunction = "Function";
export const lblAddLinkedAccount = "Add linked account";
export const lblSearch = "Search";
export const lblSearchMemberDescription = "Name, last name or user-ID";
export const lblPublished = "Published";
export const lblUnpublished = "Unpublished";
export const lblPublishedItemsTitle = "Published items";
export const lblUnpublishedItemsTitle = "Unpublished items";
export const lblUnpublishSelected = "Unpublish selected";
export const lblPublishSelected = "Publish selected";
export const lblDeleteSelected = "Delete selected";
export const lblItemTitle = "Item title";
export const lblExpiringDate = "Expiring date";
export const lblAdmin = "Admin";
export const lblSignOff = "Sign off";
export const lblShowCompanyAddress = "•	Allow company address to be visible.";
export const lblShowOrganizationNamePublic = "Show organization name";

// Error codes
export const lblUserAlreadyRegistered = "User is already registered.";
export const lblValidationFailed = "Validation failed.";
export const lblUserDoesntExist = "User does not exist.";
export const lblUserExistsInvalidStatus = "User exists, but does not have a valid status.";
export const lblUserNotVerified = "User is already present in the database, but has not yet verified his account.";
export const lblConfirmationUrlError = "Confirmation URL was not provided by API user or is invalid (for example, not an absolute URL).";
export const lblUserAlreadyExists = "Entity with the specified nickname already exists.";
export const lblConfirmationIdInvalid = "Confirmation_id parameter specified is invalid.";
export const lblSocialAccountAlreadyRegistered = "This social account is already registered.";
export const lblItemDoesntExist = "Item does not exist.";
export const lblLastSocialAccount = "This social account cannot be disconnected from account because it is the last user social account and user does not have password defined.";
export const lblEmailAlreadyUsed = "Specified email address is already in use.";
export const lblNewPasswordsDontMatch = "New passwords do not match.";
export const lblOldPasswordInvalid = "Old password is invalid.";
export const lblEmailDoesntExist = "User with the specified email address does not exist.";
export const lblMaximumNumberOfItemsError = "Maximum number of allowed items has been exceeded.";
export const lblEmptyRequestBody = "Request body size is empty.";
export const lblInvalidContentType = "Specified content type is invalid.";
export const lblFileTypeNotSupported = "File type is not supported.";
export const lblFileSizeTooLarge = "File size is too large.";
export const lblImageSizeError = "Image width and/or height is out of range (too small or too large).";
export const lblInvalidSpecifiedParameters = "Specified parameters are invalid.";
export const lblCategoryNotAcceptingItems = "Specified category does not accept listing items (it is not the last category in the hierarchy).";
export const lblMessageSendingError = "Message cannot be sent to the user.";
export const lblExpiryDateTooHigh = "Expiry date for listing item is too high.";

// Status messages
export const lblSuccessProfileUpdate = "User profile was successfully updated.";

//Other
export const lblUnderstand = "I understand.";
export const lblCookieMessage = "This website uses cookies to enhance the user experience.";
export const lblRegistrationSuccessful = "Registration successful";
export const lblRegistrationSuccessfulMessage = "Check your email and locate the confirmation email. Follow the steps in the email to confirm your email address.";
export const lblConfirmationPage = "Confirmation page";
export const lblConfirmationMessage = "Thanks for creating a Vevergo account! You can continue with your searching :)";
export const lblDropImageHere = "Drop image here";
export const lblCancelEditing = "Cancel editing";
export const lblEditPhoto = "Edit photo";
export const lblDeactivateAccountMessage = "Are you sure you want to deactivate your account?";
export const lblChangeEmailMessage = "Are you sure you want to change your email to";
export const lblChangePasswordMessage = "Are you sure you want to change your password?";
export const lblChange = "Change";
export const lblEnterNewEmail = "Enter new email";
export const lblEmailChangeRequestMessage = "Please check your email and follow further instructions.";
export const lblBackToPersonal = "Back to personal profile";
export const lblDeleteOrganization = "Are you sure you want to delete organization?";
export const lblShowAllCategories = "Show all categories";
export const lblTypePriceLocation = "Type, price, location";
export const lblResultsFound = "Results found";
export const lblPriceByAgreement = "Price by agreement";

// Topbar
export const lblAddAd = "Add ad";

// User menu
export const lblMyItems = "My items";
export const lblFavoriteItems = "Favorite items";
export const lblLogout = "Logout";

//Detail ad
export const lblRemoveFromFavorites = "Remove from favorites";
export const lblAddToFavorites = "Add to favorites"
export const lblDescription = "Description";
export const lblDetails = "Details";
export const lblPostedBy = "Posted by";
export const lblValidTo = "Valid to";
export const lblCreated = "Created";
export const lblChanged = "Changed";
export const lblViews = "Views";
export const lblReportItem = "Report item";
export const lblFullScreen = "Fullscreen";
export const lblFullScreenClose = "Close";
export const lblContactOwner = "Contact owner";
export const lblMessage = "Message...";

//My items
export const lblNone = "None";
export const lblExpiryDate = "Expiry date";
export const lblNoData = "No data to display";
export const lblWarning = "Warning";
export const lblPublishMessage = "Are you sure you want to publish item(s)";
export const lblUnpublishMessage = "Are you sure you want to unpublish item(s)";
export const lblDeleteMessage = "Are you sure you want to delete item(s)";
export const lblRemoveFavoritesMessage = "Are you sure you want to remove item(s) from favorites";
export const lblItemPrice = "Item price";

// new
export const lblSelectAll = "Select all";
export const lblNoOptions = "No options";