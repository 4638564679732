export const SET_SIDEBAR_COMMON_FILTER = "SET_SIDEBAR_COMMON_FILTER";
export const SET_SIDEBAR_ADDITIONAL_FILTER = "SET_SIDEBAR_ADDITIONAL_FILTER";
export const SET_APPLIED_COMMON_FILTER = "SET_APPLIED_COMMON_FILTER";
export const SET_APPLIED_ADDITIONAL_FILTER = "SET_APPLIED_ADDITIONAL_FILTER";
export const SET_INITIAL_FILTER = "SET_INITIAL_FILTER";
export const SET_MIN_MAX_ERROR = "SET_MIN_MAX_ERROR";
export const SET_ACTIVE_SIDEBAR_ITEM = "SET_ACTIVE_SIDEBAR_ITEM";
export const RESET_ACTIVE_SIDEBAR_ITEM = "RESET_ACTIVE_SIDEBAR_ITEM";
export const RESET_ACTIVE_FILTERS = "RESET_ACTIVE_FILTERS";

export const setSidebarCommonFilter = (filterValue) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_COMMON_FILTER,
      data: filterValue,
    });
  };
};

export const setSidebarAdditionalFilter = (filterValue) => {
  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_ADDITIONAL_FILTER,
      data: filterValue,
    });
  };
};

export const setAppliedCommonFilter = (filterValue) => {
  return (dispatch) => {
    dispatch({
      type: SET_APPLIED_COMMON_FILTER,
      data: filterValue,
    });
  };
};

export const setAppliedAdditionalFilter = (filterValue) => {
  return (dispatch) => {
    dispatch({
      type: SET_APPLIED_ADDITIONAL_FILTER,
      data: filterValue,
    });
  };
};

export const setInitialFilter = (filterValue) => {
  return (dispatch) => {
    dispatch({
      type: SET_INITIAL_FILTER,
      data: filterValue,
    });
  };
};

export const setMinMaxError = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_MIN_MAX_ERROR,
      data: value,
    });
  };
};

export const setActiveSidebarItem = (id) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_SIDEBAR_ITEM,
      data: id,
    });
  };
};

export const resetActiveSidebarItem = (flag) => {
  return (dispatch) => {
    dispatch({
      type: RESET_ACTIVE_SIDEBAR_ITEM,
      data: flag,
    });
  };
};

export const resetActiveFilters = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ACTIVE_FILTERS,
    });
  };
};