import React from "react";
import { connect } from "react-redux";
import classnames from "classnames"
import {
  setSidebarCommonFilter,
  setSidebarAdditionalFilter,
} from "../../redux/actions/filter/index";
import { Field, getFormSyncErrors } from "redux-form";
import { FILTER_TYPES, filtersForm } from "../../utility/Constants";
import VvgDropdown from '../VvgDropdown/index';

class VvgSelectNew extends React.Component {
  handleChange(selectedItem) {
    const {
      commonFilters,
      additionalFilters,
      data,
      setSidebarCommonFilter,
      setSidebarAdditionalFilter,
      isMulti,
      isCommon,
      filterName,
      id,
    } = this.props;
    let filters = Object.assign(
      [],
      isCommon ? commonFilters : additionalFilters
    );
    filters = filters.filter((item) => item.id !== (id || data.id));
    let value = "";
    let name = "";
    if (selectedItem === null) {
      value = null;
      name = null;
    } else {
      if (isMulti) {
        selectedItem.map((element) => { // eslint-disable-line array-callback-return
          value += element.id + "|";
          name += element.name + "|";
        });
      } else {
        value += selectedItem.id + "|";
        name += selectedItem.name + "|";
      }
    }

    if (value && value !== "") {
      filters.push({
        id: id !== undefined ? id : data.id,
        filterName: filterName !== undefined ? filterName : data.name,
        value: String(value).substring(0, value.length - 1),
        name: String(name).substring(0, name.length - 1),
        type: FILTER_TYPES.SELECT,
      });
    }
    this.setState({
      inputValue: selectedItem,
    });

    isCommon
      ? setSidebarCommonFilter(filters)
      : setSidebarAdditionalFilter(filters);
  }
  render() {
    const { data, name, id, className, pureOptions } = this.props;
    const attrValues = Object.assign({}, data.attribute_values)
    attrValues.filterName = data.name;
    return (
      <div>
        <div className="vvg-sidebar-filter-type-item-title">{data !== undefined ? data.name : ""}</div>
        <Field
          name={id !== undefined ? id : ((name !== undefined ? name : (data.name !== undefined ? data.name : "selectField")))}
          component={this.renderSelect}
          options={pureOptions ? data : data.attribute_values}
          className={classnames("basic-multi-select React", className)}
          onChange={(value) => this.handleChange(value)}
          // defaultValue={[2]}
          // validate={[testValidation]}
        />
      </div>
    );
  }
  renderSelect = ({ input: { value, onChange }, options, className }) => {
    const selectedIds = (value || []).map(item => item.id);
    return (
        <VvgDropdown.MultiSearch
            className={className}
            selectedIds={selectedIds}
            options={options}
            onChange={(selectedIds) => {
                onChange(options.filter(({ id }) => selectedIds.includes(id)));
            }}
        />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonFilters: state.filter.sidebarFilter.commonFilters,
    additionalFilters: state.filter.sidebarFilter.additionalFilters,
    formErrors: getFormSyncErrors(filtersForm)(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgSelectNew);
