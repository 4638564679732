import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import data from "./data/Index"
import filter from "./filter/Index"
import selectedData from "./selectedData/Index"
import commonFilters from "./commonFilters/Index"
import user from "./user/Index"
import organization from "./organization/index"
import chat from './chat/index';
import translationsLoader from './translationsLoader/index';
import { reducer as formReducer } from 'redux-form'
import { i18nReducer } from 'react-redux-i18n';
import { mainPageViewReducer } from './mainPageView'

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  data: data,
  filter: filter,
  selectedData: selectedData,
  commonFilters: commonFilters,
  user: user,
  organization: organization,
  form: formReducer,
  i18n: i18nReducer,
  chat: chat,
  translationsLoader,
  mainPageView: mainPageViewReducer,
})

export default rootReducer
