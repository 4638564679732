import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../history';
import _ from 'lodash';
import './VvgTopbar.css';
import VvgTopbarLogo from './elements/VvgTopbarLogo';
import VvgCategoriesAutosuggest from './elements/VvgCategoriesAutosuggest/VvgCategoriesAutosuggest';
import {
    searchItemListing,
    getAutocompleteData,
    resetCategoryAttributes,
    getCategoryAttributes,
    getFavorites,
    getCategories,
    resetSearchResult,
    resetUserItems,
    resetFavorites,
} from '../../../redux/actions/data/index';
import {
    changeSingleItem,
    fetchUserCultureData,
    getUserData,
    resetUserCultureData,
    setResetUser,
} from '../../../redux/actions/user/index';
import {
    setAppliedCommonFilter,
    setAppliedAdditionalFilter,
    resetActiveFilters,
} from '../../../redux/actions/filter/index';
import VvgMenuDropdownSelector from './elements/VvgMenuDropdownSelector';
import VvgMenuOrganizationsSelector from './elements/VvgMenuOrganizationsSelector';
import {
    setCurrentCategory,
    setBreadcrumbs,
    setActiveBreadcrumbs,
    resetSelectedData,
    setActiveLanguage,
    setActiveCurrency,
    setAutosuggestInput,
} from '../../../redux/actions/selectedData';
import VvgUserMenu from './elements/VvgUserMenu';
import VvgAddPost from './elements/VvgAddPost';
import VvgMenuSeparator from './elements/VvgMenuSeparator';
import { reset } from 'redux-form';
import {
    filtersForm,
    localStorageToken,
    localStorageUser,
    defaultLanguageId,
    defaultCurrencyId,
    localStorageTempLanguage,
    localStorageTempCurrency,
    localStorageOrganization,
} from '../../../utility/Constants';
import { setMinMaxError, setActiveSidebarItem, resetActiveSidebarItem } from '../../../redux/actions/filter';
import { getCategoryMeasurementUnitOptions, getCountryOptions } from '../../../redux/actions/commonFilters';
import VvgCustomDropdownSelector from './elements/VvgCustomDropdownSelector';
import VvgTopbarMobileWrapper from './elements/VvgTopbarMobileWrapper';
import VvgNavbarWrapper from './elements/VvgNavbarWrapper';
import {
    getUserDataFromLocalStorage,
    getTempCurrencyFromLocalStorage,
    getTempLanguageFromLocalStorage,
    findSelectedBreadcrumbs,
    getLanguageId,
    getLanguageName,
    resolveSearchText,
    getCategoryById,
} from '../../../utility/Helpers';
import {
    setResetOrganizationUser,
    getOrganizationsList,
    getOrganizationMemberships,
    getOrganizationProfile,
} from '../../../redux/actions/organization/index';
import { setTranslations } from '../../../redux/storeConfig/store';
import { getOrganizationFromLocalStorage } from '../../../utility/Helpers';
import { filterFunction } from '../../../components/filter/filterButton/VvgFilterButton';
import { VvgChatPreview } from './elements/VvgChatPreview';
import { clearChatState, getUnreadMessages } from '../../../redux/actions/chat';
import { loggedInCurrencySelector } from '../../../redux/selectors/userSelectors';

export const MenuItemClickSources = {
    categories: 'categories',
};

class VvgTopbar extends React.Component {
    constructor(props) {
        super(props);
        const localStorageLanguage = localStorage.getItem(localStorageTempLanguage);
        const selectedLanguageName = localStorageLanguage === null || localStorageLanguage === undefined
            ? defaultLanguageId
            : localStorageLanguage;
        const selectedLanguage = getLanguageId(selectedLanguageName, this.props.supportedLanguages);
        this.state = {
            currenciesOpen: false,
            languagesOpen: false,
            organizationsListOpen: false,
            userMenuOpen: false,
            isLoggedIn: true,
            selectedCurrency: defaultCurrencyId, // EUR
            selectedLanguage, // en
            selectedOrganization: null,
            screenWidth: 0,
            screenHeight: 0,
            userMenuWidth: -1,
            organizationsList: [],
            mobileMenuOpen: false,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        const { isUserLoggedIn, getFavorites, getOrganizationsList, getOrganizationMemberships, getUnreadMessages } =
            this.props;
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setDefaultLanguageAndCurrency();
        if (isUserLoggedIn) {
            getUnreadMessages();
            getFavorites();
            getOrganizationsList();
            getOrganizationMemberships();
        }
        document.addEventListener('scroll', this.closeMenusOnScroll);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isUserLoggedIn, supportedLanguages, organizationProfileData } = nextProps;
        const { isUserLoggedIn: prevIsUserLoggedIn } = this.props;
        if (!prevIsUserLoggedIn && !!isUserLoggedIn) {
            this.props.getFavorites();
            this.props.getOrganizationsList();
            this.props.getOrganizationMemberships();
        }
        if (organizationProfileData !== this.props.organizationProfileData && isUserLoggedIn) {
            this.props.getOrganizationsList();
            this.props.getOrganizationMemberships();
        }
        if (_.isEmpty(this.props.supportedLanguages) && !_.isEmpty(supportedLanguages)) {
            const tempLanguage = getTempLanguageFromLocalStorage();
            this.setState((prevState) => ({
                ...prevState,
                selectedLanguage: getLanguageId(tempLanguage, supportedLanguages),
            }));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        document.removeEventListener('scroll', this.closeMenusOnScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        const { userProfileData, organizationProfileData, resetAttributes, getUnreadMessages, isUserLoggedIn } =
            this.props;
        if (!_.isEqual(prevProps.userProfileData, userProfileData)) {
            if (!_.isEqual(prevProps.userProfileData, userProfileData)) {
                this.setCurrency();
            }
            if (!_.isEqual(prevProps.userProfileData, userProfileData)) {
                this.setLanguage();
            }
            resetAttributes();
        }
        if (!_.isEqual({ ...this.state }, { ...prevState }) && isUserLoggedIn) {
            getUnreadMessages();
        }
        if (!_.isEmpty(prevProps.organizationProfileData) && _.isEmpty(organizationProfileData)) {
            this.setState((prevState) => ({
                ...prevState,
                selectedOrganization: null,
            }));
        }
    }

    closeMenusOnScroll = () => {
        this.setState(prevState => ({
            ...prevState,
            currenciesOpen: false,
            languagesOpen: false,
            organizationsListOpen: false,
            userMenuOpen: false,
            mobileMenuOpen: false,
        }));
    }

    updateWindowDimensions() {
        this.setState((prevState) => ({
            ...prevState,
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
        }));
    }

    setDefaultLanguageAndCurrency = () => {
        const tempCurrency = getTempCurrencyFromLocalStorage();
        if (tempCurrency !== null) {
            this.setState((prevState) => ({
                ...prevState,
                selectedCurrency: Number(tempCurrency),
            }));
        } else {
            localStorage.setItem(localStorageTempCurrency, defaultCurrencyId);
        }
    };

    setLanguage = () => {
        const { userProfileData, organizationProfileData, setActiveLanguage, supportedLanguages } = this.props;
        if (organizationProfileData !== null) {
            this.setState((prevState) => ({
                ...prevState,
                selectedLanguage: userProfileData.language,
            }));
            setActiveLanguage(getLanguageName(userProfileData.language, supportedLanguages));
        } else if (userProfileData !== null) {
            this.setState((prevState) => ({
                ...prevState,
                selectedLanguage: userProfileData.language,
            }));
            setActiveLanguage(getLanguageName(userProfileData.language, supportedLanguages));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                selectedLanguage: defaultLanguageId,
            }));
            setActiveLanguage(getLanguageName(defaultLanguageId, supportedLanguages));
        }
    };

    setCurrency = () => {
        const { userProfileData, organizationProfileData } = this.props;
        if (organizationProfileData !== null && userProfileData.currency) {
            this.setState((prevState) => ({
                ...prevState,
                selectedCurrency: userProfileData.currency,
            }));
            setActiveCurrency(userProfileData.currency);
        } else if (userProfileData !== null && userProfileData.currency !== null) {
            this.setState((prevState) => ({
                ...prevState,
                selectedCurrency: userProfileData.currency,
            }));
            setActiveCurrency(userProfileData.currency);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                selectedCurrency: defaultCurrencyId,
            }));
            setActiveCurrency(defaultCurrencyId);
        }
    };

    logIn = () => {
        history.push('/login');
    };

    logOut = () => {
        const {
            organizationProfileData,
            setResetUser,
            setResetOrganizationUser,
            resetAttributes,
            resetActiveItem,
            resetUserCultureData,
            resetChatState,
        } = this.props;
        history.push('/'); // TODO remove this, jsut for testing
        resetChatState();
        if (organizationProfileData !== null) {
            setResetOrganizationUser();
            localStorage.removeItem(localStorageOrganization);
            this.setState((prevState) => ({
                ...prevState,
                selectedOrganization: null,
            }));
        } else {
            setResetUser();
            setResetOrganizationUser();
            localStorage.removeItem(localStorageToken);
            localStorage.removeItem(localStorageUser);
            localStorage.setItem(localStorageTempCurrency, '44');
        }
        // delete localeStorage token
        // change state
        resetAttributes();
        resetActiveItem(true);
        resetUserCultureData();
    };

    showMyItems = () => {
        history.push('/my-items');
    };

    showFavouriteItems = () => {
        history.push('/favorite-items');
    };

    showUserProfile = () => {
        this.props.resetFavorites();
        this.props.resetUserItems();
        this.props.organizationProfileData !== null && this.props.organizationProfileData !== undefined
            ? history.push('/organization')
            : history.push('/profile');
    };
    showMessages = () => {
      history.push('/communications');
    };

    closeCurrencies = () => {
        this.setState((prevState) => ({
            ...prevState,
            currenciesOpen: false,
        }));
    };

    toggleCurrenciesOpen = () => {
        this.setState((prevState) => ({
            ...prevState,
            currenciesOpen: !prevState.currenciesOpen,
        }));
    };

    toggleLanguagesOpen = () => {
        this.setState((prevState) => ({
            ...prevState,
            languagesOpen: !prevState.languagesOpen,
        }));
    };

    toggleOrganizationsListOpen = () => {
        this.setState((prevState) => ({
            ...prevState,
            organizationsListOpen: !prevState.organizationsListOpen,
        }));
    };

    toggleUserMenuOpen = () => {
        this.setState((prevState) => ({
            ...prevState,
            userMenuOpen: !prevState.userMenuOpen,
        }));
    };

    closeUserMenu = () => {
        this.setState((prevState) => ({
            ...prevState,
            userMenuOpen: false,
        }));
    };

    toggleIsLoggedIn = () => {
        this.setState((prevState) => ({
            ...prevState,
            isLoggedIn: !prevState.isLoggedIn,
        }));
    };

    onCategoriesAutosuggestChange = (event) => {
        const inputValue = event?.target?.value;
        if (inputValue !== null) {
            this.props.setAutosuggestText(inputValue);
            this.props.filterAutocompleteData(inputValue);
        } else {
            this.props.setAutosuggestText(null);
            this.props.filterAutocompleteData(null);
        }
    };

    handleCurrencyItemClick = (id) => {
        const { appliedFilter, setActiveCurrency, userCurrency } = this.props;
        this.setState((prevState) => ({
            ...prevState,
            selectedCurrency: id,
        }));
        this.updateSingleItem('currency', id);
        setActiveCurrency(id);
        if (!_.isEmpty(appliedFilter.commonFilters)) {
            appliedFilter.commonFilters.forEach((item) => {
                if (item.id === 'price_from' || item.id === 'price_to') {
                    const {
                        commonFilters,
                        additionalFilters,
                        currentCategory,
                        searchItemListing,
                        setAppliedCommonFilter,
                        setAppliedAdditionalFilter,
                        resetSearchResult,
                    } = this.props;
                    filterFunction(
                        commonFilters,
                        additionalFilters,
                        currentCategory,
                        searchItemListing,
                        setAppliedCommonFilter,
                        setAppliedAdditionalFilter,
                        resetSearchResult,
                        userCurrency
                    );
                }
            });
        }
    };

    handleLanguageItemClick = (id) => {
        const { supportedLanguages, setActiveLanguage, getCountryOptions, getUserCultureData } = this.props;
        const languageName = getLanguageName(id, supportedLanguages);
        localStorage.setItem(localStorageTempLanguage, languageName);
        setActiveLanguage(languageName);
        this.updateSingleItem('language', id);
        // update labels
        setTranslations(languageName);
        getCountryOptions();
        getUserCultureData();
        this.setState((prevState) => ({
            ...prevState,
            selectedLanguage: id,
        }));
    };

    handleOrganizationsItemClick = (id) => {
        this.setState((prevState) => ({
            ...prevState,
            selectedOrganization: id,
        }));
        this.props.resetUserItems();
        this.props.resetFavorites();
        this.props.setResetOrganizationUser();
        this.props.getOrganizationProfile(id).then(() => {
            history.push('/');
        });
    };

    updateSingleItem = (dataId, value) => {
        const { getUserData, changeSingleItem, isUserLoggedIn, supportedLanguages } = this.props;
        if (isUserLoggedIn) {
            const params = {
                data_id: dataId,
                data_value: value,
            };
            changeSingleItem(params).then(() => {
                const userId = getUserDataFromLocalStorage();
                getUserData(userId);
            });
        }
        if (dataId === 'currency') {
            localStorage.setItem(localStorageTempCurrency, value);
        } else if (dataId === 'language') {
            localStorage.setItem(localStorageTempLanguage, getLanguageName(value, supportedLanguages));
        }
    };

    filter = (source, item, text) => {
        console.log(source, item, text);
        const { userCurrency } = this.props;
        const currentPage = window.location.pathname.split('/')[1];
        const pageList = [
            'ad',
            'my-items',
            'password-reset',
            'favorite-items',
            'change-email',
            'confirmation-email',
            'my-ads',
            'organization',
            'profile',
            'register',
            'login',
            'ads',
            'communications',
        ];
        if (pageList.includes(currentPage)) {
            history.push('/');
            this.props.setAutosuggestText(text);
        }
        switch (source) {
            case 'Ads':
            case 'Items': {
                const { title } = item;
                const search_text = resolveSearchText(title);
                this.props.resetFilters();
                this.props.resetFilterForm();
                this.props.setMinMaxError([]);
                this.props.resetAttributes();
                this.props.resetActiveItem(true);
                search_text.length === 0
                    ? this.props.filterResults(null, null, userCurrency)
                    : this.props.filterResults(
                          null,
                          {
                              search_text,
                          },
                          userCurrency
                      );
                break;
            }
            case 'Categories': {
                const { category, title } = item;
                const data = this.props.dataCategories;
                const x = getCategoryById(data, category);
                const selectedBreadcrumbs = findSelectedBreadcrumbs(data, category);
                this.props.filterResults(category, null, userCurrency);
                this.props.setSelectedCategory({
                    id: category,
                    name: title,
                });
                this.props.setSelectedBreadcrumbs(selectedBreadcrumbs);
                this.props.setActiveBreadcrumbs(selectedBreadcrumbs);
                this.props.setActiveItem(x);
                this.props.getAttributes(category);
                break;
            }
            case 'none': {
                const search_text = resolveSearchText(text);
                this.props.resetFilters();
                this.props.resetFilterForm();
                this.props.setMinMaxError([]);
                this.props.resetAttributes();
                this.props.resetActiveItem(true);
                search_text.length === 0
                    ? this.props.filterResults(null, null, userCurrency)
                    : this.props.filterResults(
                          null,
                          {
                              search_text,
                          },
                          userCurrency
                      );
                break;
            }
            default: {
                break;
            }
        }
    };

    resetSelection = (shouldPush = false) => {
        const {
            resetFilters,
            filterAfterReset,
            resetFilterForm,
            setMinMaxError,
            resetAttributes,
            resetActiveItem,
            resetSearchResult,
            resetActiveFilters,
            setSelectedBreadcrumbs,
            userCurrency,
        } = this.props;
        filterAfterReset(shouldPush, userCurrency);
        resetFilters();
        resetActiveFilters();
        resetFilterForm();
        setMinMaxError([]);
        resetAttributes();
        resetActiveItem(true);
        resetSearchResult();
        setSelectedBreadcrumbs([]);
        setActiveBreadcrumbs([]);
        this.props.setAutosuggestText(null);
    };

    resolveIsOnHome = () => {
        const pathnameFirst = window.location.pathname.split('/')[1];
        const isOnHomepage = pathnameFirst === '';
        return isOnHomepage;
    };

    updateUserMenuWidth = (userMenuWidth) => {
        this.setState((prevState) => ({
            ...prevState,
            userMenuWidth,
        }));
    };

    renderOrganizationsList = (isMobile, isLandscape) => {
        const { organizationsCombined } = this.props;
        const { organizationsListOpen, selectedOrganization } = this.state;
        if (!_.isEmpty(organizationsCombined)) {
            return (
                <VvgMenuOrganizationsSelector
                    items={organizationsCombined}
                    isOpen={organizationsListOpen}
                    handleClick={this.toggleOrganizationsListOpen}
                    handleItemClick={this.handleOrganizationsItemClick}
                    selectedId={
                        selectedOrganization === null ? getOrganizationFromLocalStorage() : selectedOrganization
                    }
                    itemSize={isMobile ? 100 : isLandscape ? 13 : 8}
                    isMobile={isMobile}
                />
            );
        }
    };

    toggleMobileMenu = () => {
        this.setState(prevState => ({
            ...prevState,
            mobileMenuOpen: !prevState.menuOpen,
        }));
    }
    closeMobileMenu = () => {
        this.setState(prevState => ({
            ...prevState,
            mobileMenuOpen: false,
        }));
    }

    render() {
        const {
            autocompleteData = [],
            currencyOptions,
            supportedLanguages,
            renderNavbar,
            isUserLoggedIn,
            userProfileData,
            organizationProfileData,
            dataCategories,
            numberOfUnreadMessages,
            autosuggestInput,
        } = this.props;
        const {
            screenWidth,
            currenciesOpen,
            languagesOpen,
            userMenuOpen,
            selectedCurrency,
            selectedLanguage,
            selectedOrganization,
            mobileMenuOpen,
        } = this.state;
        const isMobile = screenWidth !== 0 && screenWidth < 600;
        const isLandscape = screenWidth !== 0 && screenWidth < 1100;
        const isOnHomepage = this.resolveIsOnHome();
        return (
            <div className="topbar-wrapper">
                <div className="topbar-wrapper-main">
                    {isLandscape && isOnHomepage && (
                        <VvgNavbarWrapper renderNavbar={renderNavbar} itemSize={isMobile ? 10 : 10} />
                    )}
                    <VvgTopbarLogo
                        resetSelection={this.resetSelection}
                        itemSize={isMobile ? (isUserLoggedIn ? 50 : 100) : isLandscape ? 15 : 15.7}
                        isMobile={isMobile}
                        isLandscape={isLandscape}
                        isOnHomepage={isOnHomepage}
                    />
                    {!isOnHomepage && isMobile && isLandscape && <VvgMenuSeparator itemSize={20} />}
                    {!isMobile && (
                        <VvgCategoriesAutosuggest
                            onInputChange={this.onCategoriesAutosuggestChange}
                            suggestions={autocompleteData}
                            initialInput={autosuggestInput}
                            itemSize={
                                isMobile
                                    ? isUserLoggedIn
                                        ? 20
                                        : 40
                                    : isLandscape
                                    ? isUserLoggedIn
                                        ? 20
                                        : 46
                                    : isUserLoggedIn
                                    ? 52.3
                                    : 65.3
                            }
                            onItemClick={this.filter}
                            isMobile={isMobile}
                            categories={dataCategories}
                            resetSelection={this.resetSelection}
                            isOnHomepage={isOnHomepage}
                        />
                    )}
                    {!isMobile && (
                        <VvgChatPreview
                            isMobile={isMobile}
                            itemSize={isMobile ? 20 : isLandscape ? 10 : 8}
                            isHiddenInFull
                            numberOfUnreadMessages={numberOfUnreadMessages}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                    )}
                    <VvgAddPost isUserLoggedIn={isUserLoggedIn} isMobile={isMobile} itemSize={isMobile ? 13 : 10} />
                    <VvgTopbarMobileWrapper
                        isMobile={isMobile}
                        itemSize={13}
                        userMenuOpen={userMenuOpen}
                        toggleUserMenuOpen={this.toggleUserMenuOpen}
                        menuOpen={mobileMenuOpen}
                        toggleMenu={this.toggleMobileMenu}
                        closeMenu={this.closeMobileMenu}
                    >
                        <VvgCustomDropdownSelector
                            items={currencyOptions}
                            isOpen={currenciesOpen}
                            handleClick={this.toggleCurrenciesOpen}
                            close={this.closeCurrencies}
                            handleItemClick={this.handleCurrencyItemClick}
                            selectedId={selectedCurrency}
                            menuItemSize={isMobile ? 100 : isLandscape ? 13 : 8}
                            wrapperSize={30}
                            placeholder={''}
                            isMobile={isMobile}
                        />
                        <VvgMenuDropdownSelector
                            items={supportedLanguages}
                            isOpen={languagesOpen}
                            handleClick={this.toggleLanguagesOpen}
                            handleItemClick={this.handleLanguageItemClick}
                            selectedId={selectedLanguage}
                            itemSize={isMobile ? 100 : isLandscape ? 13 : 8}
                            isMobile={isMobile}
                        />
                        {this.renderOrganizationsList(isMobile, isLandscape)}
                        {isUserLoggedIn && (
                            <>
                                <VvgChatPreview
                                    isMobile={isMobile}
                                    itemSize={isMobile ? 30 : isLandscape ? 13 : 5}
                                    isHiddenInMobile
                                    numberOfUnreadMessages={numberOfUnreadMessages}
                                    isUserLoggedIn={isUserLoggedIn}
                                />
                            </>
                        )}
                    </VvgTopbarMobileWrapper>
                    <VvgUserMenu
                        isOpen={userMenuOpen}
                        handleClick={this.toggleUserMenuOpen}
                        handleLogInOut={this.toggleIsLoggedIn}
                        isLoggedIn={isUserLoggedIn}
                        itemSize={isMobile ? 16 : 8}
                        logIn={this.logIn}
                        logOut={this.logOut}
                        showMyItems={this.showMyItems}
                        showFavouriteItems={this.showFavouriteItems}
                        showUserProfile={this.showUserProfile}
                        showMessages={this.showMessages}
                        isMobile={isMobile}
                        isLandscape={isLandscape}
                        userProfileData={userProfileData}
                        organizationProfileData={organizationProfileData}
                        width={this.state.userMenuWidth}
                        selectedOrganization={selectedOrganization}
                        numberOfUnreadMessages={numberOfUnreadMessages}
                        closeUserMenu={this.closeUserMenu}
                    />
                </div>

                {isMobile && (
                    <VvgCategoriesAutosuggest
                        onInputChange={this.onCategoriesAutosuggestChange}
                        suggestions={autocompleteData}
                        initialInput={autosuggestInput}
                        itemSize={100}
                        onItemClick={this.filter}
                        isMobile={isMobile}
                        categories={dataCategories}
                        resetSelection={this.resetSelection}
                        isOnHomepage={isOnHomepage}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        data: { autocompleteData, categories },
        commonFilters: { currencyOptions, supportedLanguages },
        user: { isUserLoggedIn = false, userProfileData },
        organization: { organizationProfileData, organizationsList, organizationMemberships },
        filter: { appliedFilter },
        chat: { unreadMessages },
        selectedData: { autosuggestInput },
    } = state;
    const userCurrency = loggedInCurrencySelector(state);
    return {
        ...props,
        autocompleteData,
        currencyOptions,
        supportedLanguages,
        userProfileData,
        isUserLoggedIn,
        dataCategories: categories,
        organizationProfileData,
        organizationsList,
        organizationMemberships,
        appliedFilter,
        organizationsCombined:
            organizationsList !== null && organizationMemberships !== null
                ? organizationsList.concat(organizationMemberships)
                : organizationsList === null
                ? organizationMemberships
                : organizationsList,
        commonFilters: state.filter.sidebarFilter.commonFilters,
        additionalFilters: state.filter.sidebarFilter.additionalFilters,
        currentCategory: state.selectedData.currentCategory,
        numberOfUnreadMessages: unreadMessages.length,
        userCurrency,
        autosuggestInput,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterResults: (id, queryParams = {}, userCurrency) =>
            dispatch(searchItemListing(id, queryParams, null, null, null, null, null, userCurrency)),
        filterAfterReset: (shouldPush = false, userCurrency) =>
            dispatch(searchItemListing(undefined, {}, null, null, null, shouldPush, null, userCurrency)),
        filterAutocompleteData: (inputValue) => dispatch(getAutocompleteData(inputValue)),
        setSelectedCategory: (category) => dispatch(setCurrentCategory(category)),
        setSelectedBreadcrumbs: (data) => dispatch(setBreadcrumbs(data)),
        setActiveBreadcrumbs: (data) => dispatch(setActiveBreadcrumbs(data)),
        resetFilters: () => dispatch(resetSelectedData()),
        resetActiveFilters: () => dispatch(resetActiveFilters()),
        resetFilterForm: () => dispatch(reset(filtersForm)),
        setMinMaxError: (errors) => dispatch(setMinMaxError(errors)),
        resetAttributes: () => dispatch(resetCategoryAttributes()),
        getAttributes: (id) => dispatch(getCategoryAttributes(id)),
        getUnitOptions: (id) => dispatch(getCategoryMeasurementUnitOptions(id)),
        setActiveItem: (id) => dispatch(setActiveSidebarItem(id)),
        resetActiveItem: (flag) => dispatch(resetActiveSidebarItem(flag)),
        changeSingleItem: (params) => dispatch(changeSingleItem(params)),
        getUserData: (id) => dispatch(getUserData(id)),
        setResetUser: () => dispatch(setResetUser()),
        setResetOrganizationUser: () => dispatch(setResetOrganizationUser()),
        getFavorites: () => dispatch(getFavorites()),
        getOrganizationsList: () => dispatch(getOrganizationsList()),
        getOrganizationMemberships: () => dispatch(getOrganizationMemberships()),
        getOrganizationProfile: (id) => dispatch(getOrganizationProfile(id)),
        setActiveLanguage: (id) => dispatch(setActiveLanguage(id)),
        setActiveCurrency: (id) => dispatch(setActiveCurrency(id)),
        getCategories: () => dispatch(getCategories()),
        setAppliedCommonFilter: (filterData) => dispatch(setAppliedCommonFilter(filterData)),
        setAppliedAdditionalFilter: (filterData) => dispatch(setAppliedAdditionalFilter(filterData)),
        searchItemListing: (id, queryParams, payload, userCurrency) =>
            dispatch(searchItemListing(id, queryParams, payload, null, null, null, null, userCurrency)),
        resetSearchResult: () => dispatch(resetSearchResult()),
        getCountryOptions: () => dispatch(getCountryOptions()),
        resetUserItems: () => dispatch(resetUserItems()),
        resetFavorites: () => dispatch(resetFavorites()),
        getUnreadMessages: () => dispatch(getUnreadMessages()),
        resetUserCultureData: () => dispatch(resetUserCultureData()),
        getUserCultureData: () => dispatch(fetchUserCultureData()),
        setAutosuggestText: (text) => dispatch(setAutosuggestInput(text)),
        resetChatState: () => dispatch(clearChatState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgTopbar);
