import React, { PureComponent } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import _ from "lodash";
import VvgNumericInput from "../numericInput/VvgNumericInput";
import VvgToggle from "../toggle/VvgToggle";

import { change, getFormValues } from "redux-form";
import {
  getCountryOptions,
  getItemTypeOptions,
  getSupportedLanguages,
  getCategoryMeasurementUnitOptions,
} from "../../redux/actions/commonFilters/index";
import {
  setSidebarCommonFilter,
  setAppliedCommonFilter,
} from "../../redux/actions/filter/index";
import { setInitialFilter } from "../../redux/actions/filter/index";
import VvgInput from "../../components/VvgInput/VvgInput";
import { COMMON_FILTERS, filtersForm } from "../../utility/Constants";
import { transformSelectValue } from "../../utility/Helpers";
import "./VvgCommonFilter.scss";
import VvgSelectNew from "../select/VvgSelectNew";

const priceCurrencyFromData = {
  id: "price_from",
  name: "price_from",
};

const priceCurrencyToData = {
  id: "price_to",
  name: "price_to",
};

const itemsWithoutPriceId = "include_items_without_price";
const itemsWithoutMediaId = "include_items_with_media";
const publishedById = "owner_type";

class VvgCommonFilter extends PureComponent {
  state = {
    optionsLoaded: false,
  };
  toggle = (event) => {
    const id = event.target.getAttribute("id");
    this.setState((state) => ({ [id]: !state[id] }));
  };

  componentDidMount() {
    const {
      getCountryOptions,
      getItemTypeOptions,
      itemTypeOptions,
      countryOptions,
    } = this.props;
    if (_.isEmpty(itemTypeOptions)) {
      getItemTypeOptions();
    }
    // if (_.isEmpty(currencyOptions)) {
    //   getCurrencyOptions();
    // }
    if (_.isEmpty(countryOptions)) {
      getCountryOptions();
    }
    // if (_.isEmpty(supportedLanguages)) {
    //   getSupportedLanguages();
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      itemTypeOptions,
      countryOptions,
      filterValues,
      categoryMeasurementUnits,
      setSidebarCommonFilter,
      setAppliedCommonFilter,
    } = this.props;
    if (
      !this.state.optionsLoaded &&
      !_.isEmpty(itemTypeOptions) &&
      !_.isEmpty(countryOptions)
    ) {
      this.setState({ optionsLoaded: true });
      this.transformInitialFilters();
    }
    if (
      prevProps.categoryMeasurementUnits !== categoryMeasurementUnits &&
      _.isEmpty(categoryMeasurementUnits)
    ) {
      const filtersReduced = filterValues.filter(
        (item) => item.id !== "measurement_unit"
      );
      setSidebarCommonFilter(filtersReduced);
      setAppliedCommonFilter(filtersReduced);
    }
  }

  transformInitialFilters = () => {
    const {
      itemTypeOptions,
      countryOptions,
      filterValues,
      changeFilter,
    } = this.props;
    filterValues.forEach((item) => {
      // eslint-disable-line array-callback-return
      switch (item.id) {
        case COMMON_FILTERS.LISTING_TYPE:
          changeFilter(
            filtersForm,
            COMMON_FILTERS.LISTING_TYPE,
            transformSelectValue(item, itemTypeOptions)
          );
          break;
        case COMMON_FILTERS.INCLUDE_ITEMS_WITHOUT_PRICE:
          changeFilter(
            filtersForm,
            COMMON_FILTERS.INCLUDE_ITEMS_WITHOUT_PRICE,
            item.value === true || item.value === "1" ? [0] : [2]
          );
          break;
        case COMMON_FILTERS.PRICE_FROM:
          changeFilter(filtersForm, COMMON_FILTERS.PRICE_FROM, item.value);
          break;
        case COMMON_FILTERS.PRICE_TO:
          changeFilter(filtersForm, COMMON_FILTERS.PRICE_TO, item.value);
          break;
        case COMMON_FILTERS.COUNTRY:
          changeFilter(
            filtersForm,
            COMMON_FILTERS.COUNTRY,
            transformSelectValue(item, countryOptions)
          );
          break;
        case COMMON_FILTERS.PLACE:
          changeFilter(filtersForm, COMMON_FILTERS.PLACE, item.value);
          break;
        case COMMON_FILTERS.OWNER_TYPE:
          changeFilter(
            filtersForm,
            COMMON_FILTERS.OWNER_TYPE,
            item.value === true || item.value === "1" ? [0] : [2]
          );
          break;
        case COMMON_FILTERS.INCLUDE_ITEMS_WITH_MEDIA:
          changeFilter(
            filtersForm,
            COMMON_FILTERS.INCLUDE_ITEMS_WITH_MEDIA,
            item.value === true || item.value === "1" ? [0] : [2]
          );
          break;
        case COMMON_FILTERS.MEASUREMENT_UNIT:
          changeFilter(
            filtersForm,
            COMMON_FILTERS.MEASUREMENT_UNIT,
            item.value
          );
          break;
        default:
          break;
      }
    });
  };

  render() {
    const itemTypeText = I18n.t("lblItemType");
    const priceCurrencyTypeText = I18n.t("lblPriceCurrencyType");
    // const currencyText = I18n.t("lblCurrency");
    const includeItemsWithoutPrice = I18n.t("lblIncludeItemsWithoutPrice");
    const itemCountryText = I18n.t("lblItemCountry");
    const itemCityText = I18n.t("lblItemCity");
    const itemCityId = "place";
    const publishedByText = I18n.t("lblPublishedBy");
    const includeItemsWithoutMediaText = I18n.t("lblIncludeItemsWithoutMedia");
    const {
      itemTypeOptions,
      countryOptions,
      categoryMeasurementUnits,
    } = this.props;
    return !this.state.optionsLoaded ? (
      <div> </div>
    ) : (
      <div className="common-filter-container">
        <div>
          <div>{itemTypeText}</div>
          <VvgSelectNew
            id="listing_type"
            filterName={itemTypeText}
            name={itemTypeText}
            data={itemTypeOptions}
            isMulti={true}
            pureOptions
            isCommon
          />
        </div>
        <div className="common-filter-name-container">
          <VvgNumericInput
            idFrom="price_from"
            idTo="price_to"
            fromData={priceCurrencyFromData}
            toData={priceCurrencyToData}
            filterName={priceCurrencyTypeText}
            isCommon
            categoryMeasurementUnits={categoryMeasurementUnits}
            measurementUnitsName={"measurement_unit"}
          />
        </div>
        <div>
          <div className="common-filter-name-container">
            {includeItemsWithoutPrice}
          </div>
          <VvgToggle
            id="include_items_without_price"
            data={{ id: itemsWithoutPriceId, name: includeItemsWithoutPrice }}
            isCommon
          />
        </div>
        <div>
          <div className="common-filter-name-container">{itemCountryText}</div>
          <VvgSelectNew
            id="country"
            filterName={itemCountryText}
            name={itemCountryText}
            data={countryOptions}
            isMulti
            pureOptions
            isCommon
          />
        </div>
        <div>
          <div className="common-filter-name-container">{itemCityText}</div>
          <VvgInput data={{ id: "place", name: itemCityId }} isCommon />
        </div>

        <div>
          <div className="common-filter-name-container">{publishedByText}</div>
          <VvgToggle
            id="owner_type"
            data={{ id: publishedById, name: publishedByText }}
            options={{
              lblLeft: I18n.t("lblIndividual"),
              lblRight: I18n.t("lblOrganization"),
            }}
            isCommon
          />
        </div>

        <div>
          <div className="common-filter-name-container">
            {includeItemsWithoutMediaText}
          </div>
          <VvgToggle
            id="include_items_with_media"
            data={{
              id: itemsWithoutMediaId,
              name: includeItemsWithoutMediaText,
            }}
            isCommon
            hasTwoOptions={true}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itemTypeOptions: state.commonFilters.itemTypeOptions,
    supportedLanguages: state.commonFilters.supportedLanguages,
    countryOptions: state.commonFilters.countryOptions,
    filterValues: state.filter.sidebarFilter.commonFilters,
    categoryMeasurementUnits: state.commonFilters.categoryMeasurementUnits,
    formValues: getFormValues(filtersForm)(state),
    i18n: state.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountryOptions: () => dispatch(getCountryOptions()),
    getItemTypeOptions: () => dispatch(getItemTypeOptions()),
    getSupportedLanguages: () => dispatch(getSupportedLanguages()),
    getCategoryMeasurementUnitOptions: (categoryId) =>
      dispatch(getCategoryMeasurementUnitOptions(categoryId)),
    setInitialFilter: (filterData) => dispatch(setInitialFilter(filterData)),
    changeFilter: (formName, fieldName, value) =>
      dispatch(change(formName, fieldName, value)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
    setAppliedCommonFilter: (filterData) =>
      dispatch(setAppliedCommonFilter(filterData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgCommonFilter);
