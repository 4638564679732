import {
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_PROFILE,
  SET_ORGANIZATION_USERS,
  SET_ORGANIZATION_MEMBERSHIPS,
  RESET_ORGANIZATION_PROFILE,
} from "../../actions/organization";

const initialState = {
  organizationsList: null,
  organizationProfileData: null,
  organizationUsers: null,
  organizationMemberships: null,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS_LIST:
      return {
        ...state,
        organizationsList: action.data,
      };
    case SET_ORGANIZATION_PROFILE:
      return {
        ...state,
        organizationProfileData: action.data,
      };
    case SET_ORGANIZATION_USERS:
      return {
        ...state,
        organizationUsers: action.data,
      };
    case SET_ORGANIZATION_MEMBERSHIPS:
      return {
        ...state,
        organizationMemberships: action.data,
      };
    case RESET_ORGANIZATION_PROFILE:
      return {
        ...state,
        organizationProfileData: null,
        organizationsList: null,
        organizationUsers: null,
        organizationMemberships: null,
      };
    default:
      return state;
  }
};

export default organizationReducer;
