import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import classnames from "classnames";
import "./FormComponents.scss";
import { I18n } from "react-redux-i18n";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "inherit",
  // padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const multiValueContainer1 = ({ selectProps, data }) => {
  const label = data.name;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex((selected) => selected.name === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? "" : ", ";
  const val = `${label}${labelSuffix}`;
  return val;
};

export const ReduxFormSelect = (props) => {
  const testChange = (selectedOption, triggeredAction) => {
    props.input.onChange(selectedOption);
  };

  const {
    input,
    options,
    isMulti,
    className,
    isDisabled,
    isNumeric,
    isClearable,
    formErrors,
  } = props;

  const { name, value, onBlur, onFocus } = input;

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "95%",
      overflow: "hidden",
      display: "initial",
      fontSize: "13px",
      // height: state.isSelected ? '100px' : '50px'
      // height: "38px",
      // maxHeight: "38px"
    }),
    control: (base, state) => ({
      ...base,
      border: "1px solid rgb(204,204,204)",
      // This line disable the blue border
      boxShadow: 0,
      "&:hover": {
        border: "1px solid rgb(204,204,204)",
      },
    }),
    menuList: styles => {
      return {
        ...styles,
        maxHeight: 135,
        fontSize: "13px",
      };
    },
    menu: styles => {
      return {
        ...styles,
        zIndex: 9999,
        fontSize: "13px",
      };
    },
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#ff9f43' : '#fff',
      color: '#626262',
      "&:hover": {
        backgroundColor: '#ffd8a1',
      },
    }),
  };

  const customStylesWithError = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "95%",
      overflow: "hidden",
      display: "initial",
      fontSize: "13px",
    }),
    control: (base) => addBorder(base),
    menuList: styles => {
      return {
        ...styles,
        fontSize: "13px",
      };
    },
    menu: styles => {
      return {
        ...styles,
        zIndex: 9999,
        fontSize: "13px",
      };
    },
  };

  const addBorder = (base) => {
    return {
      ...base,
      border: "1px solid #eb516d",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #eb516d",
      },
    };
  };

  const fieldHasError = (name, fieldErrors) => {
    let isNameInErros = false;
    fieldErrors &&
      Object.keys(fieldErrors).map((item) => { // eslint-disable-line array-callback-return
        if (item === name) isNameInErros = true;
      });
    if (isNameInErros) {
      return customStylesWithError;
    }
    return customStyles;
  };

  if (typeof value === Object) {
    value.filterName = name;
  }
  const filterOption = Object.assign({}, value);
  filterOption.filterName = name;

  return (
    <>
      {isMulti ? (
        <Select
          {...props}
          name={name}
          // name={name.split(" ").join("")}
          value={props.input.value}
          onChange={(selectedOption, triggeredAction) =>
            testChange(selectedOption, triggeredAction)
          }
          isMulti={isMulti}
          options={options}
          onBlur={() => onBlur(value)} // event.preventdefault
          // onBlur={event => event.preventDefault()}
          onFocus={onFocus}
          className={classnames("", className)}
          classNamePrefix={isNumeric ? "" : "select"}
          // isSearchable={isSearchable || false}
          // isSearchable={isNumeric ? true : false}
          isSearchable={true}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPlacement="auto"
          isClearable={isNumeric ? isClearable : true}
          isDisabled={isDisabled}
          styles={fieldHasError(name, formErrors)}
          components={{
            MultiValueContainer: multiValueContainer1,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            // Input: Input,
            // Input: (optionProps) => (
            //   <components.Input {...optionProps} type="number" />
            // ),
          }}
          formatGroupLabel={formatGroupLabel}
          menuContainerStyle={{ zIndex: 999999 }}
          blurInputOnSelect={false}
          getNewOptionData={(inputValue, optionLabel) => ({
            id: optionLabel,
            name: inputValue,
            __isNew__: true,
          })}
          placeholder={I18n.t("lblSelect")}
        />
      ) : (
        <CreatableSelect
          {...props}
          name={name}
          // name={name.split(" ").join("")}
          value={props.input.value}
          onChange={(selectedOption, triggeredAction) =>
            testChange(selectedOption, triggeredAction)
          }
          isMulti={isMulti}
          options={options}
          onBlur={() => onBlur(value)} // event.preventdefault
          // onBlur={event => event.preventDefault()}
          onFocus={onFocus}
          className={classnames("", className)}
          classNamePrefix={isNumeric ? "" : "select"}
          // isSearchable={isSearchable || false}
          // isSearchable={isNumeric ? true : false}
          isSearchable={true}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          menuPlacement="auto"
          isClearable={isNumeric ? isClearable : true}
          isDisabled={isDisabled}
          styles={fieldHasError(name, formErrors)}
          // styles={customStyles}
          components={{
            MultiValueContainer: multiValueContainer1,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            // Input: Input,
            // Input: (optionProps) => (
            //   <components.Input {...optionProps} type="number" />
            // ),
          }}
          formatGroupLabel={formatGroupLabel}
          menuContainerStyle={{ zIndex: 999999 }}
          blurInputOnSelect={false}
          getNewOptionData={(inputValue, optionLabel) => ({
            id: optionLabel,
            name: inputValue,
            __isNew__: true,
          })}
          placeholder={I18n.t("lblSelect")}
        />
      )}
    </>
  );
};
