import React, { Component, useCallback, useRef } from 'react';
import '../VvgTopbar.css';
import { Settings } from 'react-feather';
import { useOutsideClickListener } from '../../../../utility/Hooks';

class VvgTopbarMobileWrapper extends Component {
    render() {
        const { isMobile, itemSize, children, userMenuOpen, toggleUserMenuOpen, menuOpen, toggleMenu, closeMenu } = this.props;
        return (
            <MobileWrapper
                menuOpen={menuOpen}
                isMobile={isMobile}
                closeMenu={closeMenu}
                toggleMenu={toggleMenu}
                itemSize={itemSize}
                userMenuOpen={userMenuOpen}
                toggleUserMenuOpen={toggleUserMenuOpen}
            >
                {children}
            </MobileWrapper>
        );
    }
}

const MobileWrapper = (props) => {
    const { isMobile, menuOpen, itemSize, children, closeMenu, toggleMenu, userMenuOpen, toggleUserMenuOpen } = props;
    const style = {
        width: `${itemSize}%`,
    };
    const ref = useRef(null);
    useOutsideClickListener(ref, closeMenu, 'mobile wrapper');
    const itemsStyle = {
        transform: menuOpen ? 'none' : 'translateX(100%)',
        position: 'fixed',
        border: '1px solid rgba(34, 41, 47, 0.15)',
        borderRadius: '4px',
    }
    const onSettingsClick = useCallback(() => {
        if (userMenuOpen) {
            toggleUserMenuOpen();
        }
        toggleMenu();
    }, [userMenuOpen, toggleUserMenuOpen, toggleMenu]);
    return (
        <>
            {isMobile ?
                <div
                    ref={ref}
                style={style} className="mobile-menu-wrapper">
                    <div className="menu-item" style={{justifyContent: 'center'}}>
                        <Settings onClick={onSettingsClick} className="mobile-menu-icon" />
                    </div>
                    <div style={itemsStyle} className="mobile-menu-items">
                        {children}
                    </div>
                </div>
                : children
            }

        </>
    );
}

export default VvgTopbarMobileWrapper;