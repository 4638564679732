import React from "react";
import { Field } from "redux-form";
import "react-toggle/style.css";
import ReduxToggle from '../ReduxFormComponents/ReduxToggle';
import './VvgToggle.scss';

class VvgToggle extends React.Component {
  render() {
    const { data, options, isCommon, id, hasTwoOptions } = this.props;
    return (
      <div>
      {!isCommon && data.name}
      <Field
          name={id !== undefined ? id : data.name}
          component={ReduxToggle}
          options={options}
          data={data}
          // defaultValue={defaultValues}
          isCommon={isCommon}
          hasTwoOptions={hasTwoOptions}
        />
      </div>
    );
  }
}

export default VvgToggle;
