import React, { PureComponent } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { ChevronRight, ChevronDown } from "react-feather";
import classnames from "classnames";
import { Collapse } from "reactstrap";
import _ from "lodash";
import { reduxForm, reset } from "redux-form";
import VvgSelectNew from "../../components/select/VvgSelectNew";
import VvgCombinedSelect from "../../components/select/VvgCombinedSelect";
import VvgNumericInput from "../../components/numericInput/VvgNumericInput";
import Timepicker from "../../components/datepicker/Timepicker";
import VvgToggle from "../../components/toggle/VvgToggle";
import "./VvgFilter.scss";
import VvgCommonFilter from "../commonFilters/VvgCommonFilter";
import VvgDatepicker from "../datepicker/VvgDatepicker";
import VvgInput from "../../components/VvgInput/VvgInput";
import validate from "./validate";
import { filtersForm } from "../../utility/Constants";
import {
  setAppliedAdditionalFilter,
  setSidebarAdditionalFilter,
  setSidebarCommonFilter,
  setAppliedCommonFilter,
} from "../../redux/actions/filter/index";
import { searchItemListing } from "../../redux/actions/data";
import { getCategoryAttributes } from "../../redux/actions/data/index";
import { loggedInCurrencySelector } from "../../redux/selectors/userSelectors";

const vvgFilterPrefix = "vvg-filter-";
const typePriceLocationId = "999";

class VvgFilter extends PureComponent {
  state = {
    selectedFilterGroup: null,
    hoveredItem: null,
  };
  toggle = (id) => {
    // scroll to the start of an element
    const idd = `${vvgFilterPrefix}${typePriceLocationId}`;
    // eslint-disable-next-line react/no-string-refs
    const reff = this.refs[idd];
    reff.scrollIntoView();

    if (this.state.selectedFilterGroup === id) {
      this.setState({
        ...this.state,
        selectedFilterGroup: null,
      });
    } else {
      this.setState({
        ...this.state,
        selectedFilterGroup: id,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const {
      filterData,
      currentCategory,
      setAppliedAdditionalFilter,
      setSidebarAdditionalFilter,
      setSidebarCommonFilter,
      setAppliedCommonFilter,
      userCurrency,
    } = this.props;
    if (!_.isEqual(prevProps.filterData, filterData)) {
      this.getGroups();
    }
    if (
      prevProps.currentCategory &&
      currentCategory &&
      !_.isEqual(prevProps.currentCategory.id, currentCategory.id)
    ) {
      setAppliedAdditionalFilter([]);
      setSidebarAdditionalFilter([]);
      this.setState({
        ...this.state,
        selectedFilterGroup: null,
      });
    }
    if (prevProps.activeLanguage !== this.props.activeLanguage) {
      if (currentCategory !== null) {
        this.props.getCategoryAttributes(currentCategory.id);
        this.props.resetForm(filtersForm);
        setSidebarAdditionalFilter([]);
        setAppliedAdditionalFilter([]);
        setSidebarCommonFilter([]);
        setAppliedCommonFilter([]);
        this.props.searchItemListing(currentCategory.id);
      }
    }
    if (prevProps.userCurrency !== userCurrency) {
      if (currentCategory !== null) {
        this.props.getCategoryAttributes(currentCategory.id);
        this.props.resetForm(filtersForm);
        setSidebarAdditionalFilter([]);
        setAppliedAdditionalFilter([]);
        setSidebarCommonFilter([]);
        setAppliedCommonFilter([]);
        this.props.searchItemListing(currentCategory.id, userCurrency);
      }
    }
    if (
      prevProps.userProfileData !== this.props.userProfileData &&
      this.props.userProfileData === null
    ) {
      this.props.resetForm(filtersForm);
    }
  }

  getGroups = () => {
    const { filterData } = this.props;
    const groups = [];
    filterData !== null &&
      filterData.forEach((item) => {
        // eslint-disable-line array-callback-return
        if (groups.length > 0) {
          let exists = false;
          groups.forEach((group) => {
            if (group.id === item.attribute_group_id) {
              exists = true;
            }
          });
          if (!exists) {
            groups.push({ id: item.attribute_group_id, name: item.group_name });
          }
        } else {
          groups.push({ id: item.attribute_group_id, name: item.group_name });
        }
      });
    return groups;
  };

  getGroupName = (id) => {
    const groups = this.getGroups();
    let name = "";
    groups.forEach((group) => {
      // eslint-disable-line array-callback-return
      if (String(group.id) === String(id)) {
        name = group.name;
      }
    });
    return name;
  };

  getFilterGroups = () => {
    const { filterData } = this.props;
    const filterGroups = _(filterData)
      .groupBy((x) => x.attribute_group_id)
      .map((value, key) => ({ id: key, data: value }))
      .value();
    return filterGroups;
  };

  showData = (node, id, lvl, nodes) => {
    if (node.attribute_group_id !== undefined) {
      let filterType;
      if (node.data_type === 1) {
        filterType = <VvgInput data={node} />;
      }
      if (node.data_type === 2 || node.data_type === 3) {
        filterType = <VvgNumericInput data={node} isMulti={false} />;
      }
      if (node.data_type === 4) {
        filterType = <VvgDatepicker data={node} />;
      }
      if (node.data_type === 5) {
        filterType = <Timepicker data={node} />;
      }
      if (node.data_type === 6) {
        filterType = <VvgToggle data={node} />;
      }
      if (node.data_type === 7) {
        if (node.child_id !== null) {
          const childNode = nodes.find((item) => item.id === node.child_id);
          filterType = (
            <VvgCombinedSelect
              parentNode={node}
              childNode={childNode}
              isMulti={false}
            />
          );
        } else {
          if (node.parent_id !== null) {
            // skip
          } else {
            filterType = <VvgSelectNew data={node} isMulti={true} />;
          }
        }
      }
      if (node.data_type === 8) {
        filterType = <VvgSelectNew data={node} isMulti={true} />;
      }
      // return <>{filterType}</>;
      return <div style={{ padding: "5px" }}>{filterType}</div>;
    }
    return (
      <li
        id={`${vvgFilterPrefix}${id}`}
        ref={`${vvgFilterPrefix}${id}`}
        className={classnames({
          "nav-item vvg-filter-item-container": true,
          hover: this.state.hoveredItem === id,
        })}
      >
        <h6
          className={classnames({
            "d-flex justify-content-between": true,
            "sidebar-group-active1": this.state.selectedFilterGroup === id,
          })}
          onMouseEnter={() => {
            this.handleSidebarMouseEnter(id);
          }}
          onMouseLeave={() => {
            this.handleSidebarMouseEnter(null);
          }}
          key={id}
          onClick={() => this.toggle(node.id)}
        >
          <div className="menu-text">
            {/* {child.icon} */}
            <span className="menu-item menu-title">
              {this.getGroupName(id)}
            </span>
          </div>
          {this.state.selectedFilterGroup === id ? (
            <ChevronDown className="menu-toggle-icon" size={13} />
          ) : (
            <ChevronRight className="menu-toggle-icon" size={13} />
          )}
        </h6>
      </li>
    );
  };

  commonFilter = () => {
    const item = (
      <React.Fragment key={typePriceLocationId}>
        <Collapse
          isOpen={this.state.selectedFilterGroup === typePriceLocationId}
        >
          <VvgCommonFilter />
        </Collapse>
      </React.Fragment>
    );
    return item;
  };

  mapper = (nodes, parentId, lvl) => {
    return nodes.map((node, index) => {
      const id = node.id;
      const item = (
        <React.Fragment key={id}>
          {this.showData(node, id, lvl, nodes)}
          {node.data && (
            <Collapse
              isOpen={this.state.selectedFilterGroup === id}
              className="additional-filter-container"
            >
              {this.mapper(node.data, id, (lvl || 0) + 1)}
            </Collapse>
          )}
        </React.Fragment>
      );

      return item;
    });
  };

  handleSidebarMouseEnter = (id) => {
    this.setState({
      ...this.state,
      hoveredItem: id,
    });
  };

  getCommonFilter = () => {
    const typePriceLocationText = I18n.t("lblTypePriceLocation");
    return (
      <li
        id={`${vvgFilterPrefix}${typePriceLocationId}`}
        ref={`${vvgFilterPrefix}${typePriceLocationId}`}
        className={classnames({
          "nav-item vvg-filter-item-container z-index-max": true,
          hover: this.state.hoveredItem === typePriceLocationId,
        })}
      >
        <h6
          className={classnames({
            "d-flex justify-content-between": true,
            "sidebar-group-active1":
              this.state.selectedFilterGroup === typePriceLocationId,
          })}
          onMouseEnter={() => {
            this.handleSidebarMouseEnter(typePriceLocationId);
          }}
          onMouseLeave={() => {
            this.handleSidebarMouseEnter(null);
          }}
          key={typePriceLocationId}
          onClick={() => this.toggle(typePriceLocationId)}
        >
          <div className="menu-text">
            {/* {child.icon} */}
            <span className="menu-item menu-title">
              {typePriceLocationText}
            </span>
          </div>
          {this.state.selectedFilterGroup === typePriceLocationId ? (
            <ChevronDown className="menu-toggle-icon" size={13} />
          ) : (
            <ChevronRight className="menu-toggle-icon" size={13} />
          )}
        </h6>
        <Collapse
          isOpen={this.state.selectedFilterGroup === typePriceLocationId}
        >
          <VvgCommonFilter />
        </Collapse>
      </li>
    );
  };

  render() {
    return (
      <ul>
        {this.getCommonFilter()}
        {this.mapper(this.getFilterGroups(this.props.filterData))}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  const userCurrency = loggedInCurrencySelector(state)
  return {
    filterData: state.data.categoryAttributes,
    currentCategory: state.selectedData.currentCategory,
    i18n: state.i18n,
    activeLanguage: state.selectedData.activeLanguage,
    userProfileData: state.user.userProfileData,
    userCurrency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
    setAppliedAdditionalFilter: (filterData) =>
      dispatch(setAppliedAdditionalFilter(filterData)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
    setAppliedCommonFilter: (filterData) =>
      dispatch(setAppliedCommonFilter(filterData)),
    getCategoryAttributes: (id) => dispatch(getCategoryAttributes(id)),
    searchItemListing: (id, currency) =>
      dispatch(searchItemListing(id, null, null, null, null, false, false, currency)),
    resetForm: (name) => dispatch(reset(name)),
  };
};

// eslint-disable-next-line no-class-assign
VvgFilter = connect(mapStateToProps, mapDispatchToProps)(VvgFilter);

let VvgFilterForm = reduxForm({
  form: filtersForm,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(VvgFilter);

VvgFilterForm = connect((state) => ({}))(VvgFilterForm);

export default VvgFilterForm;
