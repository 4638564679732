import React from 'react';
import '../VvgTopbar.css';

const VvgNavbarWrapper = (props) => {
    const { itemSize = 20, renderNavbar } = props;
    const style = {
        width: `${itemSize}%`,
        justifyContent: 'flex-start',
        marginLeft: 12,
    };
    return (
        <div style={style} className="menu-item space-center">
            {renderNavbar()}
        </div>
    );
};

export default VvgNavbarWrapper;