import React from 'react';
import '../VvgTopbar.css';

const VvgMenuSeparator = (props) => {
    const { itemSize = 100 } = props;
    const style = {
        width: `${itemSize}%`,
    };
    return (
        <div style={style} className="menu-item space-center"></div>
    );
};

export default VvgMenuSeparator;