import axios from "axios";
import {
  baseUrl,
  apiKey,
  routeTimezones,
  routeDataTypes,
  routeUserTypes,
  routeOrganizationTypes,
} from "../../..//utility/RouteRegistry";

import { getAcceptLanguageHeader } from "../../../utility/Helpers";

export const GET_COUNTRY_OPTIONS = "GET_COUNTRY_OPTIONS";
export const GET_ITEM_TYPE_OPTIONS = "GET_ITEM_TYPE_OPTIONS";
export const GET_CURRENCY_OPTIONS = "GET_CURRENCY_OPTIONS";
export const SET_CATEGORY_NAME_OPTIONS = "SET_CATEGORY_NAME_OPTIONS";
export const SET_CATEGORY_MEASUREMENT_UNITS = "SET_CATEGORY_MEASUREMENT_UNITS";
export const GET_SUPPORT_LANGUAGES = "GET_SUPPORT_LANGUAGES";
export const SET_TIMEZONE_OPTIONS = "SET_TIMEZONE_OPTIONS";
export const GET_DATA_TYPES = "GET_DATA_TYPES";
export const GET_USER_TYPES = "GET_USER_TYPES";
export const GET_ORGANIZATION_TYPES = "GET_ORGANIZATION_TYPES";

export const getCountryOptions = () => {
  const headers = getAcceptLanguageHeader();
  return (dispatch) => {
    axios.get(baseUrl + "/countries?api_key=" + apiKey, { headers }).then((response) => {
      dispatch({
        type: GET_COUNTRY_OPTIONS,
        data: response.data,
      });
    });
  };
};

export const getItemTypeOptions = () => {
  const headers = getAcceptLanguageHeader();
  return (dispatch) => {
    axios.get(baseUrl + "/listing_types?api_key=" + apiKey, { headers }).then((response) => {
      dispatch({
        type: GET_ITEM_TYPE_OPTIONS,
        data: response.data,
      });
    });
  };
};

export const getCurrencyOptions = () => {
  return (dispatch) => {
    axios.get(baseUrl + "/currencies?api_key=" + apiKey).then((response) => {
      dispatch({
        type: GET_CURRENCY_OPTIONS,
        data: response.data,
      });
    });
  };
};

export const getSupportedLanguages = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios.get(baseUrl + "/languages?api_key=" + apiKey).then((response) => {
        dispatch({
          type: GET_SUPPORT_LANGUAGES,
          data: response.data,
        });
        resolve();
      });
    });
  };
};

export const setCategoryNameOptions = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_CATEGORY_NAME_OPTIONS,
      data: data,
    });
  };
};

export const getCategoryMeasurementUnitOptions = (categoryId) => {
  const headers = getAcceptLanguageHeader();
  return (dispatch) => {
    axios
      .get(
        baseUrl +
          "/category_measurement_units/" +
          categoryId +
          "?api_key=" +
          apiKey,
          { headers }
      )
      .then((response) => {
        dispatch({
          type: SET_CATEGORY_MEASUREMENT_UNITS,
          data: response.data,
        });
      });
  };
};

export const getTimezoneOptions = () => {
  const headers = getAcceptLanguageHeader();
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios.get(routeTimezones, { params: queryParams, headers: headers }).then((response) => {
      dispatch({
        type: SET_TIMEZONE_OPTIONS,
        data: response.data,
      });
    });
  };
};

export const getDataTypes = () => {
  const headers = getAcceptLanguageHeader();
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios.get(routeDataTypes, { params: queryParams, headers: headers }).then((response) => {
      dispatch({
        type: GET_DATA_TYPES,
        data: response.data,
      });
    });
  };
};

export const getUserTypes = () => {
  const headers = getAcceptLanguageHeader();
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios.get(routeUserTypes, { params: queryParams, headers: headers }).then((response) => {
      dispatch({
        type: GET_USER_TYPES,
        data: response.data,
      });
    });
  };
};

export const getOrganizationTypes = () => {
  const headers = getAcceptLanguageHeader();
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios.get(routeOrganizationTypes, { params: queryParams, headers: headers }).then((response) => {
      dispatch({
        type: GET_ORGANIZATION_TYPES,
        data: response.data,
      });
    });
  };
};
