/* eslint-disable no-prototype-builtins */
import axios from "axios";
import { I18n } from "react-redux-i18n";
import { showWarning } from "../../../utility/Toast";
import { history } from "../../../history";
import {
  routeRegistration,
  routeLogin,
  routeUserData,
  routeUserPrivacy,
  routeUser,
  routeRegistrationConfirmation,
  routeSearchUsers,
  routeUserImage,
  routeReactivateAccount,
  routeResetPassword,
  routeResetPasswordConfirmation,
  routeChangeEmail,
  routeChangeEmailConfirmation,
  routeChangePassword,
  routeOrganizationData,
  apiKey,
  routeUserCultureData,
} from "../../../utility/RouteRegistry";
import {
  ERROR_CODES,
  localStorageToken,
  localStorageUser,
} from "../../../utility/Constants";
import { getAcceptLanguageHeader, getOrganizationFromLocalStorage, getTokenFromLocalStorage } from "../../../utility/Helpers";
import { setIsLoading } from "../../../redux/actions/data/index";

export const SET_USER_PROFILE_DATA = "SET_USER_DATA";
export const SET_USER_PRIVACY = "SET_USER_PRIVACY";
export const SET_IS_USER_LOGGED_IN = "SET_IS_USER_LOGGED_IN";
export const SET_SEARCH_USERS = "SET_SEARCH_USERS";
export const SET_RESET_USER = "SET_RESET_USER";
export const RESET_SEARCH_USERS = "RESET_SEARCH_USERS";
export const SET_OTHER_USER_DATA = "SET_OTHER_USER_DATA";
export const RESET_OTHER_USER_DATA = "SET_OTHER_USER_DATA";
export const SET_USER_CULTURE_DATA = "SET_USER_CULTURE_DATA";

export const login = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_email: params.email,
    iss_password: params.password,
  };
  return (dispatch) => {
    axios
      .get(routeLogin, { params: queryParams, headers: headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          } else {
            const token = response.headers["iss_authentication_token"];
            const userData = response.data.items[0];
            localStorage.setItem(localStorageToken, token);
            localStorage.setItem(localStorageUser, userData.id);

            dispatch({
              type: SET_IS_USER_LOGGED_IN,
              data: true,
            });

            dispatch({
              type: SET_USER_PROFILE_DATA,
              data: userData,
            });

            dispatch(fetchUserCultureData());

            history.push("/");
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const registerUser = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeRegistration, params, { params: queryParams })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const registrationConfirmation = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeRegistrationConfirmation, params, { params: queryParams })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const reactivateUser = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_email: params.email,
    iss_password: params.password,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(routeReactivateAccount, { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              showWarning(I18n.t("lblAccountReactivated"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const deactivateUser = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(routeUser, { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              // TODO dodaj poruku da je user deaktiviran
              showWarning(I18n.t("lblAccountDeactivated"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const resetPassword = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(routeResetPassword, params, { params: queryParams })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              showWarning(I18n.t("lblPasswordResetMessage"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const resetPasswordConfirmation = (params) => {
  let queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeResetPasswordConfirmation, params, { params: queryParams })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              showWarning(I18n.t("lblPasswordResetConfirmationMessage"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const getUserData = (id) => {
  let queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    dispatch(setIsLoading(true));
    return new Promise((resolve, reject) => {
      axios
        .get(routeUserData(id), { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              dispatch({
                type: SET_USER_PROFILE_DATA,
                data: response.data.items[0],
              });
              dispatch({
                type: SET_IS_USER_LOGGED_IN,
                data: true,
              });
              resolve(response.data.items[0]);
            }
            dispatch(setIsLoading(false));
          }
        })
        .catch((err) => {
          dispatch(setIsLoading(false));
          console.log(err);
        });
    });
  };
};

export const getOtherUserData = (id) => {
  let queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(routeUserData(id), { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id === 1010) {
              dispatch(getOtherOrganizationData(id));
            } else if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              dispatch({
                type: SET_OTHER_USER_DATA,
                data: response.data.items[0],
              });
              resolve(response.data.items[0]);
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const getOtherOrganizationData = (id) => {
  let queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(routeOrganizationData(id), { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              dispatch({
                type: SET_OTHER_USER_DATA,
                data: response.data.items[0],
              });
              resolve(response.data.items[0]);
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const setUserData = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeUser, params, { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              showWarning(I18n.t("lblSuccessProfileUpdate"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const setResetUser = () => {
  return (dispatch) => {
    dispatch({
      type: SET_RESET_USER,
    });
  };
};

export const getUserPrivacy = (orgId = null) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers =
    orgId !== null
      ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: orgId,
        }
      : {
          iss_authentication_token: getTokenFromLocalStorage(),
        };
  return (dispatch) => {
    axios
      .get(routeUserPrivacy, { params: queryParams, headers: headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          }
          dispatch({
            type: SET_USER_PRIVACY,
            data: response.data.items[0],
          });
        }
      })
      .catch((err) => console.log(err));
  };
};

export const setUserPrivacy = (params, orgId = null) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers =
    orgId !== null
      ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: orgId,
        }
      : {
          iss_authentication_token: getTokenFromLocalStorage(),
        };
  return (dispatch) => {
    axios
      .put(routeUserPrivacy, params, { params: queryParams, headers: headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const setIsUserLoggedIn = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_USER_LOGGED_IN,
      data: value,
    });
  };
};

export const searchUsers = (params) => {
  const queryParams = {
    api_key: apiKey,
    ...params,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    axios
      .get(routeSearchUsers, { params: queryParams, headers: headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          if (responseData.error_id !== 0) {
            if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
              showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
            }
          } else {
            dispatch({
              type: SET_SEARCH_USERS,
              data: response.data.items,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };
};

export const changeSingleItem = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(routeUser, params, { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const setProfileImage = (params, orgId = null) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers =
    orgId !== null
      ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          "Content-Type": "image/jpeg",
          iss_organization: orgId,
        }
      : {
          iss_authentication_token: getTokenFromLocalStorage(),
          "Content-Type": "image/jpeg",
        };
  return (dispatch) => {
    dispatch(setIsLoading(true));
    return new Promise((resolve, reject) => {
      axios
        .post(routeUserImage, params, { params: queryParams, headers: headers })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
          dispatch(setIsLoading(false));
        })
        .catch((err) => {
          dispatch(setIsLoading(false));
          console.log(err);
        });
    });
  };
};

export const setResetSearchedUsers = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SEARCH_USERS,
    });
  };
};

export const changeEmailRequest = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(routeChangeEmail, params, {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              showWarning(I18n.t("lblEmailChangeRequestMessage"));
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const changeEmailConfirmation = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeChangeEmailConfirmation, params, { params: queryParams })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const changePassword = (params) => {
  const queryParams = {
    api_key: apiKey,
  };
  const headers = {
    iss_authentication_token: getTokenFromLocalStorage(),
  };
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(routeChangePassword, params, {
          params: queryParams,
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            if (responseData.error_id !== 0) {
              if (ERROR_CODES.hasOwnProperty(responseData.error_id)) {
                showWarning(I18n.t(ERROR_CODES[responseData.error_id]));
              }
            } else {
              resolve();
            }
          }
        })
        .catch((err) => console.log(err));
    });
  };
};

export const fetchUserCultureData = () => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: getOrganizationFromLocalStorage(),
          ...getAcceptLanguageHeader(),
        }
      : {
          iss_authentication_token: getTokenFromLocalStorage(),
          ...getAcceptLanguageHeader(),
        };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios.get(routeUserCultureData, { headers, params, }).then((response) => {
        dispatch({
          type: SET_USER_CULTURE_DATA,
          data: response.data.items[0],
        })
    });
  };
};

export const resetUserCultureData = () => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_CULTURE_DATA,
      data: null,
    });
  };
};
