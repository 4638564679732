const initialState = {
    view: undefined,
};

export const mainPageViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'list-view': {
            return {
                ...state,
                view: action.type,
            }
        }
        case 'grid-view': {
            return {
                ...state,
                view: action.type,
            }
        }
        default: {
            return state;
        }
    }
};
