import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { setSidebarAdditionalFilter } from "../../redux/actions/filter/index";
import "flatpickr/dist/themes/light.css";
import "../../assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "./FormComponents.scss";
import { FILTER_TYPES } from "../../utility/Constants";

class ReduxDatePicker extends React.Component {
  state = {
    basicPicker: null,
    fromValue: null,
    toValue: null,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  static defaultProps = {
    placeholder: "",
  };

  getValue = (filterType) => {
    const { additionalFilters } = this.props;
    let fromValue = null;
    let toValue = null;
    additionalFilters.map(item => { // eslint-disable-line array-callback-return
      if (item.filterType === "from") {
        fromValue = item.value;
      }
      if (item.filterType === "to") {
        toValue = item.value;
      }
    })
    if (filterType === "from") {
      fromValue = this.state.fromValue;
    } else {
      toValue = this.state.toValue;
    }
    if (fromValue && toValue) {
      return fromValue + toValue;
    } else if (fromValue === null && toValue) {
      return "~" + toValue;
    } else if (fromValue && toValue === null) {
      return fromValue + "~";
    } else {
      return null;
    }
  };

  setFilters = (filters) => {
    const { data, setSidebarAdditionalFilter, filterType } = this.props;
    filters.push({
      id: data.id,
      value: this.getValue(filterType),
      filterName: data.name,
      name: this.getValue(filterType),
      filterType: filterType,
      type: FILTER_TYPES.DATE,
    });

    setSidebarAdditionalFilter(filters);
  };

  handleChange(date) {
    const formattedDate = date[0] !== undefined ? moment(date[0]).format("YYYY-MM-DD") : null;
    this.props.input.onChange(formattedDate);

    const {
      data,
      additionalFilters,
      filterType,
    } = this.props;

    let filters = Object.assign([], additionalFilters);
    filters = filters.filter((item) => item.id !== data.id);
    if (filterType === "from") {
      this.setState(
        {
          fromValue: formattedDate,
        },
        () => this.setFilters(filters)
      );
    } else {
      this.setState(
        {
          toValue: formattedDate,
        },
        () => this.setFilters(filters)
      );
    }
  }

  render() {
    const {
      input,
      data,
    } = this.props;

    return (
      <div className="date-picker-component">
        <Flatpickr
          name={data.name + "_from"}
          className="form-control"
          {...input}
          onChange={this.handleChange}
          options={{ minDate: data.minimum, maxDate: data.maximum }}
          // allowInput={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    additionalFilters: state.filter.sidebarFilter.additionalFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxDatePicker);
