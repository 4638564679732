import {
    CLEAR_CHAT_STATE,
    GET_ALL_CONTACT_IMAGES,
    GET_ALL_CONTACT_THREADS,
    GET_SINGLE_CONTACT_THREAD,
    GET_UNREAD_MESSAGES,
} from "../../actions/chat";

const initialState = {
    allContactThreads: [],
    singleContactThread: {},
    unreadMessages: [],
    contactImages: {},
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CONTACT_THREADS: {
            return {
                ...state,
                allContactThreads: action.data,
            };
        }
        case GET_SINGLE_CONTACT_THREAD: {
            return {
                ...state,
                singleContactThread: action.data,
            };
        }
        case GET_UNREAD_MESSAGES: {
            return {
                ...state,
                unreadMessages: action.data,
            };
        }
        case GET_ALL_CONTACT_IMAGES: {
            return {
                ...state,
                contactImages: action.data,
            };
        }
        case CLEAR_CHAT_STATE: {
            return {
                ...state,
                ...initialState,
            }
        }
        default: {
            return state;
        }
    }
};

export default chatReducer;
