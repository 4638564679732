import _ from "lodash";

const validate = (values, props) => {
  // remove vvg-error class when entering validation
  var vvgErrors = document.getElementsByClassName("vvg-error");
  while (vvgErrors.length > 0) {
    vvgErrors[0].classList.remove("vvg-error");
  }
  const errors = {};
  const fieldNames = [];
  for (const [key, value] of Object.entries(values)) {
    let tempName = key;
    const tempValue = _.isArray(value)
      ? value.length > 1
        ? value
        : value[0]
      : _.isObject(value)
      ? value.id
      : value !== null
      ? Number(value)
      : null;

    // check for Parent-Child combinations (If parent is selected, Child also has to be selected) -> if only 1 exists
    if (
      _.isObject(tempValue) &&
      !_.isEmpty(tempValue.parent) &&
      _.isEmpty(tempValue.child)
    ) {
      errors[key] = "ParentChildError";
    }

    // check for Parent-Child combinations (If parent is selected, Child also has to be selected) -> if more than 1 exists
    if (_.isArray(tempValue) && tempValue.length > 1) {
      tempValue.map((item) => { // eslint-disable-line array-callback-return
        if (!_.isEmpty(item.parent) && _.isEmpty(item.child)) {
          errors[key] = "ParentChildError";
        }
      });
    }

    const shouldApplyClass = !_.isArray(value) && !_.isObject(value); // apply vvg-error class to pure number input
    tempName = tempName.replace("_from", "");
    tempName = tempName.replace("_to", "");
    fieldNames.map((item) => { // eslint-disable-line array-callback-return
      if (item.name === tempName) {
        if (key.includes("_to")) {
          if (tempValue !== null && tempValue < item.value) {
            errors[tempName + "_from"] = "MinMaxError";
            errors[tempName + "_to"] = "MinMaxError";
            if (shouldApplyClass) {
              let inputFieldTo = document.querySelector(
                `input[name=${tempName}_to]`
              );
              if (inputFieldTo !== null) inputFieldTo.classList.add("vvg-error");
              let inputFieldFrom = document.querySelector(
                `input[name=${tempName}_from]`
              );
              if (inputFieldFrom !== null) inputFieldFrom.classList.add("vvg-error");
            }
          }
        } else {
          if (tempValue !== null && tempValue > item.value) {
            errors[tempName + "_from"] = "MinMaxError";
            errors[tempName + "_to"] = "MinMaxError";
            if (shouldApplyClass) {
              let inputFieldFrom = document.querySelector(
                `input[name=${tempName}_from]`
              );
              if (inputFieldFrom !== null) inputFieldFrom.classList.add("vvg-error");
              let inputFieldTo = document.querySelector(
                `input[name=${tempName}_to]`
              );
              if (inputFieldTo !== null) inputFieldTo.classList.add("vvg-error");
            }
          }
        }
      }
    });
    fieldNames.push({ name: tempName, value: tempValue });
  }
  return errors;
};

export default validate;
