import React, { useState, useEffect, useRef } from 'react';
import { X } from 'react-feather';
import { I18n } from 'react-redux-i18n';
import { useOutsideClickListener } from '../../../utility/Hooks';
import {
    idToNameString,
    resolveOptionsClassName,
    resolveSelectClassName,
    resolveWrapperClassName,
} from '../common/utils/helpers';
import Measure from 'react-measure';

const SingleDropdown = (props) => {
    const {
        className,
        style,
        selectedItem,
        options = [],
        onChange,
        hasClearButton = true,
        shouldRecalcOffset = true,
        bottomToTop = false,
    } = props;

    const [wrapperClassName, setWrapperClassName] = useState(null);
    const [isSelectOpen, setSelectOpen] = useState(false);
    const [selectClassName, setSelectClassName] = useState('');
    const [optionsClassName, setOptionsClassName] = useState('');
    const [offsetBottom, setOffsetBottom] = useState(shouldRecalcOffset || bottomToTop ? 0 : 200);
    const [isReadOnly, setReadOnly] = useState(false);

    useEffect(() => {
        setWrapperClassName(resolveWrapperClassName(className));
    }, [className]);

    useEffect(() => {
        const selectedIds = selectedItem !== null && selectedItem !== undefined ? [selectedItem] : [];
        setSelectClassName(resolveSelectClassName(isSelectOpen, selectedIds, false));
    }, [selectedItem, isSelectOpen]);

    useEffect(() => {
        setOptionsClassName(resolveOptionsClassName(offsetBottom, selectClassName, false));
    }, [offsetBottom, selectClassName]);

    useEffect(() => {
        if (selectedItem !== null && selectedItem !== undefined && options.length === 1) {
            setReadOnly(true);
        } 
    }, [selectedItem, options]);

    const onSelectClick = (e) => {
        e.stopPropagation();
        setSelectOpen(!isSelectOpen);
    };

    const closeSelect = () => {
        if (isSelectOpen) {
            setSelectOpen(false);
        }
    };

    const onClearButtonClick = (e) => {
        e.stopPropagation();
        onChange(undefined);
    };

    const onOptionClick = (id) => {
        onChange(id);
        closeSelect();
    };
    const selectedOption = idToNameString(selectedItem, options);
    const shouldShowSelectedOption = selectedItem !== null && selectedItem !== undefined;
    const shouldShowClearButton = hasClearButton && (
        selectedItem !== null && selectedItem !== undefined && selectedItem.length !== 0
    );

    const wrapperRef = useRef(null);
    useOutsideClickListener(wrapperRef, closeSelect);

    return (
        <div
            ref={wrapperRef}
            className={wrapperClassName}
            style={style}
        >
            <Measure
                bounds
                onResize={(contentRect) => {
                    const ob = window.innerHeight - contentRect.bounds?.top - contentRect.bounds?.height;
                    shouldRecalcOffset && setOffsetBottom(ob);
                }}
            >
                {({ measureRef }) => (
                    <div
                        ref={measureRef}
                        className={`select ${!isReadOnly ? selectClassName : 'read-only'}`}
                        onClick={onSelectClick}
                    >
                        <div
                            className={`select-content`}
                        >
                            {shouldShowSelectedOption &&
                                <div
                                    className="selected-options"
                                >
                                    {selectedOption}
                                </div>
                            }
                        </div>
                        {shouldShowClearButton && !isReadOnly &&
                            <div className="select-clear-button">
                                <X
                                    className="clear-button"
                                    onClick={onClearButtonClick}
                                />
                            </div>
                        }
                    </div>
                )}
            </Measure>
            {isSelectOpen && !isReadOnly &&
                <Options
                    optionsClassName={optionsClassName}
                    filteredOptions={options}
                    onOptionClick={onOptionClick}
                    selectedItem={selectedItem}
                />
            }
        </div>
    );
};

const Options = (props) => {
    const {
        optionsClassName,
        filteredOptions,
        onOptionClick,
        selectedItem
    } = props;

    return (
        <div
            className={`options ${optionsClassName}`}
        >
            {filteredOptions.length === 0
                ? <div
                    className="no-option"
                >
                    {I18n.t('lblNoOptions')}
                </div>
                : filteredOptions.map(({ id, name }, idx) => (
                    <div
                        key={idx}
                        className={`option ${selectedItem === id ? 'selected' : ''}`}
                        onClick={() => onOptionClick(id)}
                    >
                        {name}
                    </div>
                ))
            }

        </div>
    );
};

export default SingleDropdown;