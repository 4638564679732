import React from 'react';
import { NavLink } from 'react-router-dom';
import '../VvgTopbar.css';

const VvgTopbarLogo = (props) => {
    const { resetSelection, itemSize = 10, isMobile, isLandscape, isOnHomepage, customWidth } = props;
    const style = {
        width: customWidth !== undefined ? customWidth : `${itemSize}%`,
        paddingLeft: '20px',
    };
    let className = 'topbar-logo';
    if (!isMobile && !isLandscape && isOnHomepage) {
        className = 'space-center ml-10';
    }
    if (!isMobile && !isLandscape && !isOnHomepage) {
        className = 'space-center ml-10';
    }
    if (!isMobile && isLandscape && !isOnHomepage) {
        className = 'space-center ml-10';
    }
    return (
        <div style={style} className={`menu-item space-left`}>
            {/* <div className={className} onClick={resetSelection}> */}
                <NavLink className={className} onClick={resetSelection} to="/">
                <div className="logo-img" />
                {/* <h2 className="logo-text">Vevergo</h2> */}
            </NavLink>
        </div>
    );
};

export default VvgTopbarLogo;