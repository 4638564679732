/* eslint-disable no-prototype-builtins */
import axios from "axios";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { history } from "../../../history";
import {
  getTokenFromLocalStorage,
  getAcceptLanguageHeader,
  getOrganizationFromLocalStorage,
  getTempCurrencyFromLocalStorage,
} from "../../../utility/Helpers";
import {
  apiKey,
  routeCategoryTree,
  routeSearchItemListings,
  routeCategoryAttributes,
  routeCountries,
  routeAutocomplete,
  routeSingleItemListing,
  routeFavoritesList,
  routeAddFavorite,
  routeUserItemListing,
  routeUserItemListingsById,
  routePostSingleItemListing,
  routeOrganizationItemListingsById,
  routeUserItemListings,
  routePostAdImage,
  routeDeleteAdImage,
  routeSetAdImageAsDefault,
  routeSetImageComment,
  routePostVideo,
  routeSetVideoComment,
  routeDeleteAdVideo,
  routeGetSingleItemListing,
  routeGetItemMedia,
  routeViewItemMedia,
  routeReportItem,
  routeGetRecentItemListings,
} from "../../../utility/RouteRegistry";
import {  showSuccess, showWarning } from "../../../utility/Toast";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const SEARCH_ITEM_LISTING = "SEARCH_ITEM_LISTING";
export const SEARCH_ITEM_LISTING_WITH_PAGINATION =
  "SEARCH_ITEM_LISTING_WITH_PAGINATION";
export const RESET_CATEGORY_ATTRIBUTES = "RESET_CATEGORY_ATTRIBUTES";
export const RESET_SEARCH_RESULT = "RESET_SEARCH_RESULT";
export const GET_CATEGORY_ATTRIBUTES = "GET_CATEGORY_ATTRIBUTES";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_AUTOCOMPLETED_ITEMS = "GET_AUTOCOMPLETED_ITEMS";
export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";
export const RESET_SINGLE_ITEM = "RESET_SINGLE_ITEM";
export const GET_FAVORITES = "GET_FAVORITES";
export const GET_USER_ITEMS = "GET_USER_ITEMS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const RESET_FAVORITES = "RESET_FAVORITES";
export const RESET_USER_ITEMS = "RESET_USER_ITEMS";
export const POSTED_ITEM = "POSTED_ITEM";
export const POSTED_IMAGE = "POSTED_IMAGE";
export const SERVER_VALIDATION = "SERVER_VALIDATION";

export const resetUserItems = () => (dispatch) => {
  dispatch({
    type: RESET_USER_ITEMS,
  });
};

export const resetFavorites = () => (dispatch) => {
  dispatch({
    type: RESET_FAVORITES,
  });
};

export const getCategories = () => {
  const headers = getAcceptLanguageHeader();
  let params = {
    api_key: apiKey,
  };

  return (dispatch) => {
    axios.get(routeCategoryTree, { params, headers }).then((response) => {
      dispatch({
        type: GET_CATEGORIES,
        data: response.data,
      });
    });
  };
};

export const getAutocompleteData = (inputValue) => {
  const headers = getAcceptLanguageHeader();
  const include_categories = true;
  const search_inside = true;
  const search_text = inputValue;
  return (dispatch) => {
    const url = routeAutocomplete;
    const params = {
      include_categories,
      search_inside,
      search_text,
      api_key: apiKey,
    };
    /*
   // set URL with query params before each search request
   */
    const paramsForUrl = Object.assign({}, params);
    delete paramsForUrl.api_key;

    if (!inputValue || inputValue === "") {
      dispatch({ type: GET_AUTOCOMPLETED_ITEMS, data: [] });
    } else {
      axios
        .get(url, { params, headers })
        .then((response) => {
          const {
            data: { items },
          } = response;
          dispatch({ type: GET_AUTOCOMPLETED_ITEMS, data: items });
        })
        .catch(() => {
          dispatch({ type: GET_AUTOCOMPLETED_ITEMS, data: [] });
        });
    }
  };
};

export const viewItemMedia = async (itemId) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
    const params = {
      api_key: apiKey,
    };
    const url = routeViewItemMedia(itemId);
    const media = await axios.get(url, { params, headers });
    return media;
};

export const getSingleItemListing = (
  id,
  currency,
  shouldResetSingleItem = true
) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  return (dispatch) => {
    if (shouldResetSingleItem) {
      dispatch({
        type: RESET_SINGLE_ITEM,
      });
    }
    const params = {
      api_key: apiKey,
      currency,
    };
    if (!!id && id !== "") {
      const url = routeSingleItemListing + id;
      const imagesUrl = url + "/media";
      const itemRequest = axios.get(url, { params, headers });
      const imagesRequest = axios.get(imagesUrl, { params, headers });
      axios
        .all([itemRequest, imagesRequest])
        .then((responses) => {
          const itemResponse = responses[0];
          const {
            data: { items, row_count, error_id },
          } = itemResponse;
          if (error_id === 1010) {
            const getUrl = routeGetSingleItemListing(id);
            const getImagesUrl = routeGetItemMedia(id);
            const getItemRequest = axios.get(getUrl, { params, headers: headers });
            const getImagesRequest = axios.get(getImagesUrl, { params, headers });
            axios
              .all([getItemRequest, getImagesRequest])
              .then((responses) => {
                const itemResponse = responses[0];
                const {
                  data: { items, row_count, error_id },
                } = itemResponse;
                if (error_id === 1010) {
                  dispatch({
                    type: GET_SINGLE_ITEM,
                    data: {},
                  });
                }
                else if (row_count === 0) {
                  dispatch({
                    type: GET_SINGLE_ITEM,
                    data: {},
                  });
                } else {
                  const imagesResponse = responses[1];
                  const {
                    data: { items: images = [] },
                  } = imagesResponse;
                  const item = items[0];
                  dispatch({
                    type: GET_SINGLE_ITEM,
                    data: {
                      ...item,
                      images,
                    },
                  });
                  dispatch({
                    type: POSTED_ITEM,
                    data: item.id,
                  });
                }
              })
              .catch(err => {
                dispatch({
                  type: GET_SINGLE_ITEM,
                  data: {},
                });
              });
          } else {
            if (row_count === 0) {
              dispatch({
                type: GET_SINGLE_ITEM,
                data: {},
              });
            } else {
              const imagesResponse = responses[1];
              const {
                data: { items: images = [] },
              } = imagesResponse;
              const item = items[0];
              dispatch({
                type: GET_SINGLE_ITEM,
                data: {
                  ...item,
                  images,
                },
              });
              dispatch({
                type: POSTED_ITEM,
                data: item.id,
              });
            }
          }
        })
        .catch(() => {
          dispatch({
            type: RESET_SINGLE_ITEM,
          });
        });
    }
  };
};

export const setItemListingActive = (id, reroute) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios
    .patch(
      routePostSingleItemListing,
      {
        item_id: id,
        active: true,
      },
      { headers, params }
    )
    .then((response) => {
      showWarning(I18n.t('lblItemSuccessfullyPublished'));
      if (reroute !== undefined) {
        setTimeout(() => {
          window.location.href = reroute;
        }, 2000)
      }
    });
  };
};

export const postItemListing = (data, id) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    dispatch({
      type: SERVER_VALIDATION,
      data: { status: 'STARTING' },
    })
    const url = routePostSingleItemListing;
    const allData = { ...data };
    if (id !== null && id !== undefined) {
      allData["id"] = id;
    }
    axios.put(url, allData, { headers, params }).then((response) => {
      if (response.data.error_id === 0) {
        if (id !== null && id !== undefined) {
          dispatch({
            type: POSTED_ITEM,
            data: id,
          });
          dispatch({
            type: SERVER_VALIDATION,
            data: { status: 'SUCCESS' },
          });
        } else {
          const items = response.data.items;
          if (items !== null) {
            const itemId = response.data.items[0].id;
            axios
              .patch(
                routePostSingleItemListing,
                {
                  item_id: itemId,
                  active: false,
                },
                { headers, params }
              )
              .then((response) => {
                dispatch({
                  type: POSTED_ITEM,
                  data: itemId,
                });
                dispatch({
                  type: SERVER_VALIDATION,
                  data: { status: 'SUCCESS' },
                });
              });
          } else {
            dispatch({
              type: POSTED_ITEM,
              data: id,
            });
            dispatch({
              type: SERVER_VALIDATION,
              data: { status: 'SUCCESS' },
            });
          }
        }
      } else if (response.data.error_id === 1010) {
        showWarning(I18n.t('lblItemDoesNotExist'));
      } else {
        showWarning(I18n.t('lblValidationWrong'));
        dispatch({
          type: SERVER_VALIDATION,
          data: { status: 'FAILURE',  validation: response.data.items[0]},
        });
      }
    });
  };
};

export const searchItemListing = (
  id,
  queryParams,
  payload,
  pageSize = null,
  categoryUrl = null,
  shouldPush = true,
  shouldReplace = false,
  currency,
) => {
  // history.push("/");
  if (
    queryParams !== undefined &&
    queryParams !== null &&
    (queryParams.hasOwnProperty("price_from") ||
      queryParams.hasOwnProperty("price_to"))
  ) {
    queryParams.currency = getTempCurrencyFromLocalStorage();
  }
  let payloadParams = null;
  if (payload !== null && payload !== undefined) {
    payloadParams = { attributes: payload };
  }
  return (dispatch) => {
    const url = routeSearchItemListings;
    let params = {
      category: id,
      api_key: apiKey,
      currency,
    };
    if (
      queryParams !== null &&
      queryParams !== undefined &&
      !_.isEmpty(queryParams)
    ) {
      params = { ...params, ...queryParams };
    }
    if (pageSize !== null) {
      params.start = pageSize;
    }

    /*
    // set URL with query params before each search request
    */
    const paramsForUrl = Object.assign({}, params);
    delete paramsForUrl.api_key;
    delete paramsForUrl.start;

    const urlConfig = {
      method: "post",
      url:
        categoryUrl !== null
          ? `${window.location.origin}/${categoryUrl}`
          : window.location.origin,
      params: paramsForUrl,
    };
    const urlWithParams = decodeURI(axios.getUri(urlConfig));
    if (
      window.location.href !== urlWithParams &&
      window.location.href !== urlWithParams + window.location.pathname
    ) {
      // pathname? nije isto radi / u urlu
      if (shouldPush) {
        // window.history.pushState(null, "", urlWithParams);
        history.replace({
          pathname: "/",
          search: urlWithParams.replace(urlConfig.url, ""),
          state: { isActive: true },
        });
        // window.location.href=urlWithParams;
      }
      if (shouldReplace) {
        window.history.replaceState(null, "", urlWithParams);
      }
    }
    // history.push(urlWithParams);

    const languageHeader = getAcceptLanguageHeader();
    const authToken = {
      iss_authentication_token: getTokenFromLocalStorage(),
    };
    const headers = { ...languageHeader, ...authToken };
    dispatch(setIsLoading(true));
    const { category, search_text } = params;
    const categoryCondition = Boolean(category);
    const searchTextCondition = Boolean(search_text);
    const requestUrl = !categoryCondition
      && !searchTextCondition
      ? routeGetRecentItemListings
      : url;

    if (requestUrl === routeGetRecentItemListings) {
      axios.get(requestUrl, { params, headers }).then((response) => {
        dispatch({
          type:
            pageSize !== null
              ? SEARCH_ITEM_LISTING_WITH_PAGINATION
              : SEARCH_ITEM_LISTING,
          data: response.data,
        });
      });
    } else {
      axios.post(requestUrl, payloadParams, { params, headers }).then((response) => {
        dispatch({
          type:
            pageSize !== null
              ? SEARCH_ITEM_LISTING_WITH_PAGINATION
              : SEARCH_ITEM_LISTING,
          data: response.data,
        });
      });
    }
    dispatch(setIsLoading(false));
  };
};

export const resetCategoryAttributes = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CATEGORY_ATTRIBUTES,
      data: null,
    });
  };
};

export const resetSearchResult = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SEARCH_RESULT,
      data: [],
    });
  };
};

export const getCategoryAttributes = (id) => {
  const headers = getAcceptLanguageHeader();
  return (dispatch) => {
    const url = routeCategoryAttributes(id);
    const params = {
      api_key: apiKey,
    };
    dispatch(setIsLoading(true));
    axios
      .get(url, { params, headers })
      .then((response) => {
        dispatch({
          type: GET_CATEGORY_ATTRIBUTES,
          data: response.data,
        });
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        console.log(err);
      });
  };
};

export const getCountries = () => {
  const headers = getAcceptLanguageHeader();
  return (dispatch) => {
    let params = {
      api_key: apiKey,
    };
    axios.get(routeCountries, { params, headers }).then((response) => {
      dispatch({
        type: GET_COUNTRIES,
        data: response.data,
      });
    });
  };
};

export const getFavorites = (currency) => {
  let params = {
    api_key: apiKey,
    currency,
  };
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
      };
  return (dispatch) => {
    axios.get(routeFavoritesList, { params, headers }).then((response) => {
      dispatch({
        type: GET_FAVORITES,
        data: response.data.items,
      });
    });
  };
};

export const addToFavorites = (itemId) => {
  let params = {
    api_key: apiKey,
  };
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
      };
  const data = {
    item_id: itemId,
  };
  return (dispatch) => {
    axios.put(routeAddFavorite, data, { params, headers }).then((response) => {
      dispatch(getFavorites());
    });
  };
};

export const removeFromFavorites = (itemId) => {
  let params = {
    api_key: apiKey,
  };
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
      };
  const url = routeAddFavorite + "/" + itemId;
  return (dispatch) => {
    axios.delete(url, { params, headers }).then((response) => {
      dispatch(getFavorites());
    });
  };
};

export const getUserItems = (userId, isOrganization = false, currency) => {
  let params = {
    api_key: apiKey,
    size: 1000,
    currency,
  };
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
      };
  // const url = !userId
  //   ? routeUserItemListings
  //   : routeUserItemListingsById(userId);
  const url = !userId
    ? routeUserItemListings
    : !isOrganization
      ? routeUserItemListingsById(userId)
      : routeOrganizationItemListingsById(userId);
  return (dispatch) => {
    axios.get(url, { params, headers }).then((response) => {
      dispatch({
        type: GET_USER_ITEMS,
        data: response.data.items,
      });
    });
  };
};

export const setUserItemsPublishedStatus = (desiredItems, action) => {
  const url = routeUserItemListing;
  const requests = desiredItems.map((item) => {
    const {
      itemId,
      shouldPublish,
      shouldExtend,
    } = item;
    const data = {
      item_id: itemId,
    };
    if (shouldExtend) {
      data['extend'] = shouldExtend;
    }
    if (shouldPublish) {
      data['active'] = shouldPublish;
    }
    if (action === 'unpublish') {
      data['active'] = false;
    }
    let params = {
      api_key: apiKey,
    };
    const headers =
      getOrganizationFromLocalStorage() !== null
        ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: getOrganizationFromLocalStorage(),
          ...getAcceptLanguageHeader(),
        }
        : {
          iss_authentication_token: getTokenFromLocalStorage(),
          ...getAcceptLanguageHeader(),
        };
    return axios.patch(url, data, { params, headers });
  });

  return (dispatch) => {
    axios
      .all(requests)
      .then((responses) => {
        dispatch(getUserItems());
      })
      .catch(() => { });
  };
};

export const deleteUserItems = (itemIds) => {
  const url = routeUserItemListing;
  const requests = itemIds.map((id) => {
    let params = {
      api_key: apiKey,
    };
    const headers =
      getOrganizationFromLocalStorage() !== null
        ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: getOrganizationFromLocalStorage(),
        }
        : {
          iss_authentication_token: getTokenFromLocalStorage(),
        };
    const itemUrl = url + "/" + id;
    return axios.delete(itemUrl, { params, headers });
  });

  return (dispatch) => {
    axios
      .all(requests)
      .then((responses) => {
        showSuccess(I18n.t('item_deleted'));
        dispatch(getUserItems());
      })
      .catch(() => {
        showWarning(I18n.t('something_went_wrong'));
      });
  };
};

export const deleteFavoriteItems = (itemIds) => {
  const url = routeAddFavorite;
  const requests = itemIds.map((id) => {
    let params = {
      api_key: apiKey,
    };
    const headers =
      getOrganizationFromLocalStorage() !== null
        ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: getOrganizationFromLocalStorage(),
        }
        : {
          iss_authentication_token: getTokenFromLocalStorage(),
        };
    const itemUrl = url + "/" + id;
    return axios.delete(itemUrl, { params, headers });
  });
  return (dispatch) => {
    axios
      .all(requests)
      .then((responses) => {
        dispatch(getFavorites());
      })
      .catch(() => { });
  };
};

export const setIsLoading = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_LOADING,
      data: value,
    });
  };
};

export const postAdImage = (imageBinaryArray, itemId, fileType = 'image/jpeg') => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    dispatch(setIsLoading(true));
    axios
      .post(routePostAdImage(itemId), imageBinaryArray, {
        headers: { ...headers, "content-type": fileType },
        params,
      })
      .then((response) => {
        if (response.data.error_id === 0) {
          const imageId = response.data.items[0].id;
          dispatch({
            type: POSTED_IMAGE,
            data: imageId,
          });
        } else if (response.data.error_id === 1018) {
          showWarning(I18n.t('lblWrongImageType'));
        } else if (response.data.error_id === 1021) {
          showWarning(I18n.t('lblImageWrongSize'));
        } else {
          dispatch({
            type: POSTED_IMAGE,
            data: null,
          });
        }
      dispatch(setIsLoading(false));
      });
  };
};

export const resetPostedImage = () => {
  return (dispatch) => {
    dispatch({
      type: POSTED_IMAGE,
      data: null,
    });
  };
};

export const deleteAdImage = (imageId) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  const url = routeDeleteAdImage(imageId);
  return (dispatch) => {
    axios.delete(url, { headers, params }).then((response) => {
      if (response.data.error_id === 0) {
        dispatch({
          type: POSTED_IMAGE,
          data: imageId,
        });
      } else {
        dispatch({
          type: POSTED_IMAGE,
          data: null,
        });
      }
    });
  };
};

export const deleteAdVideo = (videoId) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  const url = routeDeleteAdVideo(videoId);
  return (dispatch) => {
    axios.delete(url, { headers, params }).then((response) => {
      if (response.data.error_id === 0) {
        dispatch({
          type: POSTED_IMAGE,
          data: videoId,
        });
      } else {
        dispatch({
          type: POSTED_IMAGE,
          data: null,
        });
      }
    });
  };
};

export const setAdImageAsDefault = (imageId) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios
      .put(routeSetAdImageAsDefault, { image_id: imageId }, { headers, params })
      .then((response) => {
        if (response.data.error_id === 0) {
          dispatch({
            type: POSTED_IMAGE,
            data: imageId,
          });
        } else {
          dispatch({
            type: POSTED_IMAGE,
            data: null,
          });
        }
      });
  };
};

export const setImageComment = (imageId, comment) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios
      .patch(
        routeSetImageComment(imageId),
        { comment: comment },
        { headers, params }
      )
      .then((response) => {
        if (response.data.error_id === 0) {
          dispatch({
            type: POSTED_IMAGE,
            data: imageId,
          });
        } else {
          dispatch({
            type: POSTED_IMAGE,
            data: null,
          });
        }
      });
  };
};

export const setVideoComment = (videoId, comment) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios
      .patch(
        routeSetVideoComment(videoId),
        { comment: comment },
        { headers, params }
      )
      .then((response) => {
        if (response.data.error_id === 0) {
          dispatch({
            type: POSTED_IMAGE,
            data: videoId,
          });
        } else {
          dispatch({
            type: POSTED_IMAGE,
            data: null,
          });
        }
      });
  };
};

export const addVideo = (itemId, videoUrl) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
        iss_authentication_token: getTokenFromLocalStorage(),
        iss_organization: getOrganizationFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      }
      : {
        iss_authentication_token: getTokenFromLocalStorage(),
        ...getAcceptLanguageHeader(),
      };
  const params = {
    api_key: apiKey,
  };
  const data = {
    video_url: videoUrl,
  };
  return (dispatch) => {
    dispatch(setIsLoading(true));
    axios
      .post(routePostVideo(itemId), { ...data }, { headers, params })
      .then((response) => {
        if (response.data.error_id === 0) {
          const id = response.data.items[0].id;
          dispatch({
            type: POSTED_IMAGE,
            data: id,
          });
        } else {
          dispatch({
            type: POSTED_IMAGE,
            data: null,
          });
        }
        dispatch(setIsLoading(false));
      });
  };
};

export const resetServerValidation = () => {
  return dispatch => {
    dispatch({
      type: SERVER_VALIDATION,
      data: {},
    })
  };
};

export const reportItem = (itemId, comment) => {
  const headers =
    getOrganizationFromLocalStorage() !== null
      ? {
          iss_authentication_token: getTokenFromLocalStorage(),
          iss_organization: getOrganizationFromLocalStorage(),
          ...getAcceptLanguageHeader(),
        }
      : {
          iss_authentication_token: getTokenFromLocalStorage(),
          ...getAcceptLanguageHeader(),
        };
  const params = {
    api_key: apiKey,
  };
  return (dispatch) => {
    axios.post(routeReportItem(itemId), { comment }, { headers, params }).then(response => {
      if (response.error_id !== 0) {
          showWarning(I18n.t('lblMessageSuccess'));
      }
    });
  };
};
