import {
  SET_USER_PROFILE_DATA,
  SET_USER_PRIVACY,
  SET_IS_USER_LOGGED_IN,
  SET_SEARCH_USERS,
  SET_RESET_USER,
  RESET_SEARCH_USERS,
  SET_OTHER_USER_DATA,
  RESET_OTHER_USER_DATA,
  SET_USER_CULTURE_DATA,
} from "../../actions/user";

const initialState = {
  userProfileData: null,
  isUserLoggedIn: false,
  userPrivacy: null,
  searchUsers: null,
  otherUserData: null,
  userCultureData: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.data,
      };
    case SET_USER_PRIVACY:
      return {
        ...state,
        userPrivacy: action.data,
      };
    case SET_IS_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: action.data,
      };
    case SET_SEARCH_USERS:
      return {
        ...state,
        searchUsers: action.data,
      };
    case SET_RESET_USER:
      return {
        ...state,
        userProfileData: null,
        isUserLoggedIn: false,
        userPrivacy: null,
        searchUsers: null,
      };
    case RESET_SEARCH_USERS:
      return {
        ...state,
        searchUsers: null,
      };
    case SET_OTHER_USER_DATA:
      return {
        ...state,
        otherUserData: action.data
      };
    case RESET_OTHER_USER_DATA:
      return {
        ...state,
        otherUserData: null,
      };
    case SET_USER_CULTURE_DATA:
      return {
        ...state,
        userCultureData: action.data
      }
    default:
      return state;
  }
};

export default userReducer;
