import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Button, Row } from "reactstrap";
import _ from "lodash";
import { getFormValues, getFormSyncErrors } from "redux-form";
import "./VvgFilterButton.scss";
import {
  searchItemListing,
  resetSearchResult,
} from "../../../redux/actions/data/index";
import { setActiveBreadcrumbs } from "../../../redux/actions/selectedData/index";
import {
  setAppliedCommonFilter,
  setAppliedAdditionalFilter,
} from "../../../redux/actions/filter/index";
import {
  getQueryParams,
  getPayloadParams,
  findUrlCategories,
  prepareCategoriesForUrl,
} from "../../../utility/Helpers";
import { filtersForm } from "../../../utility/Constants";
import { loggedInCurrencySelector } from "../../../redux/selectors/userSelectors";

export function filterFunction(
  commonFilters,
  additionalFilters,
  currentCategory,
  searchItemListing,
  setAppliedCommonFilter,
  setAppliedAdditionalFilter,
  resetSearchResult,
  preparedCategoiresUrl,
  userCurrency,
) {
  setAppliedCommonFilter(commonFilters);
  setAppliedAdditionalFilter(additionalFilters);
  resetSearchResult();
  searchItemListing(
    currentCategory !== null ? currentCategory.id : null,
    getQueryParams(commonFilters),
    getPayloadParams(additionalFilters),
    null,
    preparedCategoiresUrl,
    userCurrency
  );
}

class VvgFilterButton extends React.Component {
  filter = () => {
    const {
      commonFilters,
      additionalFilters,
      currentCategory,
      searchItemListing,
      setAppliedCommonFilter,
      setAppliedAdditionalFilter,
      resetSearchResult,
      setActiveBreadcrumbs,
      breadcrumbs,
      userCurrency,
    } = this.props;
    const urlCategories = findUrlCategories(
      this.props.categories,
      currentCategory !== null ? currentCategory.id : null
    );
    const preparedCategoriesUrl = prepareCategoriesForUrl(urlCategories);
    filterFunction(
      commonFilters,
      additionalFilters,
      currentCategory,
      searchItemListing,
      setAppliedCommonFilter,
      setAppliedAdditionalFilter,
      resetSearchResult,
      preparedCategoriesUrl,
      userCurrency,
    );
    setActiveBreadcrumbs(breadcrumbs);
  };
  render() {
    return (
      <Row className="justify-content-center">
        {(!this.props.collapsedContent ||
          (this.props.collapsedContent && !this.props.sidebarState)) && (
          <Button
            className={"vvg-filter-button btn"}
            color="deoco"
            onClick={this.filter}
            disabled={
              !_.isEmpty(this.props.formErrors) ||
              this.props.minMaxErrors.length > 0
            }
          >
            {I18n.t("lblFilter")}
          </Button>
        )}
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  const userCurrency = loggedInCurrencySelector(state);
  return {
    commonFilters: state.filter.sidebarFilter.commonFilters,
    additionalFilters: state.filter.sidebarFilter.additionalFilters,
    currentCategory: state.selectedData.currentCategory,
    formFilters: state.form.additionalFilters,
    formValues: getFormValues(filtersForm)(state),
    formErrors: getFormSyncErrors(filtersForm)(state),
    minMaxErrors: state.filter.minMaxErrors,
    i18n: state.i18n,
    categories: state.data.categories,
    breadcrumbs: state.selectedData.breadcrumbs,
    userCurrency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppliedCommonFilter: (filterData) =>
      dispatch(setAppliedCommonFilter(filterData)),
    setAppliedAdditionalFilter: (filterData) =>
      dispatch(setAppliedAdditionalFilter(filterData)),
    searchItemListing: (id, queryParams, payload, pageSize, categoryUrl, userCurrency) =>
      dispatch(
        searchItemListing(id, queryParams, payload, pageSize, categoryUrl, null, null, userCurrency)
      ),
    resetSearchResult: () => dispatch(resetSearchResult()),
    setActiveBreadcrumbs: (breadcrumbs) =>
      dispatch(setActiveBreadcrumbs(breadcrumbs)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgFilterButton);
