import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Autocomplete from "../../../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import "../../VvgTopbar.css";
import "./VvgCategoriesAutosuggest.css";
import { Search } from "react-feather";
import { findCategoryNamesTree } from "../../../../../utility/Helpers";

class VvgCategoriesAutosuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: this.transformSuggestions(props.suggestions),
    };
  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    const nextPropsSuggestions = this.transformSuggestions(
      nextProps.suggestions
    );
    if (
      JSON.stringify(nextPropsSuggestions) !==
      JSON.stringify(this.state.suggestions)
    ) {
      this.setState((prevState) => ({
        ...prevState,
        suggestions: nextPropsSuggestions,
      }));
    }
  }
  render() {
    const {
      initialInput,
      isMobile,
      onInputChange,
      onItemClick,
      resetSelection,
      itemSize = 10,
      isOnHomepage,
    } = this.props;
    const { suggestions } = this.state;
    const style = {
      width: `${itemSize}%`,
    };
    return (
      <div
        style={style}
        className={`menu-item ${isMobile ? "space-center" : "space-left"}`}
      >
        <MobileWrapper
          isMobile={isMobile}
          onInputChange={onInputChange}
          suggestions={suggestions}
          onItemClick={onItemClick}
          initialInput={initialInput}
          resetSelection={resetSelection}
          isOnHomepage={isOnHomepage}
        />
      </div>
    );
  }
  transformSuggestions(suggestions) {
    const { categories } = this.props;
    return suggestions.reduce((memo, current, index) => {
      const { title, entity_type, category } = current;
      const disc = entity_type === 1 ? 'Items' : 'Categories';
      const filteredByDisc = memo.filter((m) => m.groupTitle === disc);
      const dataItem = { title, index: index.toString(), category };
      if (disc === "Items") {
        dataItem["additionalInfo"] = null;
      } else {
        const selectedBreadcrumbs = findCategoryNamesTree(categories, category);
        selectedBreadcrumbs.pop();
        const additionalInfo = selectedBreadcrumbs.reduce(
          (memo, item, index, src) => {
            if (index === 0) {
              memo = item;
              return memo;
            }
            memo += "/";
            memo += item;
            return memo;
          },
          ""
        );
        dataItem["additionalInfo"] = additionalInfo;
      }
      if (filteredByDisc.length === 0) {
        memo.push({
          groupTitle: disc,
          data: [dataItem],
        });
      } else {
        filteredByDisc[0].data.push(dataItem);
      }
      return memo;
    }, []);
  }
}

const MobileWrapper = (props) => {
  const {
    onInputChange,
    suggestions,
    onItemClick,
    isMobile,
    initialInput,
    resetSelection,
    isOnHomepage,
  } = props;
  return (
    <>
      {isMobile ? (
        <div className="categories-autosuggest-mobile-wrapper">
          <div
            className="categories-autosuggest-mobile-container"  
          >
            <div
              className="categories-autosuggest-mobile-container-inner"
            >
              <Autocomplete
                suggestions={suggestions}
                className="form-control vx-autocomplete-container mobile-autocomplete"
                arrayKey="index"
                filterKey="title"
                filterHeaderKey="groupTitle"
                grouped={true}
                onChange={onInputChange}
                suggestionItemAdditionalAction={onItemClick}
                placeholder={I18n.t("lblSearch")}
                placeholderImage={Search}
                initialInput={initialInput}
                isMobile={isMobile}
                resetSelection={resetSelection}
                isOnHomepage={isOnHomepage}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            width: "99%",
          }}
        >
          <Autocomplete
            suggestions={suggestions}
            className="form-control vx-autocomplete-container"
            arrayKey="index"
            filterKey="title"
            filterHeaderKey="groupTitle"
            grouped={true}
            onChange={onInputChange}
            suggestionItemAdditionalAction={onItemClick}
            placeholder={I18n.t("lblSearchAutocomplete")}
            placeholderImage={Search}
            initialInput={initialInput}
            resetSelection={resetSelection}
            isOnHomepage={isOnHomepage}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    i18n: state.i18n,
  };
};

export default connect(mapStateToProps, null)(VvgCategoriesAutosuggest);
