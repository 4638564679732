import { useEffect } from 'react';

export function useOutsideClickListener(ref, handleClick) {
    useEffect(() => {
        function handleClickOutside(e) {
            if (ref.current && !ref.current.contains(e.target)
                && e.target.tagName !== 'svg' && e.target.tagName !== 'polyline') {
                handleClick && handleClick();
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, [ref, handleClick]);
}

export function useKeypress(key, action) {
    useEffect(() => {
        function onKeyup(e) {
            if (e.key === key) {
                action();
            }
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, [key, action]);
}

export function useBindTouchmoveEvent(ref) {
    useEffect(() => {
        function handleEvent(e) {
            e.preventDefault();
        }
        if (ref !== null && ref.current !== null) {
            const curr = ref.current;
            curr.addEventListener('touchmove', handleEvent);
            curr.addEventListener('scroll', handleEvent);
            return () => {
                curr.removeEventListener('touchmove', handleEvent);
                curr.removeEventListener('scroll', handleEvent);
            }
        }
    }, [ref]);
}
