import {
  GET_CATEGORIES,
  SEARCH_ITEM_LISTING,
  SEARCH_ITEM_LISTING_WITH_PAGINATION,
  GET_CATEGORY_ATTRIBUTES,
  RESET_CATEGORY_ATTRIBUTES,
  RESET_SEARCH_RESULT,
  GET_AUTOCOMPLETED_ITEMS,
  RESET_SINGLE_ITEM,
  GET_SINGLE_ITEM,
  GET_FAVORITES,
  GET_USER_ITEMS,
  SET_IS_LOADING,
  RESET_USER_ITEMS,
  RESET_FAVORITES,
  POSTED_ITEM,
  POSTED_IMAGE,
  SERVER_VALIDATION,
} from "../../actions/data/index";
import { SET_RESET_USER } from "../../actions/user";

const initialState = {
  autocompleteData: [],
  categories: [],
  itemListings: [],
  categoryAttributes: null,
  isLoading: 0,
  searchResult: {
    data: [],
    rowCount: 0,
    totalCount: 0,
  },
  singleItem: null,
  favorites: [],
  userItems: [],
  postedItem: null,
  postedAdImage: null,
  serverValidation: {},
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.data.items,
      };
    case SEARCH_ITEM_LISTING:
      return {
        ...state,
        itemListings: [...state.itemListings, ...action.data.items],
        searchResult: {
          data: action.data.items,
          totalCount: action.data.total_count,
          rowCount: action.data.row_count,
        },
      };
    case SEARCH_ITEM_LISTING_WITH_PAGINATION:
      return {
        ...state,
        itemListings: [...state.itemListings, ...action.data.items],
        searchResult: {
          data: [...state.searchResult.data, ...action.data.items],
          totalCount: action.data.total_count,
          rowCount: action.data.row_count,
        },
      };
    case GET_CATEGORY_ATTRIBUTES:
      return {
        ...state,
        categoryAttributes: action.data.items,
      };
    case RESET_CATEGORY_ATTRIBUTES:
      return {
        ...state,
        categoryAttributes: null,
      };
    case RESET_SEARCH_RESULT:
      return {
        ...state,
        itemListings: [],
        searchResult: {},
      };
    case GET_AUTOCOMPLETED_ITEMS: {
      return {
        ...state,
        autocompleteData: action.data,
      };
    }
    case RESET_SINGLE_ITEM: {
      return {
        ...state,
        singleItem: null,
      };
    }
    case GET_SINGLE_ITEM: {
      return {
        ...state,
        singleItem: action.data,
      };
    }
    case SET_RESET_USER: {
      return {
        ...state,
        favorites: [],
      };
    }
    case GET_FAVORITES: {
      return {
        ...state,
        favorites: action.data,
      }
    }
    case GET_USER_ITEMS: {
      return {
        ...state,
        userItems: action.data,
      }
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.data === true ? state.isLoading + 1 : state.isLoading - 1,
      }
    }
    case RESET_USER_ITEMS:
      return {
        ...state,
        userItems: [],
      }
    case RESET_FAVORITES:
      return {
        ...state,
        favorites: [],
      }
    case POSTED_ITEM: {
      return {
        ...state,
        postedItem: action.data,
      }
    }
    case POSTED_IMAGE: {
      return {
        ...state,
        postedImage: action.data,
      }
    }
    case SERVER_VALIDATION: {
      return {
        ...state,
        serverValidation: action.data,
      }
    }
    default:
      return state;
  }
};

export default dataReducer;
