import React, { useState, useEffect, useRef } from 'react';
import fuzzysort from 'fuzzysort';
import { X } from 'react-feather';
import { I18n } from 'react-redux-i18n';
import { useOutsideClickListener } from '../../../utility/Hooks';
import { FUZZY_SEARCH_OPTIONS } from '../common/utils/constants';
import {
    resolveOptionsClassName,
    resolveWrapperClassName,
    sortObjectsById,
} from '../common/utils/helpers';
import Measure from 'react-measure';

const ComboBoxDropdown = (props) => {
    const {
        className,
        style,
        value = '', // and id, they should be the same
        options = [],
        onChange,
        hasClearButton = true,
        shouldRecalcOffset = true,
        inputType = 'text',
        bottomToTop = false,
        useDynamicOptions = false,
        sendFlagOnInputFocusOrBlur = () => {},
    } = props;
    const [wrapperClassName, setWrapperClassName] = useState(null);
    const [isSelectOpen, setSelectOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [optionsClassName, setOptionsClassName] = useState('');
    const [offsetBottom, setOffsetBottom] = useState(shouldRecalcOffset || bottomToTop ? 0 : 200);
    const [dynamicOptions, setDynamicOptions] = useState([]);
    const [inputPlaceholder, setInputPlaceholder] = useState(I18n.t('lblSelect'));
    const selectClassName = 'select-34';

    useEffect(() => {
        if (value === null || value === undefined || value.length === 0) {
            setDynamicOptions(options);
        } else {
            const t = options.find(option => option.id.toString() === value.toString());
            if (t === undefined) {
                const newOptions = [...options].concat({id: value, name: value});
                setDynamicOptions(newOptions);
            } else {
                setDynamicOptions(options);
            }
        }
    }, [value, options, useDynamicOptions]);

    useEffect(() => {
        setWrapperClassName(resolveWrapperClassName(className));
    }, [className]);

    useEffect(() => {
        if (dynamicOptions.length === 0) {
            return;
        }
        if (value === null || value === undefined || value.length === 0) {
            setFilteredOptions(dynamicOptions);
        } else {
            setFilteredOptions(
                fuzzysort.go(value.toString(), dynamicOptions, FUZZY_SEARCH_OPTIONS)
                    .map(fs => fs.obj)
                    .sort(sortObjectsById)
            );
        }
    }, [dynamicOptions, value]);

    useEffect(() => {
        setOptionsClassName(resolveOptionsClassName(offsetBottom, selectClassName));
    }, [offsetBottom, selectClassName]);

    const onSelectClick = () => {
        if (!isSelectOpen) {
            setInputPlaceholder(I18n.t('lblEnter'));
        }
        setSelectOpen(!isSelectOpen);
    };

    const closeSelect = () => {
        if (isSelectOpen) {
            setSelectOpen(false);
            setInputPlaceholder(I18n.t('lblSelect'));
        }
    };

    const onInputChange = (e) => {
        e.persist();
        onChange(e.target.value.toString());
    };

    const onInputClick = (e) => {
        e.stopPropagation();
        if (!isSelectOpen) {
            setSelectOpen(!isSelectOpen);
            setInputPlaceholder(I18n.t('lblEnter'));
        }
    };

    const onClearButtonClick = (e) => {
        e.stopPropagation();
        onChange('');
    };

    const onOptionClick = (id) => {
        // setInputValue(id);
        onChange(id.toString());
        closeSelect();
        setInputPlaceholder(I18n.t('lblSelect'));
    };

    const shouldShowClearButton = hasClearButton
        && (
            (value !== null && value !== undefined && value.length > 0)
        );
    const wrapperRef = useRef(null);
    useOutsideClickListener(wrapperRef, closeSelect);
    return (
        <div
            ref={wrapperRef}
            className={wrapperClassName}
            style={style}
        >
            <Measure
                bounds
                onResize={(contentRect) => {
                    const ob = window.innerHeight - contentRect.bounds?.top - contentRect.bounds?.height;
                    shouldRecalcOffset && setOffsetBottom(ob);
                }}
            >
                {({ measureRef }) => (
                    <div
                        ref={measureRef}
                        className={`select ${selectClassName}`}
                        onClick={onSelectClick}
                    >
                        <div
                            className="select-content"
                        >
                                <input
                                    type={inputType}
                                    value={value}
                                    placeholder={inputPlaceholder}
                                    onChange={onInputChange}
                                    onClick={onInputClick}
                                    // autoFocus={false}
                                    disabled={!isSelectOpen}
                                    onFocus={(e) => {
                                        sendFlagOnInputFocusOrBlur(true);
                                        // setInputPlaceholder(I18n.t('lblEnter'));
                                    }}
                                    onBlur={(e) => {
                                        sendFlagOnInputFocusOrBlur(false);
                                        // setInputPlaceholder(I18n.t('lblSelect'));
                                    }}
                                />
                        </div>
                        {shouldShowClearButton &&
                            <div className="select-clear-button">
                                <X
                                    className="clear-button"
                                    onClick={onClearButtonClick}
                                />
                            </div>
                        }
                    </div>
                )}
            </Measure>
            {isSelectOpen &&
                <Options
                    optionsClassName={optionsClassName}
                    filteredOptions={filteredOptions}
                    onOptionClick={onOptionClick}
                />
            }
        </div>
    );
};

const Options = (props) => {
    const {
        optionsClassName,
        filteredOptions,
        onOptionClick,
    } = props;

    return (
        <div
            className={`options ${optionsClassName}`}
        >
            {filteredOptions.length === 0
                ? <div
                    className="no-option"
                >
                    {I18n.t('lblNoOptions')}
                </div>
                : filteredOptions.map(({ id, name }, idx) => (
                    <div
                        key={idx}
                        className="option"
                        onClick={() => onOptionClick(id)}
                    >
                        {name}
                    </div>
                ))
            }

        </div>
    );
};

export default ComboBoxDropdown;