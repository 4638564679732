import React from "react";
import { connect } from "react-redux";
import VvgFilter from "../components/filter/VvgFilter";
import "../utility/ApiManager";
import "./VvgFilterContainer.css";
import {
  searchItemListing,
} from "../redux/actions/data/index";
import {
  lblItemType,
  lblPriceCurrencyType,
  lblItemCountry,
  lblPublishedBy,
  lblIncludeItemsWithoutPrice,
  lblIncludeItemsWithoutMedia,
  lblCurrency,
} from "../utility/Labels";
import {
  setAppliedCommonFilter,
  setSidebarCommonFilter,
  setInitialFilter,
} from "../redux/actions/filter/index";
import { getCategoryMeasurementUnitOptions } from "../redux/actions/commonFilters/index";
import { getQueryParams } from "../utility/Helpers";
import { COMMON_FILTERS } from "../utility/Constants";
import { loggedInCurrencySelector } from "../redux/selectors/userSelectors";

class VvgFilterContainer extends React.Component {
  commonFilters = [];
  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const categoryParameter = params.get("category");
    const searchTextParameter = params.get("search_text");

    const listingTypeParameter = params.get(COMMON_FILTERS.LISTING_TYPE);
    if (listingTypeParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.LISTING_TYPE,
        lblItemType,
        listingTypeParameter,
        listingTypeParameter
      );
    }
    const priceFromParameter = params.get(COMMON_FILTERS.PRICE_FROM);
    if (priceFromParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.PRICE_FROM,
        lblPriceCurrencyType,
        priceFromParameter,
        priceFromParameter
      );
    }

    const priceToParameter = params.get(COMMON_FILTERS.PRICE_TO);
    if (priceToParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.PRICE_TO,
        lblPriceCurrencyType,
        priceToParameter,
        priceToParameter
      );
    }

    const currencyParameter = params.get(COMMON_FILTERS.CURRENCY);
    if (currencyParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.CURRENCY,
        lblCurrency,
        currencyParameter,
        currencyParameter
      );
    }

    const includeItemsWithoutPriceParameter = params.get(
      COMMON_FILTERS.INCLUDE_ITEMS_WITHOUT_PRICE
    );
    if (includeItemsWithoutPriceParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.INCLUDE_ITEMS_WITHOUT_PRICE,
        lblIncludeItemsWithoutPrice,
        includeItemsWithoutPriceParameter,
        includeItemsWithoutPriceParameter
      );
    }

    const countryParameter = params.get(COMMON_FILTERS.COUNTRY);
    if (countryParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.COUNTRY,
        lblItemCountry,
        countryParameter,
        countryParameter
      );
    }

    const placeParameter = params.get(COMMON_FILTERS.PLACE);
    if (placeParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.PLACE,
        lblItemCountry,
        placeParameter,
        placeParameter
      );
    }

    const ownerTypeParameter = params.get(COMMON_FILTERS.OWNER_TYPE);
    if (ownerTypeParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.OWNER_TYPE,
        lblPublishedBy,
        ownerTypeParameter,
        ownerTypeParameter
      );
    }

    const includeItemsWithMediaParameter = params.get(
      COMMON_FILTERS.INCLUDE_ITEMS_WITH_MEDIA
    );
    if (includeItemsWithMediaParameter) {
      this.addToCommonFilters(
        COMMON_FILTERS.INCLUDE_ITEMS_WITH_MEDIA,
        lblIncludeItemsWithoutMedia,
        includeItemsWithMediaParameter,
        includeItemsWithMediaParameter
      );
    }

    const measurementUnitParameter = params.get(
      COMMON_FILTERS.MEASUREMENT_UNIT
    );
    if (measurementUnitParameter) {
      this.props.getCategoryMeasurementUnitOptions(Number(categoryParameter));
      this.addToCommonFilters(
        COMMON_FILTERS.MEASUREMENT_UNIT,
        measurementUnitParameter,
        measurementUnitParameter,
        measurementUnitParameter
      );
    }

    const orderTypeParameter = params.get("order_type");
    if (orderTypeParameter) {
      this.addToCommonFilters(
        "order_type",
        "order_type",
        orderTypeParameter,
        orderTypeParameter
      );
    }

    const orderItemParameter = params.get("order_item");
    if (orderItemParameter) {
      this.addToCommonFilters(
        "order_item",
        "order_item",
        orderItemParameter,
        orderItemParameter
      );
    }
    if (searchTextParameter === null) {
      this.props.searchItemListing(
        categoryParameter,
        getQueryParams(this.commonFilters),
        false,
        this.props.userCurrency
      );
      this.props.setAppliedCommonFilter(this.commonFilters);
      this.props.setSidebarCommonFilter(this.commonFilters);
    }
  }

  addToCommonFilters = (id, filterName, value, name) => {
    this.commonFilters.push({
      id: id,
      filterName: filterName,
      value: value,
      name: name,
    });
  };

  render() {
    return (
      <div className="vvg-sidebar-container1">
        <div className="vvg-filter-margin-top"></div>
        <VvgFilter />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userCurrency = loggedInCurrencySelector(state);
  return {
    itemTypeOptions: state.commonFilters.itemTypeOptions,
    filterValues: state.filter.sidebarFilter.commonFilters,
    i18n: state.i18n,
    userCurrency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchItemListing: (id, params, shouldPush = true, userCurrency) =>
      dispatch(searchItemListing(id, params, null, null, null, shouldPush, null, userCurrency)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
    setAppliedCommonFilter: (filterData) =>
      dispatch(setAppliedCommonFilter(filterData)),
    setInitialFilter: (filterData) => dispatch(setInitialFilter(filterData)),
    getCategoryMeasurementUnitOptions: (categoryId) =>
      dispatch(getCategoryMeasurementUnitOptions(categoryId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgFilterContainer);
