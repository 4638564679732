import ComboBoxDropdown from './comboBox/ComboBoxDropdown';
import MultiSearchDropdown from './multiSearch/MultiSearchDropdown';
import SingleSearchDropdown from './singleSearch/SingleSearchDropdown';
import SingleDropdown from './single/SingleDropdown';
import './common/styles/style.scss';

const VvgDropdown = MultiSearchDropdown;

VvgDropdown.ComboBox = ComboBoxDropdown;
VvgDropdown.MultiSearch = MultiSearchDropdown;
VvgDropdown.SingleSearch = SingleSearchDropdown;
VvgDropdown.Single = SingleDropdown;

export default VvgDropdown;
