const initialState = {
    isLoaded: true,
};

const translationsLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "TRANSLATIONS_LOAD_COMPLETED":{ 
            return {
                ...state,
                isLoaded: true,
            };
        }
        case "TRANSLATIONS_LOAD_STARTED": {
            return {
                ...state,
                isLoaded: false,
            }
        }
        default: {
            return state;
        }
    }
};

export default translationsLoaderReducer;
