import React from "react";
import { Field } from "redux-form";
import ReduxDatePicker from "../../components/ReduxFormComponents/ReduxDatePicker";
import "./VvgDatepicker.scss";

class VvgDatepicker extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className="vvg-sidebar-filter-item-container">
        <div className="vvg-sidebar-filter-type-item-title">
          {data.name}
        </div>
        <div style={{ display: "flex" }}>
          <Field
            id={data.id + "_to"}
            name={data.name + "_from"}
            component={ReduxDatePicker}
            data={data}
            filterType="from"
          />
          <div className="separator-min-max"></div>
          <Field
            id={data.id + "_to"}
            name={data.name + "_to"}
            component={ReduxDatePicker}
            data={data}
            filterType="to"
          />
        </div>
      </div>
    );
  }
}

export default VvgDatepicker;
