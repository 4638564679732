import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import classnames from "classnames";
import {
  setSidebarCommonFilter,
  setSidebarAdditionalFilter,
} from "../../redux/actions/filter/index";
import {
  Field,
  getFormValues,
  FieldArray,
  formValueSelector,
  change,
} from "redux-form";
import * as Icon from "react-feather";
import { ReduxFormSelect } from "../ReduxFormComponents/ReduxSelect";
import { FILTER_TYPES, filtersForm } from "../../utility/Constants";
import "./VvgCombinedSelect.scss";
import "../filter/VvgFilter.scss";

const selector = formValueSelector(filtersForm);
let filterName = "";

class VvgCombinedSelect extends React.Component {
  state = {
    showChildError: false,
  };
  handleChange = (selectedItem, childNode, index, filterName) => {
    const {
      commonFilters,
      additionalFilters,
      setSidebarCommonFilter,
      setSidebarAdditionalFilter,
      isCommon,
      combinedSelectValue,
    } = this.props;

    let filters = Object.assign(
      [],
      isCommon ? commonFilters : additionalFilters
    );
    filters = filters.filter((item) => item.id !== childNode.id);
    let value = "";
    let name = "";

    combinedSelectValue.map((item, filterIndex) => { // eslint-disable-line array-callback-return
      if (!_.isEmpty(item)) {
        if (index !== filterIndex) {
          if (!_.isEmpty(item.child)) {
            value += item.child.id + "|";
            name += item.child.parent_name + " " + item.child.name + "|";
          }
        } else {
          if (selectedItem === null) {
            value += "";
            name += "";
          } else {
            value += selectedItem.id + "|";
            name += selectedItem.parent_name + " " + selectedItem.name + "|";
          }
        }
      }
    });

    if (value && value !== "") {
      filters.push({
        id: childNode.id,
        filterName: filterName,
        value: String(value).substring(0, value.length - 1),
        name: String(name).substring(0, name.length - 1),
        type: FILTER_TYPES.COMBINED_SELECT,
      });
    }

    if (selectedItem !== null) {
      this.setState({
        showChildError: false,
      });
    } else {
      this.setState({
        showChildError: true,
      });
    }

    isCommon
      ? setSidebarCommonFilter(filters)
      : setSidebarAdditionalFilter(filters);
  };

  handleChangeParent = (parentNode, index, value) => {
    const { combinedSelectValue } = this.props;
    const filterName =
      this.props.parentNode.name + "-" + this.props.childNode.name;
    this.props.changeFilter(filtersForm, `${filterName}.${index}`, {});
    this.handleChange(null, this.props.childNode, index, filterName);

    if (
      value !== null &&
      (_.isEmpty(combinedSelectValue[index].child) ||
        combinedSelectValue[index].child !== undefined)
    ) {
      this.setState({
        showChildError: true,
      });
    } else {
      this.setState({
        showChildError: false,
      });
    }
  };

  removeFields = (fields, index, parentNode) => {
    this.handleChangeParent(parentNode, index, null);
    fields.remove(index);
  };

  renderCombinedSelect = ({ fields }, props) => {
    if (fields !== undefined && fields.length === 0) {
      fields.push({});
    }
    const { parentNode, childNode, isMulti, combinedSelectValue } = this.props;
    const filterName = parentNode.name + "-" + childNode.name;

    return (
      <ul>
        {fields.map((item, index) => {
          const clsx =
            combinedSelectValue[index] !== undefined &&
            !_.isEmpty(combinedSelectValue[index].parent) &&
            _.isEmpty(combinedSelectValue[index].child)
              ? "basic-multi-select2"
              : "basic-multi-select";
          return (
            <li key={index}>
              <div
                className={classnames("vvg-combined-select-title-container", {
                  "vvg-combined-select-title-margin": index > 0,
                })}
              >
                {parentNode.name}
                {index > 0 && (
                  <div
                    style={{ marginBottom: "2px", cursor: "pointer" }}
                    onClick={() => this.removeFields(fields, index, parentNode)}
                  >
                    <Icon.Trash2 color="#ea5455" size={20} />
                  </div>
                )}
              </div>
              <Field
                name={`${item}.parent`}
                component={ReduxFormSelect}
                options={parentNode.attribute_values}
                isSearchable
                isMulti={isMulti}
                className="basic-multi-select111"
                isCombinedSelect
                selectIndex={index}
                onChange={(value) =>
                  this.handleChangeParent(parentNode, index, value)
                }
                openMenuOnFocus={true}
              />
              <div
                className="vvg-sidebar-filter-type-item-title"
                style={{ marginTop: "5px" }}
              >
                {childNode.name}
              </div>
              <Field
                name={`${item}.child`}
                component={ReduxFormSelect}
                options={
                  !_.isEmpty(combinedSelectValue[index])
                    ? childNode.attribute_values.filter(
                        (item) =>
                          item.parent_id ===
                          combinedSelectValue[index].parent?.id
                      )
                    : []
                }
                isSearchable
                isMulti={isMulti}
                className={clsx}
                isDisabled={_.isEmpty(combinedSelectValue[index])}
                isCombinedSelect
                selectIndex={index}
                onChange={(value) =>
                  this.handleChange(value, childNode, index, filterName)
                }
              />
              {index + 1 === fields.length && (
                <>
                  <div
                    className="vvg-combined-select-add-filter-container"
                    onClick={() => fields.push({})}
                  >
                    <Icon.Plus color="#28c76f" size={20} />
                    {I18n.t("lblAddFilter")}
                  </div>
                </>
              )}
              <hr
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "0",
                }}
              />
            </li>
          );
        })}
      </ul>
    );
  };
  render() {
    const { parentNode, childNode } = this.props;
    filterName = parentNode.name + "-" + childNode.name;
    return (
      <form>
        <FieldArray
          name={filterName}
          component={this.renderCombinedSelect}
          props={this.props}
        />
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formValues: getFormValues(filtersForm)(state),
    combinedSelectValue: selector(
      state,
      ownProps.parentNode.name + "-" + ownProps.childNode.name
    ),
    commonFilters: state.filter.sidebarFilter.commonFilters,
    additionalFilters: state.filter.sidebarFilter.additionalFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
    changeFilter: (formName, fieldName, value) =>
      dispatch(change(formName, fieldName, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VvgCombinedSelect);
