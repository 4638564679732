import React, { useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import '../VvgTopbar.css';
import Avatar from '../../../../components/@vuexy/avatar/AvatarComponent';
import defaultProfileImage from '../../../../assets/img/avatar/no-user.jpg';
import { profileImageBaseUrl } from '../../../../utility/RouteRegistry';
import { CheckSquare, LogIn, LogOut, Mail, Star, User } from 'react-feather';
import { getOrganizationFromLocalStorage } from '../../../../utility/Helpers';
import { useOutsideClickListener } from '../../../../utility/Hooks';

const VvgUserMenu = (props) => {
    const {
        isOpen,
        handleClick,
        isLoggedIn,
        isMobile,
        logIn,
        logOut,
        showMyItems,
        showFavouriteItems,
        showUserProfile,
        showMessages,
        itemSize = 10,
        userProfileData,
        organizationProfileData,
        selectedOrganization,
        numberOfUnreadMessages,
        closeUserMenu,
    } = props;


    const menuItems = {
        logIn: 'logIn',
        logOut: 'logOut',
        myItems: 'myItems',
        favoriteItems: 'favoriteItems',
        messages: 'messages',
        userProfile: 'userProfile',
    };

    const isLoggedOutItems = [
        {
            id: menuItems.logIn,
            name: I18n.t('lblLogin'),
            icon: LogIn,
        },
    ];

    const isLoggedInItems = [
        {
            id: menuItems.myItems,
            name: I18n.t('lblMyItems'),
            icon: CheckSquare,
        },
        {
            id: menuItems.favoriteItems,
            name: I18n.t('lblFavoriteItems'),
            icon: Star,
        },
        {
            id: menuItems.userProfile,
            name:
                selectedOrganization !== null || getOrganizationFromLocalStorage() !== null
                    ? I18n.t('lblOrganizationProfile')
                    : I18n.t('lblUserProfile'),
            icon: User,
        },
        {
            id: 'divider',
            name: null,
            icon: null,
        },
        {
            id: menuItems.logOut,
            name:
                selectedOrganization !== null || getOrganizationFromLocalStorage() !== null
                    ? I18n.t('lblBackToPersonal')
                    : I18n.t('lblLogout'),
            icon: LogOut,
        },
    ];

    const mobileIsLoggedInItems = [
      {
          id: menuItems.myItems,
          name: I18n.t('lblMyItems'),
          icon: CheckSquare,
      },
      {
          id: menuItems.favoriteItems,
          name: I18n.t('lblFavoriteItems'),
          icon: Star,
      },
      {
        id: menuItems.messages,
        name: I18n.t('lblMessages'),
        icon: Mail,
    },
      {
          id: menuItems.userProfile,
          name:
              selectedOrganization !== null || getOrganizationFromLocalStorage() !== null
                  ? I18n.t('lblOrganizationProfile')
                  : I18n.t('lblUserProfile'),
          icon: User,
      },
      {
          id: 'divider',
          name: null,
          icon: null,
      },
      {
          id: menuItems.logOut,
          name:
              selectedOrganization !== null || getOrganizationFromLocalStorage() !== null
                  ? I18n.t('lblBackToPersonal')
                  : I18n.t('lblLogout'),
          icon: LogOut,
      },
  ];

    const items = isLoggedIn
      ? isMobile
        ? mobileIsLoggedInItems
        : isLoggedInItems
      : isLoggedOutItems;
    const style = {
        width: `${itemSize}%`,
    };

    const handleOnMenuItemClick = (id) => {
        closeUserMenu();
        switch (id) {
            case menuItems.logIn:
                return logIn();
            case menuItems.logOut:
                return logOut();
            case menuItems.myItems:
                return showMyItems();
            case menuItems.favoriteItems:
                return showFavouriteItems();
            case menuItems.userProfile:
                return showUserProfile();
            case menuItems.messages:
                return showMessages();
            default: {
                return logIn();
            }
        }
    };

    const getAvatarImage = () => {
        if (organizationProfileData !== null) {
            if (organizationProfileData.profile_image !== null) {
                return `${profileImageBaseUrl}${organizationProfileData.profile_image}.jpeg`;
            } else {
                return defaultProfileImage;
            }
        } else if (userProfileData !== null && userProfileData.profile_image !== null) {
            return `${profileImageBaseUrl}${userProfileData.profile_image}.jpeg`;
        } else {
            return defaultProfileImage;
        }
    };

    const ref = useRef(null);
    useOutsideClickListener(ref, closeUserMenu);

    return (
        <div ref={ref} style={style} className="menu-item clickable-item space-center">
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <div className="button-text button-text-hover menu-item-center user-menu-item" onClick={handleClick}>
                    <Avatar img={getAvatarImage()} />
                    {numberOfUnreadMessages !== 0 && isMobile && <div className="chat-preview-unread-messages-user" />}
                </div>
                    <div className={`user-menu-dropdown${isOpen ? ' user-menu-dropdown-open' : ' '}`}>
                {isOpen &&
                        items.map((item) => {
                            const { id, name, icon: Icon } = item;
                            if (id === 'divider') {
                                return <div key="divider" className="user-menu-dropdown-divider"/> 
                            }
                            return (
                                <div key={id} className="user-menu-dropdown-item" onClick={() => handleOnMenuItemClick(id)}>
                                    {id === 'messages' && numberOfUnreadMessages !== 0 && isMobile && <div className="chat-preview-unread-messages-user-messages" />}
                                    <Icon size={15} className="menu-dropdown-item-icon" />
                                    {name}
                                </div>
                            )
                        })}
                
                    </div>
            </div>
        </div>
    );
};

export default VvgUserMenu;
