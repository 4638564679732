import {
  GET_ITEM_TYPE_OPTIONS,
  GET_CURRENCY_OPTIONS,
  GET_COUNTRY_OPTIONS,
  SET_CATEGORY_NAME_OPTIONS,
  SET_CATEGORY_MEASUREMENT_UNITS,
  GET_SUPPORT_LANGUAGES,
  SET_TIMEZONE_OPTIONS,
  GET_DATA_TYPES,
  GET_USER_TYPES,
  GET_ORGANIZATION_TYPES,
} from "../../actions/commonFilters";

const initialState = {
  itemTypeOptions: [],
  currencyOptions: [],
  supportedLanguages: [],
  countryOptions: [],
  categoryNameOptions: [],
  categoryMeasurementUnits: [],
  timezoneOptions: [],
  isLoading: false,
  dataTypes: [],
  userTypes: [],
  organizationTypes: [],
};

const commonFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEM_TYPE_OPTIONS:
      return {
        ...state,
        itemTypeOptions: action.data.items,
        isLoading: false,
      };
    case GET_CURRENCY_OPTIONS:
      return {
        ...state,
        currencyOptions: action.data.items,
        isLoading: false,
      };
    case GET_COUNTRY_OPTIONS:
      return {
        ...state,
        countryOptions: action.data.items,
        isLoading: false,
      };
    case SET_CATEGORY_NAME_OPTIONS:
      return {
        ...state,
        categoryNameOptions: action.data,
      };
    case SET_CATEGORY_MEASUREMENT_UNITS:
      return {
        ...state,
        categoryMeasurementUnits: action.data.items,
      };
    case GET_SUPPORT_LANGUAGES: {
      return {
        ...state,
        supportedLanguages: action.data.items,
      }
    }
    case SET_TIMEZONE_OPTIONS: {
      return {
        ...state,
        timezoneOptions: action.data.items,
      }
    }
    case GET_DATA_TYPES: {
      return {
        ...state,
        dataTypes: action.data.items,
      }
    }
    case GET_USER_TYPES: {
      return {
        ...state,
        userTypes: action.data.items,
      }
    }
    case GET_ORGANIZATION_TYPES: {
      return {
        ...state,
        organizationTypes: action.data.items,
      }
    }
    default:
      return state;
  }
};

export default commonFiltersReducer;
