import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import {
  setSidebarCommonFilter,
  setSidebarAdditionalFilter,
} from "../../redux/actions/filter/index";
import "./VvgInput.scss";

class ReduxDatePicker extends React.Component {
  state = {
    basicPicker: null,
  };

  static defaultProps = {
    placeholder: "",
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (inputValue) => {
    const {
      commonFilters,
      additionalFilters,
      setSidebarCommonFilter,
      setSidebarAdditionalFilter,
      isCommon,
      data,
    } = this.props;
    const value = inputValue.target.value;
    let filters = Object.assign(
      [],
      isCommon ? commonFilters : additionalFilters
    );
    filters = filters.filter((item) => item.id !== data.id);
    if (value && value !== "") {
      filters.push({
        id: data.id,
        value: value,
      });
    }

    isCommon
      ? setSidebarCommonFilter(filters)
      : setSidebarAdditionalFilter(filters);
    // this.props.input.onChange(value);
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <Field
          name={data.name !== undefined ? data.name : "inputField"}
          component="input"
          type="text"
          onChange={(selectedItem) => this.handleChange(selectedItem)}
          className="vvg-input-container"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonFilters: state.filter.sidebarFilter.commonFilters,
    additionalFilters: state.filter.sidebarFilter.additionalFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxDatePicker);
