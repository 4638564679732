import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import "react-toggle/style.css";
import "./FormComponents.scss";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "../toggle/components";
import {
  setSidebarCommonFilter,
  setSidebarAdditionalFilter,
} from "../../redux/actions/filter/index";
import { FILTER_TYPES } from "../../utility/Constants";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

let domain = [0, 2];
let defaultValues = [1];

class ReduxToggle extends React.Component {
  state = {
    inputValue: [1],
  };

  onChange = (option) => {
    const {
      data,
      commonFilterData,
      additionalFilterData,
      setSidebarCommonFilter,
      setSidebarAdditionalFilter,
      isCommon,
      options,
      hasTwoOptions
    } = this.props;
    let filters = Object.assign(
      [],
      isCommon ? commonFilterData : additionalFilterData
    );
    filters = filters.filter((item) => item.id !== data.id);
    const selectedValue = option[0];
    let value = [];
    let name = "";
    let filterValue = null;
    switch (selectedValue) {
      case 0:
        value = [0];
        name = options !== undefined ? options.lblLeft : (hasTwoOptions ? I18n.t("lblNo") : I18n.t("lblYes"));
        filterValue = options !== undefined ? [0] : "true";
        break;
      case 1:
      case null:
        value = null;
        break;
      case 2:
        value = [2];
        name = options !== undefined ? options.lblRight : (hasTwoOptions ? I18n.t("lblYes") : I18n.t("lblNo"));
        filterValue = options !== undefined ? [2] : "false";
        break;
      default:
        value = null;
    }

    if (value !== null && value !== undefined && value !== "") {
      filters.push({
        id: data.id,
        value: filterValue,
        filterName: data.name,
        name: name,
        type: FILTER_TYPES.TOGGLE,
      });
    }

    this.setState({
      inputValue: [value],
    });
    this.props.input.onChange(value);

    if (isCommon) {
      setSidebarCommonFilter(filters);
    } else {
      setSidebarAdditionalFilter(filters);
    }
  };

  render() {
    const { options, input, hasTwoOptions } = this.props;
    if (hasTwoOptions) {
      domain = [0, 1];
    } else {
      domain = [0, 2];
    }
    return (
      <div>
        <div className={"vvg-toggle-container"}>
          <div style={{ marginRight: 15 }}>
            {options !== undefined ? options.lblLeft : (hasTwoOptions ? I18n.t("lblNo") : I18n.t("lblYes"))}
          </div>
          <div style={{ width: 50 }}>
            <Slider
              mode={2}
              step={1}
              domain={domain}
              rootStyle={sliderStyle}
              onUpdate={this.onUpdate}
              onChange={this.onChange}
              values={input.value !== "" ? input.value : defaultValues}
            >
              <Rail>
                {({ getRailProps }) => (
                  <SliderRail getRailProps={getRailProps} />
                )}
              </Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map((handle) => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
            </Slider>
          </div>
          <div style={{ marginLeft: 15 }}>
            {options !== undefined ? options.lblRight : (hasTwoOptions ? I18n.t("lblYes") : I18n.t("lblNo"))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonFilterData: state.filter.sidebarFilter.commonFilters,
    additionalFilterData: state.filter.sidebarFilter.additionalFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarAdditionalFilter: (filterData) =>
      dispatch(setSidebarAdditionalFilter(filterData)),
    setSidebarCommonFilter: (filterData) =>
      dispatch(setSidebarCommonFilter(filterData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxToggle);
